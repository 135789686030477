import React, { useState, useEffect } from "react";
import { View, Text, Pressable, Input, Box, Stack } from "native-base";
import { useNavigate } from "react-router-dom";
import "./Video.css";
import { FaSearch } from "react-icons/fa";

const Buscador = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate("/busqueda", { state: { query: searchQuery } });
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [videoHeight, setVideoHeight] = useState("950px");
  const videoUrl = "https://marketeasyrealty.com/video/MAPA_MUNDI.mp4";

  // ajustar video
  useEffect(() => {
    const breakpoints = {
      base: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1280,
    };
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < breakpoints.md);

      if (screenWidth >= breakpoints.xl) {
        setVideoHeight("600px");
      } else if (screenWidth >= breakpoints.lg) {
        setVideoHeight("600px");
      } else if (screenWidth >= breakpoints.md) {
        setVideoHeight("550px");
      } else {
        setVideoHeight("500px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const videoBackgroundStyle = {
    position: "relative",
    width: "100%",
    height: videoHeight,
    overflow: "hidden",
  };

  const mediaStyle = {
    width: "100%",
    height: videoHeight,
    objectFit: "cover",
    pointerEvents: "none",
  };

  const contentStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  };

  return (
    <View style={videoBackgroundStyle}>
      <div style={contentStyle}>
        <Stack direction={"column"} alignSelf={"center"} w="100%">
          <Text
            noOfLines={3}
            mb={5}
            bold
            alignSelf={"center"}
            w={"90%"}
            color="white"
            style={{
              fontFamily: "'Graphik', sans-serif",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
              animation: "fadeInOut 14s ease-in-out infinite alternate",
            }}
            fontSize={["sm", "md", "2xl", "2xl"]}
            textAlign="center"
            rounded={"lg"}
            className={"fade-in-out"}
            mt={[80, 96, 96, 96, 96]}
          >
            FORMA PARTE DEL FUTURO DE LA INVERSIÓN INMOBILIARIA
          </Text>

          <Box
            bg="#fff"
            w={"80%"}
            maxW={96}
            borderRadius={40}
            alignSelf={"center"}
          >
            <Input
              zIndex={9999}
              bg="#fff"
              //color={"#fff"}
              variant="rounded"
              placeholder="Ingresa una direccion, colonia, ciudad, C.P."
              alignSelf={"flex-start"}
              w="100%"
              maxW={96}
              value={searchQuery}
              onChangeText={setSearchQuery}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputRightElement={
                <Pressable p={4} mr={2} onPress={() => handleSearch()}>
                  <FaSearch color={"#000"} />
                </Pressable>
              }
            />
          </Box>
        </Stack>
      </div>
      <video
        autoPlay
        loop
        muted
        style={mediaStyle}
        poster="https://marketeasyrealty.com/img/logo-negro.png"
      >
        <source
          src="https://marketeasyrealty.com/video/MAPA_MUNDI.mp4"
          type="video/mp4"
        />
        <source
          src="https://marketeasyrealty.com/video/MAPA_MUNDI.webm"
          type="video/webm"
        />
        Tu navegador no soporta videos HTML5.
      </video>

      {/* <video autoPlay loop muted style={mediaStyle}>
        <source src={videoUrl} type="video/mp4" />
        Tu navegador no soporta videos HTML5.
      </video> */}
    </View>
  );
};

export default Buscador;
