import { Text, Stack, VStack, Button, Modal } from "native-base";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useUser } from "../../helper/UserContext";
import { styles } from "../../styles";

const InvitacionInmueble = () => {
  const navigate = useNavigate();

  const { tipo } = useUser();

  const [tipoUser, setTipoUser] = useState(null);

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("tipo");
      if (value !== null) {
        setTipoUser(value);
      }
    } catch (e) {
      console.log("error async InvitacionInmueble: ", e);
    }
  };

  useEffect(() => {
    if (tipo) {
      setTipoUser(tipo);
    } else {
      getData();
    }
  }, []);

  const handleNavegation = (link) => {
    scroll.scrollToTop();
    navigate(link);
  };

  //logica de modal:
  const [showModal, setShowModal] = useState(false);

  const ModalInmueble = () => {
    return (
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        closeOnOverlayClick={false}
      >
        <Modal.Content maxWidth="450px">
          <Modal.CloseButton />
          <Modal.Header>Acceso exclusivo</Modal.Header>
          <Modal.Body>
            <Text fontSize={"lg"}>
              ¡No te pierdas lo mejor! Únete a nuestra comunidad para acceder a
              descargas exclusivas, contenido premium y mucho más. Regístrate o
              inicia sesión para continuar.
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setShowModal(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="darkBlue"
                onPress={() => {
                  handleNavegation("/signup");
                }}
              >
                Registrarse
              </Button>
              <Button
                onPress={() => {
                  handleNavegation("/login");
                }}
              >
                Iniciar Sesión
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  };

  const handleBoton = () => {
    if (tipoUser === "0" || tipoUser === "1") {
      handleNavegation("/AgregarInmuebleAdmin");
    } else {
      handleNavegation("/signup");
    }
  };

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      alignSelf={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      py={2}
      flex={1}
      m={5}
      space={6}
      w={{ base: "80%", lg: "65%" }}
      style={styles.borderGradientStyle}
    >
      <ModalInmueble />

      <VStack
        p={4}
        w={{ base: "80%", lg: "80%" }}
        space={1}
        alignSelf={"center"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text
          fontSize={{ base: "3xl", md: "6xl" }}
          textAlign={"center"}
          lineHeight={54}
          style={styles.colorTitulos2}
        >
          ¿ERES DESARROLLADOR INMOBILIARIO?
        </Text>

        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          textAlign={"center"}
          color="white"
          style={{ fontFamily: "Graphik" }}
        >
          Registrate como desarrollador y has visibles tus propiedades
        </Text>
        <Button
          px={10}
          size="lg"
          style={styles.buttonGradientStyle}
          shadow={4}
          onPress={() => handleBoton()}
          _text={{ fontFamily: "Graphik", fontSize: "2xl" }}
        >
          Subir Inmueble
        </Button>
      </VStack>
    </Stack>
  );
};

export default InvitacionInmueble;
