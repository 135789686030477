import URL from "./baseURL";
import fetchPost from "./fetchPost";
import AsyncStorage from "@react-native-async-storage/async-storage";

const checkStatus = async (userId) => {
  const dataLogin = new FormData();
  console.log("id check:", userId);
  dataLogin.append("user_id", userId);
  const url = `${URL.BASE_URL}/users/status`;
  const options = {
    method: "POST",
    body: dataLogin,
  };
  const res = await fetchPost(url, options);

  if (res.success) {
    console.log("check Satus: ", res.status);
    await AsyncStorage.setItem("status_suscripcion", res.status);
    return res.status;
  }
};

export default checkStatus;
