import { Heading, View } from "native-base";
import React from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import CrudComponent from "../../../components/admin/CrudComponent";
import SuscripcionModal from "../../../components/global/SuscripcionModal";

const AgregarInmuebleAdmin = () => {
  const { tipo, userId } = useUser();

  if (tipo !== "0" && tipo !== "1") {
    return <Login />;
  }

  return (
    <View pt={16}>
      <Heading style={{ textAlign: "center" }} color={"white"}>
        Agregar Inmueble{" "}
      </Heading>
      <SuscripcionModal />

      <CrudComponent />
    </View>
  );
};
export default AgregarInmuebleAdmin;
