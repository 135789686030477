import React, { useState, useEffect } from "react";
import {
  Text,
  Pressable,
  Image,
  VStack,
  Center,
  Stack,
  HStack,
  Button,
  ZStack,
  Modal,
  Divider,
} from "native-base";

import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import URL from "../helper/baseURL";
import AuthModal from "./global/AuthModal";
import { useUser } from "../helper/UserContext";
import fetchPost from "../helper/fetchPost";
import useCustomToast from "./global/CustomToast";
import { turquesa } from "../helper/colors";
import { IoBedOutline } from "react-icons/io5";
import { LuBath } from "react-icons/lu";
import { animateScroll as scroll } from "react-scroll";
import { BiArea } from "react-icons/bi";
import { styles } from "../styles";

const InmuebleComponent = ({
  id,
  imageUri,
  titulo,
  estado,
  municipio,
  precio,
  rooms,
  bath,
  area,
}) => {
  const showToast = useCustomToast();

  const BASE_URL2 = "https://marketeasyrealty.com/back/public/uploads/";
  const [isFavorite, setIsFavorite] = useState(false);
  //para navegar a otras vistas
  const navigate = useNavigate();
  const handleClick = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const { userId } = useUser();
  useEffect(() => {
    console.log("userId : ", userId);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const handleNavegation = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const AgregarFavorito = async () => {
    if (!userId) {
      setShowModal(true);
    } else {
      const data = new FormData();
      data.append("id_usuario", userId);
      data.append("id_inmueble", id);
      const url = `${URL.BASE_URL}favoritos/agregar`;
      const options = {
        method: "POST",
        body: data,
      };
      const res = await fetchPost(url, options);
      //console.log("respuesta", res.status);
      if (res && res.status) {
        showToast({
          duration: 5000,
          text: "Favorito agregado!",
          color: "success.500",
          position: "top",
        });
        setIsFavorite(true);
      } else {
        showToast({
          duration: 5000,
          text: "Error, verifica tu conexión e intenta más tarde",
          color: "error.500", // Cambié el color a rojo para indicar un error
          position: "top",
        });
      }
    }
  };
  const EliminarFavorito = async () => {
    const data = new FormData();
    data.append("id_usuario", userId);
    data.append("id_inmueble", id);
    const url = `${URL.BASE_URL}favoritos/borrar`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    if (res && res.status) {
      console.log("res eliminar: ", res);
      showToast({
        duration: 5000,
        text: "Favorito Eliminado!",
        color: "warning.500",
        position: "top",
      });
      setIsFavorite(false);
    } else {
      showToast({
        duration: 5000,
        text: "Error, verifica tu conexión e intenta más tarde",
        color: "red.500", // Cambié el color a rojo para indicar un error
        position: "top",
      });
    }
  };

  const handleFavorito = async () => {
    if (isFavorite) {
      EliminarFavorito();
    } else {
      AgregarFavorito();
    }
  };

  const CheckFavorito = async () => {
    const data = new FormData();
    data.append("id_usuario", userId);
    data.append("id_inmueble", id);
    const url = `${URL.BASE_URL}favoritos/check`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);

    if (res && res.status) {
      setIsFavorite(true);
    }
  };

  useEffect(() => {
    CheckFavorito();
  }, []);

  //logica de modal:
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        closeOnOverlayClick={false}
      >
        <Modal.Content maxWidth="450px">
          <Modal.CloseButton />
          <Modal.Header>Acceso exclusivo</Modal.Header>
          <Modal.Body>
            <Text fontSize={"lg"}>
              ¡No te pierdas lo mejor! Únete a nuestra comunidad para acceder a
              descargas exclusivas, contenido premium y mucho más. Regístrate o
              inicia sesión para continuar.
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setShowModal(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="darkBlue"
                onPress={() => {
                  handleNavegation("/signup");
                }}
              >
                Registrarse
              </Button>
              <Button
                onPress={() => {
                  handleNavegation("/login");
                }}
              >
                Iniciar Sesión
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <Pressable
        onPress={() => handleClick(`/property/${id}/${titulo}`)}
        flex={1}
      >
        <VStack
          m={4}
          flex={1}
          borderColor={"#c4c4c4"}
          mx={5}
          borderWidth={1}
          borderRadius={10}
          pb={2}
          shadow={7}
        >
          {/* Imagen */}
          <ZStack position="relative" w="100%" h={80}>
            <Image
              h={80}
              w="100%"
              borderTopRadius={10}
              source={{
                uri: `${BASE_URL2}${imageUri}`,
              }}
              alt="Alternate Text"
            />
            <Center bg="muted.200" borderRadius={20} py={1} px={2} m={2}>
              <Text style={{ fontFamily: "Graphik" }}>{estado}</Text>
            </Center>

            {/* Ícono de favorito */}
            <Pressable
              bg="muted.200"
              borderRadius={20}
              p={1}
              position="absolute"
              top={2}
              right={2}
              onPress={() => handleFavorito()}
            >
              {isFavorite ? (
                <AiFillHeart color={"#cc682c"} size="2em" />
              ) : (
                <AiOutlineHeart color={turquesa} bg="red" size="2em" />
              )}
            </Pressable>
          </ZStack>

          <AuthModal isOpen={isModalOpen} onClose={closeModal} />

          {/* Titulo */}

          <Text
            style={{ fontFamily: "Graphik" }}
            fontSize={"xl"}
            color="white"
            p={2}
            ml={3}
          >
            {titulo}
          </Text>

          <Stack direction={"row"} space={3} ml={6}>
            <HStack alignItems={"center"} space={2}>
              <IoBedOutline size={24} color="#fff" />
              <Text
                style={{ fontFamily: "Garet" }}
                fontSize={"xl"}
                color="white"
              >
                {rooms}
              </Text>
            </HStack>

            <HStack alignItems={"center"} space={2}>
              <LuBath size={"1.5em"} color="#fff" />

              <Text
                style={{ fontFamily: "Garet" }}
                fontSize={"1em"}
                color="white"
              >
                {bath}
              </Text>
            </HStack>

            <HStack alignItems={"center"} space={2}>
              <BiArea size={"1.5em"} color="#fff" />
              <Text
                style={{ fontFamily: "Garet" }}
                fontSize={"1em"}
                color="white"
              >
                {area}m²
              </Text>
            </HStack>
          </Stack>
          <Divider
            w="80%"
            my={2}
            alignSelf={"center"}
            style={styles.buttonGradientStyle}
          />

          <HStack py={2} px={4} justifyContent="space-between">
            <Text
              fontSize={["md", "xl"]}
              color="#FFF"
              style={{ fontFamily: "Graphik" }}
            >
              ${precio} USD{" "}
            </Text>

            <Pressable
              px={2}
              flex={1}
              mx={2}
              w={40}
              h={10}
              style={styles.buttonGradientStyle}
              shadow={5}
              justifyContent="center"
              alignItems="center"
              onPress={() => handleClick(`/property/${id}/${titulo}`)}
            >
              <Text
                py={2}
                fontSize={"lg"}
                px={4}
                textAlign="center"
                color="#fff"
                style={{ fontFamily: "Graphik" }}
              >
                Ver más
              </Text>
            </Pressable>
          </HStack>
        </VStack>
      </Pressable>
    </>
  );
};

export default InmuebleComponent;
