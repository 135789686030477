import * as React from "react";
import {
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  Center,
  Image,
  ZStack,
  HStack,
  Text,
} from "native-base";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchPost from "../helper/fetchPost";
import URL from "../helper/baseURL";
import Loader from "../components/Loader";
import { animateScroll as scroll } from "react-scroll";
import { styles } from "../styles";

const Password = () => {
  const [correo, setCorreo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  //para navegar a otras vistas
  const navigate = useNavigate();

  // Función para validar el correo electrónico y la contraseña

  const handleLogin = () => {
    scroll.scrollToTop();
    navigate("/login");
  };

  const handlePassword = async () => {
    if (correo === "") {
      window.alert("Ingresa un correo electrónico");
      return;
    }
    setIsLoading(true);

    const dataLogin = new FormData();
    dataLogin.append("email", correo.trim());

    const url = `${URL.BASE_URL}/enviarcorreoPassword`;
    const options = {
      method: "POST",
      body: dataLogin,
    };

    const res = await fetchPost(url, options);

    setMessage(res.message);
    setIsLoading(false);
  };

  return (
    <ZStack h="45rem" w="100%">
      <Image
        source={{
          uri: "https://marketeasyrealty.com/fondos/nosotros.jpg",
        }}
        alt="Alternate Text"
        w="100%"
        h={96}
      />

      <Center
        alignSelf="center"
        bg="#fff"
        w={96}
        px={8}
        mt={16}
        shadow={7}
        borderRadius={10}
      >
        <Image
          alignSelf="center"
          source={{
            uri: `https://marketeasyrealty.com/img/logo-pngblanco.png`,
          }}
          alt="Alternate Text"
          w={56}
          px={4}
          h={40}
          mt={5}
          resizeMode="cover"
        />

        <Box safeArea p="2" py="8" w="90%" maxW="300">
          <Heading
            mt="1"
            fontFamily={"Graphik"}
            size="lg"
            alignSelf={"center"}
            textAlign={"center"}
          >
            Recupera tu cuenta
          </Heading>

          {isLoading ? (
            <Loader />
          ) : (
            <Text color="#b16835" textAlign={"center"}>
              {message}
            </Text>
          )}

          <VStack space={3} mt="5" flex={1}>
            <FormControl>
              <FormControl.Label
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                Ingresa tu correo electrónico para buscar tu cuenta.
              </FormControl.Label>
              <Input
                placeholder="Correo electrónico"
                onChangeText={(val) => setCorreo(val.toUpperCase())}
                value={correo}
              />
            </FormControl>
            <HStack justifyContent="space-around" alignItems="center">
              <Button
                mt="2"
                variant="subtle"
                borderRadius={50}
                colorScheme="orange"
                shadow={6}
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
                onPress={() => handleLogin()}
              >
                Cancelar
              </Button>
              <Button
                mt="2"
                onPress={() => handlePassword()}
                style={styles.buttonGradientStyle}
                shadow={6}
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                Buscar
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Center>
    </ZStack>
  );
};

export default Password;
