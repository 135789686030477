import { View, Box, Flex } from "native-base";
import React from "react";
import RazonesZStack from "../components/home/RaonesZStack";
import ContactoFooter from "../components/ContactoFooter";
import FlatListInmueblesPortada from "../components/FlatListInmueblesPortada";
import InvitacionRegistro from "../components/home/InvitacionRegistro";
import BotonesInicio from "../components/home/BotonesInicio";
import Buscador from "../components/home/Buscador";
import InvitacionInmueble from "../components/global/InvitacionInmueble";
import Swiper3 from "../components/home/Swiper3";

const Home = () => {
  return (
    <View flex={1}>
      {/* <InicioComponent /> */}

      <Buscador />
      <Flex
        w="100%"
        style={{
          flex: 1,
          backgroundImage:
            "url('https://marketeasyrealty.com/fondos/PaginaWebPrincipal.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <Flex mt={10}>
          <InvitacionRegistro />
        </Flex>
        <Flex mt={10}>
          <BotonesInicio />
        </Flex>
        <Flex mt={10}>
          <InvitacionInmueble />
        </Flex>

        <Box w="90%" alignSelf="center" mt={10}>
          {/* <SwiperHome />
          <RazonesSwiper /> */}
          <Swiper3 />
        </Box>

        <Box w="90%" my={0} alignSelf="center" mt={10}>
          <FlatListInmueblesPortada />
        </Box>
        <Box w="90%" alignSelf="center" my={10}>
          <RazonesZStack />
        </Box>
        <ContactoFooter />
      </Flex>
    </View>
  );
};
export default Home;
