import React from "react";
import { Stack, Box, Image } from "native-base";
import InmuebleFav from "./home/InmuebleFav";
import ContactoSimple from "./ContactoSimple";

const ContactoFooter = () => {
  return (
    <Stack
      my={5}
      direction={{
        base: "column",
        lg: "row",
      }}
      mt={{ base: 3, md: 10 }}
      w="90%"
      alignSelf="center"
    >
      <Box
        w={{
          base: "100%",

          lg: "50%",
        }}
      >
        <Image
          source={{
            uri: "https://marketeasyrealty.com/fondos/contactoinmueble.jpg",
          }}
          alt="Alternate Text"
          w="100%"
          pb={20}
          h={"500px"}
          display={{ base: "none", md: "block" }}
        />
      </Box>

      <Box
        w={{
          base: "100%",

          md: "80%",
          lg: "50%",
        }}
        alignSelf={"center"}
      >
        <ContactoSimple />
      </Box>
    </Stack>
  );
};
export default ContactoFooter;
