import { VStack, Text } from "native-base";
import React from "react";
import { styles } from "../../styles";

const TituloComponent = ({ uno, dos }) => {
  return (
    <VStack alignSelf="center" space={1} my={3}>
      <Text
        fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
        color="white"
        fontFamily={"Times"}
        textAlign={"center"}
      >
        {uno}
      </Text>
      <Text
        fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
        color="white"
        fontFamily={"Times"}
        textAlign={"center"}
      >
        {dos}
      </Text>
    </VStack>
  );
};

export default TituloComponent;
