import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Stack,
  Heading,
  Box,
  VStack,
  Button,
  Icon,
} from "native-base";
import { useUser } from "../../../helper/UserContext";
import { useNavigate } from "react-router";
import { PiUserList } from "react-icons/pi";
import LogoutConfirmationPopover from "../../../components/LogoutConfirmationPopover";
import OpcionUser from "../../../components/global/OpcionUser";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { TbHomeHeart, TbHomeCheck } from "react-icons/tb";
import CodigoReferencia from "../../../components/global/CodigoReferencia";
import { animateScroll as scroll } from "react-scroll";
import { LiaUsersSolid } from "react-icons/lia";
import { FaHouseUser, FaRegCreditCard, FaUserCheck } from "react-icons/fa6";
import fetchPost from "../../../helper/fetchPost";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { styles } from "../../../styles";
import { BiSolidCommentError } from "react-icons/bi";
import URL from "../../../helper/baseURL";
import checkStatus from "../../../helper/checkStatus";

const DesarrolladoraPanel = () => {
  const { logout, nombre, apellido, correo, userId, statusSuscripcion } =
    useUser();

  const [idUser, setIdUser] = useState(userId);
  const [status, setStatus] = useState(statusSuscripcion);
  const getId = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        console.log("async id: ", value);
        setIdUser(value);
        handleCheckStatus(value);
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };
  // const getStatus = async () => {
  //   try {
  //     const value = await AsyncStorage.getItem("status_suscripcion");
  //     if (value !== null) {
  //       console.log("get status: ", value);

  //       setStatus(value);
  //       return value;
  //       // value previously stored
  //     }
  //   } catch (e) {
  //     // error reading value
  //   }
  // };

  useEffect(() => {
    getId();
    // getStatus();
  }, []);

  const navigate = useNavigate();

  const handleSalir = () => {
    // Lógica para el inicio de sesión
    logout();
    scroll.scrollToTop();
    navigate("/");
  };

  const UserNav = (nav) => {
    navigate(nav);
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
  };

  // Función para manejar el click en el botón y llamar a checkStatus
  const handleCheckStatus = async (id) => {
    const result = await checkStatus(id); // Llama a la función checkStatus
    setStatus(result); // Actualiza el estado con el valor devuelto
  };

  return (
    <View mt={[12, 12, 20, 24]}>
      <Heading bold fontSize={"2xl"} textAlign="center" mt={3} color="white">
        BIENVENIDO
      </Heading>
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        alignSelf={"center"}
        flex={1}
      >
        <VStack my={3}>
          <Text bold fontSize={"2xl"} textAlign="justify" px={10} color="white">
            Cuenta
          </Text>

          <Text bold fontSize={"lg"} px={10} color="white">
            {nombre} {apellido}.
          </Text>
          <Text fontWeight={"semibold"} fontSize={"md"} px={10} color="white">
            {correo}
          </Text>
          {/* suscripcion status */}
          {status === "active" ? (
            <>
              <Button
                mx={10}
                style={styles.buttonGradientStyle}
                w={64}
                onPress={() => handleCheckStatus(idUser)}
                borderRadius={20}
                rightIcon={<Icon as={<FaUserCheck color="#fff" size={30} />} />}
                _text={{
                  fontFamily: "Graphik",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Suscripción activa
              </Button>
            </>
          ) : (
            <>
              <Button
                mx={10}
                style={styles.buttonGradientStyle}
                w={64}
                onPress={() => handleCheckStatus(idUser)}
                borderRadius={20}
                rightIcon={
                  <Icon as={<BiSolidCommentError color="#fff" size={30} />} />
                }
                _text={{
                  fontFamily: "Graphik",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Suscripción pendiente
              </Button>
            </>
          )}
          <Text color="#bbb" italic textAlign={"center"}>
            Presiona para actualizar el estado de tu suscripción
          </Text>
        </VStack>

        <CodigoReferencia id={idUser} />
      </Stack>

      <Box w="80%" alignSelf="center">
        <div style={stackContainerStyle}>
          <OpcionUser
            titulo="Información de la empresa"
            texto="Visualiza y edita tus datos personales"
            funcion={() => UserNav("/UserInfo")}
            icon={PiUserList}
          />
          <OpcionUser
            titulo="Ver Brokers referidos"
            texto="Revisa los brokers registrados con tu código de referido"
            funcion={() => UserNav("/VerBrokersDesarrolladora")}
            icon={LiaUsersSolid}
          />

          <OpcionUser
            titulo="Prospectos"
            texto="Ver y asigna prospectos a tus brokers"
            funcion={() => UserNav("/SolicitudesDesarrollo")}
            icon={FaHouseUser}
            status={status}
          />

          <OpcionUser
            titulo="Agregar Inmueble"
            texto="Agrega nuevo inmueble"
            funcion={() => UserNav("/AgregarInmuebleAdmin")}
            icon={MdOutlineAddHomeWork}
            status={status}
          />

          <OpcionUser
            titulo="Ver Inmuebles "
            texto="Ver tus inmuebles subidos"
            funcion={() => UserNav("/VerInmueblesDes")}
            icon={TbHomeCheck}
            status={status}
          />
          <OpcionUser
            titulo="Favoritos"
            texto="Ver inmuebes favoritos"
            funcion={() => UserNav("/Favoritos")}
            icon={TbHomeHeart}
          />
          <OpcionUser
            titulo="Pagar Suscripción"
            texto="Paga y administra tu suscripción"
            funcion={() => UserNav("/Suscripcion")}
            icon={FaRegCreditCard}
          />

          {/* <OpcionUser
            titulo="Mi suscripción"
            texto="Información sobre mi suscripción"
            funcion={() => UserNav("/SuscripcionStatusDes")}
            icon={MdCardMembership}
          /> */}
        </div>
      </Box>

      <LogoutConfirmationPopover onConfirm={handleSalir} />
    </View>
  );
};
export default DesarrolladoraPanel;
