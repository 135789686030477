import React, { useState, useEffect } from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import fetchPost from "../../../helper/fetchPost";
import URL from "../../../helper/baseURL";
import {
  Spinner,
  HStack,
  Pressable,
  Button,
  Heading,
  Popover,
  View,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt, FaEdit } from "react-icons/fa";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { styles } from "../../../styles";
import SuscripcionModal from "../../../components/global/SuscripcionModal";

const VerInmueblesDes = () => {
  const { tipo, userId } = useUser();
  const [inmuebles, setInmuebles] = useState([{}]);
  const [isLoading, setIsLoading] = useState(true);

  const [idUser, setIdUser] = useState(userId);

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        getInmuebles(value);
        setIdUser(value);
      }
    } catch (e) {
      // error reading value
    }
  };

  useEffect(() => {
    if (userId === null) {
      getData();
    } else {
      getInmuebles(userId);
    }
  }, []);

  const navigate = useNavigate();

  const handleNav = (id, nombre) => {
    navigate(`/property/${id}/${nombre}`);
  };

  const handleEdit = (id_inmueble, id_user) => {
    navigate(`/EditarInmueble/${id_inmueble}/${id_user}`);
  };

  const getInmuebles = async (id) => {
    console.log("id user ", id);
    const dataLogin = new FormData();
    dataLogin.append("id_usuario", id);
    const url = `${URL.BASE_URL}desarrolladora/verInmueblesDes`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.success) {
      console.log("res success: ", res.success);
      setInmuebles(res.data);
      setIsLoading(false);
    } else {
      setInmuebles(null);
      setIsLoading(false);
    }
  };

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    textAlign: "center",
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };

  if (tipo !== "1" && tipo !== "0") {
    return <Login />;
  }

  const handleBorrar = async (idInmueble) => {
    const dataLogin = new FormData();
    dataLogin.append("id", idInmueble);
    const url = `${URL.BASE_URL}desarrolladora/eliminarInmueble`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.resultado) {
      window.alert(res.mensaje);
      getInmuebles(idUser);
    } else {
      window.alert(
        "Error al eliminar, comprueba tu conexión e intentalo más tarde, si el problema persiste contacte al administrador"
      );
    }
  };

  const handleEstadoChange = async (id, status) => {
    // Lógica para cambiar el estado del inmueble
    console.log("id inmueble: ", id);
    console.log("status inmueble: ", status);
    const data = new FormData();
    data.append("status", status);
    data.append("id_inmueble", id);
    const url = `${URL.BASE_URL}/cambiarStatusInmuebleAdmin`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles status77: ", res);
    if (res.status) {
      getInmuebles(idUser);
    } else {
      window.alert(res.message);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner size="xl" py={20} alignSelf={"center"} />
      ) : inmuebles === null ? (
        <View>
          <Heading
            color={"white"}
            textAlign={"center"}
            py={40}
            alignSelf={"center"}
          >
            No has subido inmuebles
          </Heading>
          <SuscripcionModal />

          <Button
            colorScheme={"orange"}
            mb={20}
            w={64}
            alignSelf={"center"}
            onPress={() => navigate("/AgregarInmuebleAdmin")}
          >
            Agregar Inmueble
          </Button>
        </View>
      ) : (
        <View mx={10} flex={1} my={16}>
          <h1 style={{ textAlign: "center", color: "white" }}>
            Inmuebles registrados
          </h1>
          <SuscripcionModal />
          <TableContainer component={Paper} style={{ zIndex: 0 }}>
            <Table sx={{ background: "#000" }}>
              <TableHead>
                <TableRow style={{ backgroundColor: "#ff7e37" }}>
                  <TableCell style={headerText} sx={{ width: "20px" }}>
                    ID
                  </TableCell>
                  {/* <TableCell style={headerText}>Desarrolladora</TableCell> */}
                  <TableCell style={headerText}>Titulo</TableCell>
                  <TableCell style={headerText}>Direccion</TableCell>
                  <TableCell style={headerText} sx={{ width: "50px" }}>
                    Precio
                  </TableCell>
                  <TableCell style={headerText} sx={{ width: "30px" }}>
                    Unidades
                  </TableCell>
                  <TableCell style={headerText} sx={{ width: "40px" }}>
                    Habilitado
                  </TableCell>
                  <TableCell style={headerText} sx={{ width: "30px" }}>
                    Link
                  </TableCell>

                  <TableCell style={headerText} sx={{ width: "40px" }}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inmuebles.map((row, index) => (
                  <TableRow
                    key={row.ID}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#000" : "#333", // Alterna colores negro y gris
                      borderBlockEnd: 1,
                    }}
                  >
                    <TableCell style={headerText}>{row.ID}</TableCell>
                    {/* <TableCell>{row.nombre_usuario}</TableCell> */}
                    <TableCell style={headerText}>{row.nombre}</TableCell>
                    <TableCell style={headerText}>{row.ubicacion}</TableCell>
                    <TableCell style={headerText}>{row.precio}</TableCell>
                    <TableCell style={headerText}>{row.unidades}</TableCell>
                    <TableCell style={headerText}>
                      <Switch
                        checked={row.status === "1"}
                        onChange={() => handleEstadoChange(row.ID, row.status)}
                      />
                    </TableCell>
                    <TableCell style={headerText}>
                      <Button
                        px={2}
                        style={styles.buttonGradientStyle}
                        onPress={() => handleNav(row.ID, row.nombre)}
                      >
                        Ver
                      </Button>
                    </TableCell>
                    <TableCell style={headerText}>
                      <HStack space={6} justifyContent={"center"}>
                        <Pressable
                          bg="#16a34a"
                          p={2}
                          borderRadius={10}
                          shadow={3}
                          onPress={() => handleEdit(row.ID, idUser)}
                        >
                          <FaEdit color="#fff" size={"18px"} />
                        </Pressable>

                        <Popover
                          trigger={(triggerProps) => {
                            return (
                              <Pressable
                                borderWidth={1}
                                borderColor={"#fff"}
                                bg="#e11d48"
                                p={2}
                                borderRadius={10}
                                shadow={3}
                                {...triggerProps}
                              >
                                <FaRegTrashAlt color="#fff" size={"18px"} />
                              </Pressable>
                            );
                          }}
                        >
                          <Popover.Content accessibilityLabel="Borrar Inmueble">
                            <Popover.Arrow />
                            <Popover.CloseButton />
                            <Popover.Header>
                              Borrar inmueble ID {row.ID}
                            </Popover.Header>
                            <Popover.Body>
                              Se eliminara el inmueble {row.nombre}
                              ¿Estás seguro? Esta acción no se puede deshacer.
                            </Popover.Body>
                            <Popover.Footer justifyContent="flex-end">
                              <Button.Group space={2}>
                                <Button
                                  colorScheme="danger"
                                  onPress={() => handleBorrar(row.ID)}
                                >
                                  Eliminar
                                </Button>
                              </Button.Group>
                            </Popover.Footer>
                          </Popover.Content>
                        </Popover>
                      </HStack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </View>
      )}
    </>
  );
};
export default VerInmueblesDes;
