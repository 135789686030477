import * as React from "react";
import {
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  Center,
  HStack,
  Text,
  Icon,
  ZStack,
  Image,
  Select,
  CheckIcon,
  View,
  Spinner,
} from "native-base";
import { useState, useEffect } from "react";
import fetchPost from "../helper/fetchPost";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaUserPen } from "react-icons/fa6";
import { MdAlternateEmail, MdOutlinePassword } from "react-icons/md";
import { Link } from "react-router-dom";
import URL from "../helper/baseURL";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import InputMask from "react-input-mask";
import { styles } from "../styles";

const Registro = () => {
  const navigate = useNavigate();

  //genera numero aleatorio
  const [numero, setNumero] = useState(0);

  // Función flecha para generar un número aleatorio de 2 dígitos
  const generarNumeroAleatorioDosDigitos = () =>
    Math.floor(1000 + Math.random() * 9000);

  // Generar y guardar el número aleatorio en el estado cuando el componente se monta
  useEffect(() => {
    const numeroAleatorio = generarNumeroAleatorioDosDigitos();
    setNumero(numeroAleatorio);
  }, []);

  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [name, setName] = useState("");
  const [apellidoP, setApellidoP] = useState("");
  const [phone, setPhone] = useState("");
  const [lada, setLada] = useState("");

  const [referido, setReferido] = useState("");
  const [codigoReferido, setCodigoReferido] = useState("");
  const [referidoVal, setReferidoVal] = useState(false);
  const [pais, setPais] = useState("");
  const [estado, setEstado] = useState("");
  const [estados, setEstados] = useState(null);
  const [referidoID, setReferidoID] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  //mostrar /ocultar password
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [show2, setShow2] = useState(false);
  const handleClick2 = () => setShow2(!show2);

  const estiloLink = {
    color: "#ff8b38",
    fontWeight: "bold",
    fontSize: "20px", // Tamaño de fuente similar a "sm"
    fontFamily: "Graphik",
    textAlign: "center", // Para eliminar el subrayado predeterminado
  };

  const NuevoUsuario = async () => {
    setIsLoading(true);
    if (!validarCredenciales()) {
      setIsLoading(false); // Si la validación falla, detener la ejecución
      return;
    }
    const dataLogin = new FormData();
    dataLogin.append("correo", correo.trim());
    dataLogin.append("password", password);
    dataLogin.append("nombre", name);
    dataLogin.append("apellidoP", apellidoP);
    dataLogin.append("lada", lada);
    dataLogin.append("telefono", phone);
    dataLogin.append("tipoUsuario", tipoUsuario);
    dataLogin.append("idPais", pais);
    dataLogin.append("idEstado", estado);
    dataLogin.append("referido", referidoID);
    const url = `${URL.BASE_URL}/registro`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const resUser = await fetchPost(url, options);
    console.log("res", resUser);
    window.alert("Gracias por registrarte, espera un momento");

    //Falta manejar el resultado
    if (resUser.resultado) {
      if (tipoUsuario === "1") {
        let codigoReferido = name + numero;
        generarReferido(resUser.idUsuario, codigoReferido);
      } else {
        window.alert(resUser.mensaje);
        navigate("/login");
      }
    } else {
      setIsLoading(false);
      window.alert(resUser.mensaje); // Muestra el mensaje de error
    }
    setIsLoading(false);
  };

  const generarReferido = async (idUser, codigoRef) => {
    setIsLoading(true);

    const data = new FormData();
    data.append("id_user", idUser);
    data.append("codigo", codigoRef);
    const url = `${URL.BASE_URL}/referidos/insertaCodigo`;
    const options = {
      method: "POST",
      body: data,
    };
    const resCod = await fetchPost(url, options);
    console.log("res", resCod);

    //Falta manejar el resultado
    if (resCod.status) {
      window.alert(resCod.message);
      navigate("/login");
    } else {
      window.alert("Error al generar código de referido");
      navigate("/login");
    }
  };

  // Función para validar el correo electrónico y la contraseña
  const validarCredenciales = () => {
    // Verifica si el correo contiene '@'
    const esCorreoValido = correo.includes("@");
    // Verifica si la contraseña tiene más de 5 caracteres
    const esPasswordValida = password.length > 4;

    if (!esCorreoValido) {
      window.alert("Por favor ingresa un correo electrónico válido.");
      return false;
    }

    if (!esPasswordValida) {
      window.alert("La contraseña debe tener más de 5 caracteres.");
      return false;
    }

    if (name.trim() === "") {
      window.alert("Por favor, ingresa tu nombre.");
      return false;
    }

    if (pais.trim() === "") {
      window.alert("Por favor, ingresa tu país.");
      return false;
    }
    if (estado.trim() === "") {
      window.alert("Por favor, ingresa tu Estado.");
      return false;
    }

    if (phone.trim() === "") {
      window.alert("Por favor, ingresa tu teléfono.");
      return false;
    } else if (phone.trim().length < 10 || phone.trim().length > 13) {
      window.alert("El teléfono debe tener entre 10 y 13 números.");
      return false;
    }
    if (password.trim() === "") {
      window.alert("Por favor, ingresa tu nueva contraseña.");
      return false;
    }
    if (password2.trim() === "") {
      window.alert("Por favor, confirma tu nueva contraseña.");
      return false;
    }
    if (password !== password2) {
      window.alert("La nueva contraseña y la confirmación no coinciden.");
      return false;
    }

    return true;
  };

  useEffect(() => {
    console.log("tipo: ", tipoUsuario);
    console.log("estado: ", estado);
    console.log("pais: ", pais);
  }, [tipoUsuario, estado, pais]);

  const TipoUsuarioSelect = () => {
    return (
      <Center>
        <FormControl w="100%" isRequired>
          <FormControl.Label _text={{ fontFamily: "Graphik", fontSize: "lg" }}>
            Selecciona el tipo de usuario
          </FormControl.Label>
          <Select
            minWidth="200"
            accessibilityLabel="Tipo de usuario"
            placeholder="Tipo de usuario"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5} />,
            }}
            mt="1"
            selectedValue={tipoUsuario}
            onValueChange={(itemValue) => setTipoUsuario(itemValue)}
          >
            <Select.Item label="Desarrollador inmobiliario" value={"1"} />
            <Select.Item label="Agente inmobiliario (Broker)" value={"2"} />
            <Select.Item label="Cliente" value={"3"} />
          </Select>
        </FormControl>
      </Center>
    );
  };

  const handlePais = async (idPais) => {
    setPais(idPais);

    const dataEstados = new FormData();
    dataEstados.append("country_id", idPais);

    const url = `${URL.BASE_URL}/getEstadosPorPais`;
    const options = {
      method: "POST",
      body: dataEstados,
    };
    const res = await fetchPost(url, options);
    console.log("res", res.data);

    //Falta manejar el resultado
    if (res.data) {
      setEstados(res.data);
    }
  };

  const PaisSelect = () => {
    return (
      <VStack>
        <FormControl w="100%" isRequired>
          <FormControl.Label _text={{ fontFamily: "Graphik", fontSize: "lg" }}>
            Selecciona tu país
          </FormControl.Label>
          <Select
            minWidth="200"
            accessibilityLabel="País"
            placeholder="País"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5} />,
            }}
            mt="1"
            selectedValue={pais}
            onValueChange={(itemValue) => handlePais(itemValue)}
          >
            <Select.Item label="México" value="141" />
            <Select.Item label="Estados Unidos" value="236" />
            <Select.Item label="Francia" value="76" />
            <Select.Item label="España" value="209" />
          </Select>
        </FormControl>

        <FormControl w="100%" isRequired>
          <FormControl.Label _text={{ fontFamily: "Graphik", fontSize: "lg" }}>
            Selecciona tu Estado
          </FormControl.Label>
          <Select
            minWidth="200"
            accessibilityLabel="Estado"
            placeholder="Estado"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5} />,
            }}
            mt="1"
            selectedValue={estado}
            onValueChange={(itemValue) => setEstado(itemValue)}
          >
            {estados === null
              ? null
              : estados.map((estado) => (
                  <Select.Item
                    key={estado.state_id}
                    label={estado.state_name}
                    value={estado.state_id}
                  />
                ))}
          </Select>
        </FormControl>
      </VStack>
    );
  };

  const CuadroUsuario = ({ titulo, texto }) => {
    return (
      <Box
        my={2}
        style={styles.buttonGradientStyle}
        flex={1}
        w="100%"
        borderRadius={10}
        shadow={5}
        p={2}
      >
        <Text bold color={"#fff"} fontSize={"md"}>
          {titulo}
        </Text>
        <Text color="#fff" fontSize={"sm"}>
          {texto}
        </Text>
      </Box>
    );
  };

  const renderBox = (tipoUsuario) => {
    switch (tipoUsuario) {
      case "1":
        return (
          <CuadroUsuario
            titulo={"Desarrollador inmobiliario"}
            texto={
              "Podrás subir inmuebles, venderlos, asignar brokers para su seguimiento"
            }
          />
        ); // Color para tipoUsuario 1
      case "2":
        return (
          <CuadroUsuario
            titulo={"Broker"}
            texto={
              "Podras solicitar apartar inmuebles de nuestro catálogo, realizar ventas y llevarte comisión"
            }
          />
        ); // Color para tipoUsuario 2
      case "3":
        return (
          <CuadroUsuario
            titulo={"Cliente"}
            texto={
              "Acceso a la información completa de los inmuebles, descargar planos, agregar a favoritos y más"
            }
          />
        ); // Color para tipoUsuario 3
      default:
        return null; // No mostrar nada si tipoUsuario es null o cualquier otro valor
    }
  };

  const [referidoStatus, setReferidoStatus] = useState(true);

  const handleCheckReferido = async () => {
    setReferidoStatus(false);
    const data = new FormData();
    data.append("codigo", codigoReferido);

    const url = `${URL.BASE_URL}referidos/verificaCodigo`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("res", res);
    if (res && res.status === true) {
      setReferidoVal(true);
      setReferidoID(res.data);
      setReferidoStatus(true);
      window.alert("Codigo de referido valido");
    } else if (res && res.status === false) {
      setReferidoVal(false);
      setCodigoReferido("");
      setReferidoStatus(true);
      window.alert("Error: Código de referido no encontrado");
    } else {
      setCodigoReferido("");
      setReferidoStatus(true);
      setReferidoVal(false);
      window.alert("Error de conexión, intente más tarde");
    }
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  return (
    <View>
      <ZStack h="15rem" w="100%">
        <Image
          source={{
            uri: "https://marketeasyrealty.com/fondos/nosotros.jpg",
          }}
          alt="Alternate Text"
          w="100%"
          h={96}
        />
      </ZStack>

      <Center
        w={96}
        alignSelf={"center"}
        bg={"#fff"}
        m={4}
        borderRadius={10}
        shadow={6}
        mb={8}
      >
        <Box safeArea p="2" w="90%" maxW="90%" py="8">
          <Heading
            textAlign="center"
            flex={1}
            color="coolGray.800"
            fontFamily={"Graphik"}
          >
            ¡Regístrate!
          </Heading>
          {renderBox(tipoUsuario)}
          <TipoUsuarioSelect />
          <VStack
            space={3}
            mt="5"
            display={tipoUsuario === null ? "none" : "flex"}
          >
            <PaisSelect />
            <FormControl>
              <FormControl.Label
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                Correo electrónico:{" "}
              </FormControl.Label>
              <Input
                placeholder="Email"
                onChangeText={(val) => setCorreo(val.toUpperCase())}
                value={correo}
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<MdAlternateEmail />} />
                  </Center>
                }
              />
            </FormControl>
            {/* codigo para brokers */}
            <FormControl display={tipoUsuario === "2" ? "flex" : "none"}>
              <FormControl.Label
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                ¿Cómo nos conociste?
              </FormControl.Label>
              <Select
                placeholder="¿Cómo nos conociste?"
                flex={1}
                selectedValue={referido}
                onValueChange={(itemValue) => setReferido(itemValue)}
              >
                <Select.Item label="Redes Sociales" value="0" />
                <Select.Item label="Busqueda en internet" value="1" />
                <Select.Item
                  label="Desarrolladora ó agencia inmobiliaria"
                  value="2"
                />
              </Select>
            </FormControl>

            <FormControl display={referido === "2" ? "flex" : "none"}>
              <FormControl.Label
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                Código de referido:
              </FormControl.Label>
              <Input
                placeholder="Código de referido"
                onChangeText={(val) => setCodigoReferido(val)}
                value={codigoReferido}
                InputRightElement={
                  referidoStatus ? (
                    <Button
                      colorScheme={referidoVal ? "success" : "danger"}
                      borderRightRadius={1}
                      flex={1}
                      onPress={() => handleCheckReferido()}
                    >
                      {referidoVal ? "Código Valido" : "Validar"}
                    </Button>
                  ) : (
                    <Spinner
                      bg="warning.500"
                      color={"#fff"}
                      flex={1}
                      size={24}
                      py={2}
                    />
                  )
                }
              />
            </FormControl>

            <FormControl>
              <FormControl.Label
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                Nombre:{" "}
              </FormControl.Label>
              <Input
                placeholder="Nombre"
                onChangeText={(val) => setName(val)}
                value={name}
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<FaUserPen />} />
                  </Center>
                }
              />
            </FormControl>

            <FormControl
              display={
                tipoUsuario === "2" || tipoUsuario === "3" ? "flex" : "none"
              }
            >
              <FormControl.Label
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                Apellido (s):{" "}
              </FormControl.Label>
              <Input
                placeholder="Apellido Paterno"
                onChangeText={(val) => setApellidoP(val)}
                value={apellidoP}
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<FaUserPen />} />
                  </Center>
                }
              />
            </FormControl>

            {/* Telefono */}
            <HStack space={2}>
              <FormControl w={32}>
                <FormControl.Label
                  _text={{ fontFamily: "Graphik", fontSize: "lg" }}
                >
                  LADA
                </FormControl.Label>
                <Select
                  selectedValue={lada}
                  w={32}
                  accessibilityLabel="LADA"
                  placeholder="LADA"
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size="3" />,
                  }}
                  onValueChange={(itemValue) => setLada(itemValue)}
                >
                  <Select.Item label="MX (+52)" value="+52" />
                  <Select.Item label="US (+1)" value="+1" />
                  <Select.Item label="FR (+33)" value="+33" />
                  <Select.Item label="CA (+1)" value="+1" />
                </Select>
              </FormControl>
              <FormControl flex={1}>
                <FormControl.Label
                  _text={{ fontFamily: "Graphik", fontSize: "lg" }}
                >
                  Teléfono
                </FormControl.Label>
                <InputMask
                  mask="999 999 9999"
                  value={phone}
                  onChange={handleChangePhone}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      color={"#000"}
                      value={phone}
                      onChange={handleChangePhone}
                    />
                  )}
                </InputMask>
              </FormControl>
            </HStack>

            <FormControl>
              <FormControl.Label
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                Contraseña
              </FormControl.Label>
              <Input
                type={show ? "text" : "password"}
                placeholder="Contraseña"
                onChangeText={(val) => setPassword(val)}
                value={password}
                InputRightElement={
                  <Button
                    ml={1}
                    variant="link"
                    roundedLeft={0}
                    roundedRight="md"
                    onPress={handleClick}
                  >
                    {show ? <FaRegEyeSlash /> : <FaRegEye />}
                  </Button>
                }
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<MdOutlinePassword />} />
                  </Center>
                }
              />
            </FormControl>

            <FormControl>
              <FormControl.Label
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
              >
                Confirmar contraseña
              </FormControl.Label>
              <Input
                type={show2 ? "text" : "password"}
                placeholder="Confirmar Contraseña"
                onChangeText={(val) => setPassword2(val)}
                value={password2}
                pl={2}
                InputRightElement={
                  <Button
                    ml={1}
                    variant="link"
                    roundedLeft={0}
                    roundedRight="md"
                    onPress={handleClick2}
                  >
                    {show2 ? <FaRegEyeSlash /> : <FaRegEye />}
                  </Button>
                }
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<MdOutlinePassword />} />
                  </Center>
                }
              />
            </FormControl>

            {isLoading ? (
              <Loader size="lg" color="primary.500" />
            ) : (
              <Button
                mt="2"
                minW={40}
                maxW={80}
                style={styles.buttonGradientStyle}
                _text={{ fontFamily: "Graphik", fontSize: "lg" }}
                alignSelf="center"
                onPress={() => NuevoUsuario()}
              >
                Registrarse
              </Button>
            )}
          </VStack>
          <HStack
            mt="6"
            justifyContent="center"
            alignContent={"center"}
            alignItems={"center"}
          >
            <Text
              fontSize="lg"
              color="coolGray.600"
              _dark={{
                color: "warmGray.200",
              }}
              fontFamily={"Graphik"}
            >
              ¿Ya tienes cuenta?{" "}
            </Text>
            <Link to="/login" style={estiloLink}>
              Iniciar Sesión
            </Link>
          </HStack>
        </Box>
      </Center>
    </View>
  );
};

export default Registro;
