import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";
import { Navigation, Pagination } from "swiper/modules";
import {
  Box,
  Pressable,
  Text,
  ZStack,
  Button,
  HStack,
  Divider,
} from "native-base";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";

export default function Swiper3() {
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const handleClickMenu = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const FotoPortada = ({ url, texto, nav }) => {
    const imagenDeFondo = {
      backgroundImage: `url("${url}")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "100%",
      height: "450px",
    };

    return (
      <Pressable
        onPress={() => handleClickMenu(nav)}
        w="100%"
        shadow={4}
        style={imagenDeFondo}
        _hover={{
          shadow: 9,
        }}
      >
        <ZStack
          w="100%"
          h={"450px"}
          alignItems="center"
          justifyContent="center"
        >
          <Box w="100%" h={"100px"} bg="#000" mt={"350px"} opacity={0.6} />
          <Text
            mt={"320px"}
            color="#fff"
            textAlign="center"
            fontFamily="Graphik"
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
            mx={4}
          >
            {texto}
          </Text>
        </ZStack>
      </Pressable>
    );
  };

  return (
    <Box flex={1}>
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
        breakpoints={{
          // cuando la pantalla sea >= 640px (tablet pequeña)
          640: {
            slidesPerView: 2, // Mostrar 2 slides
          },
          // cuando la pantalla sea >= 768px (tablet grande o laptops pequeñas)
          768: {
            slidesPerView: 2, // Mostrar 2 slides
          },
          // cuando la pantalla sea >= 1024px (laptops más grandes y desktops)
          1024: {
            slidesPerView: 3, // Mostrar 3 slides
          },
          // si es menor de 640px (móvil)
          0: {
            slidesPerView: 1, // Mostrar solo 1 slide
          },
        }}
      >
        <SwiperSlide>
          <FotoPortada
            url="https://marketeasyrealty.com/img/BELEHU_ROOF.webp"
            texto="Tu proyecto con nosotros"
            nav="/proyect"
          />
        </SwiperSlide>
        <SwiperSlide>
          <FotoPortada
            url="https://blog.simca.mx/hs-fs/hubfs/simca-desarrollos-exito-inversion-inmobiliaria.jpg"
            texto="Modelo de inversión"
            nav="/investment"
          />
        </SwiperSlide>
        <SwiperSlide>
          <FotoPortada
            url="https://www.calemanbienesraices.com/wp-content/uploads/2022/08/shark-tower.webp"
            texto="Conversor de divisas"
            nav="/converter"
          />
        </SwiperSlide>
        <SwiperSlide>
          <FotoPortada
            url="https://marketeasyrealty.com/img/hand.jpg"
            texto="Sobre nosotros"
            nav="/about"
          />
        </SwiperSlide>
        <SwiperSlide>
          <FotoPortada
            url="https://marketeasyrealty.com/img/contacto.jpg"
            texto="Contacto"
            nav="/contact"
          />
        </SwiperSlide>
        <SwiperSlide>
          <FotoPortada
            url="https://marketeasyrealty.com/img/oceantulum.jpg"
            texto="Nuestros desarrollos"
            nav="/properties"
          />
        </SwiperSlide>
      </Swiper>

      <HStack my={3} w="100%" justifyContent={"space-between"}>
        <Button
          onPress={() => swiperRef.current?.slidePrev()}
          bg="transparent"
          w={40}
          _hover={{ background: "#252525" }}
        >
          <Text color="white" style={{ fontFamily: "Graphik" }} fontSize={"xl"}>
            Anterior
          </Text>
          <Divider w={40} />
        </Button>
        <Button
          onPress={() => swiperRef.current?.slideNext()}
          height={12}
          bg="transparent"
          w={40}
          _hover={{ background: "#252525" }}
        >
          <Text
            color="white"
            style={{ fontFamily: "Graphik" }}
            fontSize={"xl"}
            alignSelf={"flex-end"}
          >
            Siguiente
          </Text>
          <Divider w={40} />
        </Button>
      </HStack>
    </Box>
  );
}
