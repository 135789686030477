import React from "react";
import { Pressable, VStack, Text, HStack } from "native-base";
import { IconContext } from "react-icons";

const OpcionUser = ({ funcion, icon: IconComponent, titulo, texto }) => {
  return (
    <Pressable
      onPress={funcion}
      py={3}
      px={5}
      bg="#000"
      shadow={6}
      borderRadius={20}
      borderColor="muted.300"
      borderWidth={2}
      m={3}
      w={80}
    >
      <VStack space={2}>
        <HStack space={4} alignItems="center">
          <IconContext.Provider value={{ size: "3em", color: "white" }}>
            <IconComponent />
          </IconContext.Provider>

          <Text bold fontSize="lg" color="white">
            {titulo}
          </Text>
        </HStack>

        <Text fontSize="md" color="white">
          {texto}
        </Text>
      </VStack>
    </Pressable>
  );
};
export default OpcionUser;
