import React, { useState } from "react";
import {
  VStack,
  Select,
  Input,
  FormControl,
  TextArea,
  Box,
  Button,
  View,
  useToast,
  Text,
  Spinner,
  HStack,
  CheckIcon,
  Flex,
} from "native-base";
import URL from "../helper/baseURL";
import fetchPost from "../helper/fetchPost";
import { turquesa, azulclaro } from "../helper/colors";
import ReactInputMask from "react-input-mask";
import { styles } from "../styles";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplate,
//   validateCaptcha,
// } from "react-simple-captcha";

const ContactoSimple = () => {
  const toast = useToast();

  const mostrarToast = (mensaje) => {
    toast.show({
      description: mensaje,
      placement: "top", // Puedes cambiar la ubicación según sea necesario
      render: () => {
        return (
          <Box
            bg={azulclaro}
            px="6"
            py="4"
            rounded="sm"
            mb={5}
            borderWidth={3}
            borderColor={turquesa}
            shadow={7}
          >
            <Text fontFamily="Circular" color="white">
              {mensaje}
            </Text>
          </Box>
        );
      },
    });
  };

  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [lada, setLada] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [tipo, setTipo] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const validarFormulario = () => {
    // let userCaptchaValue = document.getElementById("user_captcha_input").value;
    // Validar nombre
    if (nombre.length <= 3) {
      mostrarToast("El nombre debe tener más de 3 letras.");
      return false;
    }

    // Validar correo electrónico
    const regexEmail = /\S+@\S+\.\S+/;
    if (!regexEmail.test(email)) {
      mostrarToast("Por favor, introduce un correo electrónico válido.");
      return false;
    }

    // Validar teléfono
    // Limpiar los caracteres no numéricos (espacios, etc.)
    const telefonoLimpio = telefono.replace(/\D/g, ""); // Eliminar todo lo que no sea un número

    // Validar que tenga exactamente 10 dígitos
    if (telefonoLimpio.length !== 10) {
      mostrarToast("El teléfono debe tener 10 dígitos");
      return false;
    }

    if (lada === "") {
      mostrarToast("Ingrese LADA");
      return false;
    }

    // Validar mensaje
    if (mensaje.trim() === "") {
      mostrarToast("Por favor, escribe un mensaje.");
      return false;
    }

    // if (!validateCaptcha(userCaptchaValue)) {
    //   mostrarToast("Captcha Incorrecto");
    //   return false;
    // }

    return true;
  };

  const navigate = useNavigate();
  const handleNavegation = () => {
    scroll.scrollToTop();
    navigate("/thanks");
  };
  //falta configurar
  const handleSubmit = async () => {
    validarFormulario();
    if (!validarFormulario()) {
      return; // Detiene la función si la validación falla
    } else {
      const BASE_URL = URL.BASE_URL;

      const dataContacto = new FormData();
      //para enviar datos por POST
      dataContacto.append("nombre", nombre);
      dataContacto.append("email", email);
      dataContacto.append("telefono", lada + " " + telefono);
      dataContacto.append("mensaje", mensaje);
      dataContacto.append("tipo", tipo);

      const url = `${BASE_URL}enviarcorreo`;
      const options = {
        method: "POST",
        body: dataContacto,
      };
      setIsLoading(true);
      console.log("url: ", url);
      try {
        const response = await fetchPost(url, options);
        console.log("respuesta: ", response);
        if (response.status) {
          // Si el estado es verdadero, muestra un mensaje de éxito
          window.alert("Mensaje enviado");
          setIsLoading(false);
        } else {
          // Si el estado es falso, muestra un mensaje de error
          window.alert(response.message);
          setIsLoading(false);
        }
      } catch (error) {
        // Manejar cualquier otro error (por ejemplo, problemas de red)
        window.alert("Error al procesar la solicitud, intentalo más tarde");
        setIsLoading(false);
      }
    }
  };
  const handleChangePhone = (e) => {
    setTelefono(e.target.value);
  };

  const color = "#bd671f";

  return (
    <View flex={1}>
      {/* FORMULARIO CONTACTO */}
      <VStack flex={1} alignSelf="center" shadow={7} pb={2} borderRadius={5}>
        <Text
          style={{ fontFamily: "Times" }}
          pt={2}
          fontSize="2xl"
          color={color}
          pl={6}
        >
          CONTÁCTANOS
        </Text>
        <Text px={2} fontSize="xl" style={styles.colorTitulos} pl={6}>
          PARA BENEFICIARTE DE INVERSIONES SEGURAS
        </Text>

        <VStack
          w="95%"
          space={4}
          alignSelf="center"
          px={2}
          borderRadius={5}
          my={1}
        >
          <Text fontFamily="Graphik" py={2} fontSize="md" color="white">
            Dejános tu información y nos comunicaremos contigo para brindarte
            toda la información que necesites.
          </Text>

          <FormControl isRequired>
            <FormControl.Label>
              <Text style={styles.colorTitulos}>Nombre: </Text>
            </FormControl.Label>
            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              shadow={7}
              value={nombre}
              placeholder={"Nombre"}
              onChangeText={(e) => setNombre(e)}
            />
          </FormControl>

          <VStack flex={1}>
            <FormControl.Label>
              <Text style={styles.colorTitulos}>Email: </Text>
            </FormControl.Label>

            <FormControl isRequired flex={1}>
              {/* <FormControl.Label _text={{ bold: true }}>Correo: </FormControl.Label> */}
              <Input
                shadow={6}
                value={email}
                type="email"
                placeholder="Correo Electrónico"
                onChangeText={(e) => setEmail(e)}
                bg="#000"
                color="#ff8b39"
                focusOutlineColor="#ff8b39"
              />
            </FormControl>
          </VStack>

          <HStack space={2}>
            <FormControl w={32}>
              <FormControl.Label>
                <Text style={styles.colorTitulos}>LADA: </Text>
              </FormControl.Label>
              <Select
                selectedValue={lada}
                w={32}
                bg="#000"
                color="#ff8b39"
                accessibilityLabel="LADA"
                placeholder="LADA"
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="3" />,
                }}
                onValueChange={(itemValue) => setLada(itemValue)}
              >
                <Select.Item label="MX (+52)" value="+52" />
                <Select.Item label="US (+1)" value="+1" />
                <Select.Item label="FR (+33)" value="+33" />
                <Select.Item label="CA (+1)" value="+1" />
              </Select>
            </FormControl>
            <FormControl flex={1}>
              <FormControl.Label>
                <Text style={styles.colorTitulos}>Teléfono: </Text>
              </FormControl.Label>
              <ReactInputMask
                bg="white"
                mask="999 999 9999"
                value={telefono}
                onChange={handleChangePhone}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    flex={1}
                    bg="#000"
                    color="#ff8b39"
                    focusOutlineColor="#ff8b39"
                    value={telefono}
                    onChange={handleChangePhone}
                  />
                )}
              </ReactInputMask>
            </FormControl>
          </HStack>
          <Flex borderRadius={5}>
            <FormControl>
              <FormControl.Label>
                <Text style={styles.colorTitulos}>Eres: </Text>
              </FormControl.Label>
              <Select
                placeholder="Eres: "
                minWidth="64"
                bg="#000"
                color="#ff8b39"
                onValueChange={(itemValue) => setTipo(itemValue)} // Actualiza el estado
                selectedValue={tipo} // Para que se muestre el valor seleccionado
              >
                <Select.Item label="Particular" value="Particular" />
                <Select.Item
                  label="Asesor inmobiliario"
                  value="Asesor inmobiliario"
                />
                <Select.Item
                  label="Asesor de gestión de patrimonio"
                  value="Asesor de gestión de patrimonio"
                />
                <Select.Item
                  label="Asesor financiero"
                  value="Asesor financiero"
                />
                <Select.Item
                  label="Dueño de una agencia inmobiliaria"
                  value="Dueño de una agencia inmobiliaria"
                />
                <Select.Item label="Masterbroker" value="Masterbroker" />
                <Select.Item
                  label="Desarrollador inmobiliario"
                  value="Desarrollador inmobiliario"
                />
              </Select>
            </FormControl>
          </Flex>

          <FormControl isRequired>
            <FormControl.Label>
              <Text style={styles.colorTitulos}>Mensaje:</Text>
            </FormControl.Label>
            <TextArea
              shadow={6}
              value={mensaje}
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Escribe tu mensaje aquí"
              onChangeText={(e) => setMensaje(e)}
            />
          </FormControl>

          {isLoading ? (
            <Spinner mt={2} size={56} color={"#bd6823"} />
          ) : (
            <Button
              shadow={6}
              style={styles.buttonGradientStyle}
              onPress={() => handleSubmit()}
              my={4}
              size="lg"
              py={2}
              w="60%"
              alignSelf="center"
            >
              Enviar
            </Button>
          )}
        </VStack>
      </VStack>
    </View>
  );
};

export default ContactoSimple;
