import { View } from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import ContactoFooter from "../components/ContactoFooter";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FlatListBusqueda from "../components/FlatListBusqueda";

const Busqueda = () => {
  const location = useLocation();
  const searchQuery = location.state?.query || "";

  useEffect(() => {
    // Aquí puedes hacer algo con searchQuery, como pasarlo a FlatListInmuebles
    console.log("Buscar inmuebles con:", searchQuery);
  }, [searchQuery]);
  return (
    <View>
      <FotoPortada
        url="https://marketeasyrealty.com/fondos/inmuebles.png"
        texto="BUSQUEDA"
        posicion="center"
        subtext="Aquí encontrarás los resultados de tu búsqueda"
      />

      <View alignContent={"center"} pt={12} w="100%">
        <FlatListBusqueda searchQuery={searchQuery} />
      </View>

      <ContactoFooter />
    </View>
  );
};

export default Busqueda;
