import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Button, Heading, Text, View, VStack } from "native-base";
import fetchPost from "../../../helper/fetchPost";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import URL from "../../../helper/baseURL";
import { styles } from "../../../styles";
import SuscripcionModal from "../../../components/global/SuscripcionModal";

const ApartadosBroker = () => {
  const { tipo, userId } = useUser();
  const [idUsuario, setIdUsuario] = useState(userId);
  const navigate = useNavigate();
  const [apartados, setApartados] = useState([]);
  const [loading, setLoading] = useState(false);

  const getId = async () => {
    try {
      if (!idUsuario) {
        // Solo obtiene el ID si no está disponible en el estado
        const value = await AsyncStorage.getItem("userId");
        if (value) {
          console.log("async id getid: ", value);
          setIdUsuario(value); // Actualiza el estado de idUsuario
          getData(value);
          return value; // Retorna el valor correcto
        }
      } else {
        getData(idUsuario);
      }
    } catch (e) {
      console.error("Error al leer el valor de AsyncStorage:", e);
    }
    return null;
  };

  // const fetchData = async () => {
  //   setLoading(true);
  //   let id = idUsuario;

  //   if (!id) {
  //     id = await getId(); // Solo obtiene el ID si no está disponible en el estado
  //   }

  //   console.log("id fetch data", id);
  //   if (id) {
  //     await getData(id);
  //   } else {
  //     console.error("No se pudo obtener el ID de usuario");
  //   }

  //   setLoading(false);
  // };
  const getData = async (id) => {
    const data = new FormData();
    data.append("broker", id);

    const url = `${URL.BASE_URL}/leads/broker`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("broker getData: ", id);
    console.log("respuesta prospectos: ", res);
    setApartados(res.data);
  };
  const handleVerInmueble = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  useEffect(() => {
    if (!idUsuario) {
      getId();
    } else {
      getData(idUsuario);
    }
    //fetchData();
  }, [idUsuario]); // Se ejecuta cuando idUsuario cambia

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    textAlign: "center",
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };

  if (tipo !== "2") {
    return <Login />;
  }

  return (
    <View
      flex={1}
      mx={{ base: 1, md: 5, lg: 10 }}
      alignItems="center"
      alignSelf={"center"}
      justifyContent={"center"}
      justifyItems={"center"}
      my={20}
    >
      <SuscripcionModal />
      <h1 style={{ color: "white", textAlign: "center" }}>
        Lista de prospectos
      </h1>

      {apartados === null ? (
        <View>
          <Heading alignSelf={"center"} my={20} h={96} color="white">
            No tienes prospectos por el momento
          </Heading>
        </View>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ background: "#000" }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "#ff7e37" }}>
                <TableCell style={headerText} sx={{ maxWidth: "30px" }}>
                  ID
                </TableCell>
                <TableCell style={headerText}>Inmueble</TableCell>
                <TableCell style={headerText}>Prospecto</TableCell>
                <TableCell style={headerText}>Email</TableCell>
                <TableCell style={headerText}>Teléfono</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apartados.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#000" : "#333", // Alterna colores negro y gris
                    borderBlockEnd: 1,
                  }}
                >
                  <TableCell style={headerText} sx={{ width: "30px" }}>
                    {row.id}
                  </TableCell>
                  <TableCell style={headerText}>
                    <VStack
                      alignSelf={"center"}
                      alignItems={"center"}
                      space={1}
                    >
                      <Text color="white" bold>
                        {row.inmueble_nombre}
                      </Text>
                      <Button
                        w={20}
                        style={styles.buttonGradientStyle}
                        onPress={() =>
                          handleVerInmueble(
                            `/property/${row.inmueble}/${row.inmueble_nombre}`
                          )
                        }
                      >
                        Ver
                      </Button>
                    </VStack>
                  </TableCell>
                  <TableCell style={headerText}>
                    {row.usuario_nombre + " " + row.usuario_apellido}
                  </TableCell>
                  <TableCell style={headerText}>{row.usuario_correo}</TableCell>
                  <TableCell style={headerText}>
                    {row.usuario_lada + " " + row.usuario_telefono}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </View>
  );
};
export default ApartadosBroker;
