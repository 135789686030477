import React from "react";
import {
  Text,
  Pressable,
  HStack,
  Image,
  Stack,
  VStack,
  Center,
} from "native-base";
import { IconContext } from "react-icons";

import { useNavigate } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { animateScroll as scroll } from "react-scroll";
import whatsapp from "../Lotties/whatsapp.json";
import Lottie from "lottie-react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { TfiYoutube } from "react-icons/tfi";

const Footer = () => {
  const ButtonMenu = ({ link, text }) => {
    const navigate = useNavigate();
    const handleClickMenu = (vista) => {
      scroll.scrollToTop();
      navigate(vista);
    };

    return (
      <Pressable mx={2} px={2} onPress={() => handleClickMenu(link)} w="auto">
        <Text
          style={{ fontFamily: "Garet" }}
          p={1}
          fontSize={["xs", "xs", "sm", "md", "lg"]}
          color={"#fff"}
        >
          {text}
        </Text>
      </Pressable>
    );
  };

  const handlePressEmail = () => {
    console.log("email");
  };

  const handlePressWA = () => {
    // El número de teléfono de WhatsApp al que se enviará un mensaje (puedes cambiarlo según tus necesidades)
    // const whatsappNumber = "9982304219";
    const whatsappNumber = "998555555";
    // Construye la URL de WhatsApp con el número de teléfono
    const whatsappUrl = `https://wa.me/${whatsappNumber}`;
    // Abre la URL en una nueva pestaña
    window.open(whatsappUrl, "_blank");
  };

  const handlePressFB = () => {
    // La URL de Facebook a la que se redirigirá al presionar el ícono
    //const facebookUrl = "https://www.facebook.com/";
    // Abre la URL en una nueva pestaña
    //window.open(facebookUrl, "_blank");
  };

  const handlePressIG = () => {
    // La URL de Facebook a la que se redirigirá al presionar el ícono
    //const facebookUrl = "https://www.instagram.com/";

    // Abre la URL en una nueva pestaña
    //window.open(facebookUrl, "_blank");
    console.log("hola");
  };

  const handlePressMap = () => {
    // La URL de Facebook a la que se redirigirá al presionar el ícono
    //const facebookUrl = "https://maps.app.goo.gl/";

    // Abre la URL en una nueva pestaña
    // window.open(facebookUrl, "_blank");
    console.log("hola");
  };

  const handlePressLP = () => {
    // La URL de Facebook a la que se redirigirá al presionar el ícono
    //const lpUrl = "https://nuevo.lpmarketinggroup.com.mx/";

    // Abre la URL en una nueva pestaña
    //window.open(lpUrl, "_blank");
    console.log("hola");
  };

  const handlePress = (link) => {
    // window.open(link, "_blank");
  };

  return (
    <footer>
      {/* Boton WhatsAP */}
      <Pressable
        bottom={0}
        zIndex={9}
        position={"fixed"}
        justifyContent={"flex-start"}
        w={[16, 16, 24, 32]}
        h={[16, 16, 24, 32]}
        right={2}
        onPress={() => handlePressWA()}
      >
        <Lottie animationData={whatsapp} loop={true} />
      </Pressable>
      <VStack>
        <Stack
          direction="column"
          bg={"#000"}
          w="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            source={{
              uri: "https://marketeasyrealty.com/img/logo-pngblanco.png",
            }}
            alt="Alternate Text"
            h={[40]}
            w={[96]}
            resizeMode="contain"
          />

          {/* Direccion */}
          <Pressable
            flex={1}
            onPress={() => {
              handlePressMap();
            }}
          >
            <HStack
              alignSelf={"center"}
              justifyContent="center"
              alignItems="center"
              justifyItems={"center"}
              my={3}
              w="100%"
            >
              <Center>
                <MdLocationOn size={"2.6em"} color="#fff" />
              </Center>
              <Text
                color="#ffffff"
                fontSize={"sm"}
                fontFamily={"Baskerville"}
                mx={2}
                textAlign={"center"}
              >
                Cenote Tercer Cielo C.P 77766, AV. Costera Boca Paila,
                Supermanzana KM 7.6, Tulum, Q.R.
              </Text>
            </HStack>
          </Pressable>
          {/* Correo contacto */}
          <Pressable
            flex={1}
            onPress={() => {
              handlePressEmail();
            }}
          >
            <HStack
              alignSelf={"center"}
              justifyContent="center"
              alignItems="center"
            >
              <IconContext.Provider value={{ color: "#edf5f7", size: "1.8em" }}>
                <FiMail />
              </IconContext.Provider>
              <Text
                color="#ffffff"
                fontSize={"md"}
                fontFamily={"Baskerville"}
                mx={2}
                letterSpacing={1}
              >
                contacto@marketeasyrealty.com
              </Text>
            </HStack>
          </Pressable>

          {/* Redes sociales */}
          <HStack
            space={3}
            justifyContent="center"
            alignSelf={"center"}
            alignItems={"center"}
          >
            <Pressable
              p={4}
              onPress={() => {
                handlePressFB();
              }}
            >
              <IconContext.Provider value={{ color: "#edf5f7", size: "3em" }}>
                <FaFacebook />
              </IconContext.Provider>
            </Pressable>
            <Pressable
              p={4}
              onPress={() => {
                handlePressIG();
              }}
            >
              <IconContext.Provider value={{ color: "#edf5f7", size: "3em" }}>
                <FaInstagram />
              </IconContext.Provider>
            </Pressable>

            <Pressable
              p={4}
              onPress={() => {
                handlePress("https://www.youtube.com/");
              }}
            >
              <IconContext.Provider value={{ color: "#edf5f7", size: "2.5em" }}>
                <TfiYoutube />
              </IconContext.Provider>
            </Pressable>
          </HStack>
        </Stack>

        <Stack
          direction={["column", "row", "row", "row", "row"]}
          alignItems="center"
          alignSelf="center"
          justifyContent="center"
          py={2}
          bg="#000"
          flex={1}
          w="100%"
        >
          <ButtonMenu link="/" text="© 2024 Market Easy Realty" />
          <HStack>
            <ButtonMenu
              link="/terms&conditions"
              text="Términos y condiciones"
            />
            <ButtonMenu link="/privacy" text="Política de privacidad" />
          </HStack>
        </Stack>

        <HStack
          space={4}
          alignSelf={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          bg="#000"
          w="100%"
          pb={4}
        >
          <Text
            textAlign={"center"}
            fontFamily={"Garet"}
            fontSize={"xs"}
            color="#fff"
          >
            Created and designed by:
          </Text>
          <Pressable onPress={() => handlePressLP()} bg="#000">
            <Image
              alignSelf={"center"}
              source={{
                uri: "https://marketeasyrealty.com/img/lp-logo-blanco.png",
              }}
              alt="Alternate Text"
              w={32}
              h={10}
              resizeMode="contain"
            />
          </Pressable>
        </HStack>
      </VStack>
    </footer>
  );
};

export default Footer;
