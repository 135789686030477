import React, { useState, useEffect } from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import fetchPost from "../../../helper/fetchPost";
import { Heading, View, Spinner, Button } from "native-base";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import URL from "../../../helper/baseURL";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { styles } from "../../../styles";
import SuscripcionModal from "../../../components/global/SuscripcionModal";

const VerBrokersDes = () => {
  const { tipo, userId } = useUser();
  const [usuarios, setUsuarios] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [idUser, setIdUser] = useState(userId);

  // Mantuve la función para obtener datos usuarip
  const verUsuarios = async (id) => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append("id_usuario", id);
      const BASE_URL = URL.BASE_URL;
      const url = `${BASE_URL}/desarrolladora/verBrokers`;
      const options = {
        method: "POST",
        body: data,
      };
      const res = await fetchPost(url, options);
      console.log(res.success);
      console.log(res.data);
      if (res.success === true) {
        setUsuarios(res.data);
        setLoading(false);
      }

      //setUsuarios(res);
    } catch (error) {
      console.error("Error al obtener datos de usuarios:", error);
    }
  };

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    textAlign: "center",
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };
  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        verUsuarios(value);
        setIdUser(value);
      }
    } catch (e) {
      // error reading value
    }
  };

  useEffect(() => {
    if (userId === null) {
      getData();
    } else {
      verUsuarios(idUser);
    }
  }, []);

  const handlePress = (id, nombre) => {
    scroll.scrollToTop();
    navigate(`/userinformation/${id}/${nombre}/`);
  };

  if (tipo !== "1") {
    return <Login />;
  }

  if (usuarios === null) {
    return (
      <View>
        <Heading alignSelf={"center"} my={20} h={96} color="white">
          No tienes Brokers vinculados por el momento
        </Heading>
      </View>
    );
  }

  return (
    <View px={0} flex={1} py={16}>
      <SuscripcionModal />
      <Heading my={3} alignSelf="center" color="white">
        Lista de Brokers vinculados
      </Heading>

      {loading ? (
        <Spinner size="2xl" my={4} />
      ) : (
        <TableContainer component={Paper} style={{ zIndex: 0 }}>
          <Table sx={{ background: "#000" }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "#ff7e37" }}>
                <TableCell style={headerText}>ID</TableCell>
                <TableCell style={headerText}>Nombre</TableCell>
                <TableCell style={headerText}>Correo</TableCell>
                <TableCell style={headerText}>Teléfono</TableCell>
                <TableCell style={headerText}>Fecha de Creación</TableCell>
                <TableCell style={headerText}>Ver Perfil</TableCell>
                {/* <TableCell style={headerText}>Membresía</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {usuarios.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#000" : "#333", // Alterna colores negro y gris
                    borderBlockEnd: 1,
                  }}
                >
                  <TableCell style={headerText}>{row.ID}</TableCell>
                  <TableCell style={headerText}>
                    {row.nombre} {row.apellidoP}
                  </TableCell>
                  <TableCell style={headerText}>{row.correo}</TableCell>
                  <TableCell style={headerText}>{row.telefono}</TableCell>

                  <TableCell style={headerText}>{row.fechaRegistro}</TableCell>
                  <TableCell style={headerText}>
                    <Button
                      style={styles.buttonGradientStyle}
                      onPress={() => handlePress(row.ID, row.nombre)}
                    >
                      Ver Perfil
                    </Button>
                  </TableCell>
                  {/* <TableCell>{row.status}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </View>
  );
};
export default VerBrokersDes;
