import React, { useState, useEffect } from "react";
import {
  View,
  HStack,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  Center,
  Select,
  Text,
  Stack,
  Icon,
} from "native-base";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import fetchPost from "../../../helper/fetchPost";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaUserPen } from "react-icons/fa6";
import { MdAlternateEmail, MdPhone, MdOutlinePassword } from "react-icons/md";
import Loader from "../../../components/Loader";
import URL from "../../../helper/baseURL";
import { HiOutlineUserAdd } from "react-icons/hi";

const AgregarUsuarioAdmin = () => {
  const { tipo, userId } = useUser();

  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [name, setName] = useState("");
  const [apellidoP, setApellidoP] = useState("");
  const [apellidoM, setApellidoM] = useState("");
  const [phone, setPhone] = useState("");
  const [tipoUser, setTipoUser] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  //mostrar /ocultar password
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [show2, setShow2] = useState(false);
  const handleClick2 = () => setShow2(!show2);

  const NuevoUsuario = async () => {
    setIsLoading(true);
    if (!validarCredenciales()) {
      setIsLoading(false); // Si la validación falla, detener la ejecución
      return;
    }
    const dataLogin = new FormData();
    dataLogin.append("correo", correo.trim());
    dataLogin.append("password", password);
    dataLogin.append("nombre", name);
    dataLogin.append("apellidoP", apellidoP);
    dataLogin.append("apellidoM", apellidoM);
    dataLogin.append("telefono", phone);
    dataLogin.append("tipoUsuario", tipoUser);
    const url = `${URL.BASE_URL}/registro`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("res", res);
    window.alert(res.mensaje);
    setIsLoading(false);
    //Falta manejar el resultado
    if (res.resultado) {
      // navigate("/Login");
    }
  };

  // Función para validar el correo electrónico y la contraseña
  const validarCredenciales = () => {
    // Verifica si el correo contiene '@'
    const esCorreoValido = correo.includes("@");
    // Verifica si la contraseña tiene más de 5 caracteres
    const esPasswordValida = password.length > 4;

    if (!esCorreoValido) {
      window.alert("Por favor ingresa un correo electrónico válido.");
      return false;
    }

    if (!esPasswordValida) {
      window.alert("La contraseña debe tener más de 5 caracteres.");
      return false;
    }

    if (name.trim() === "") {
      window.alert("Por favor, ingresa tu nombre.");
      return false;
    }

    if (phone.trim() === "") {
      window.alert("Por favor, ingresa tu teléfono.");
      return false;
    } else if (phone.trim().length < 10 || phone.trim().length > 13) {
      window.alert("El teléfono debe tener entre 10 y 13 números.");
      return false;
    }
    if (password.trim() === "") {
      window.alert("Por favor, ingresa tu nueva contraseña.");
      return false;
    }
    if (password2.trim() === "") {
      window.alert("Por favor, confirma tu nueva contraseña.");
      return false;
    }
    if (password !== password2) {
      window.alert("La nueva contraseña y la confirmación no coinciden.");
      return false;
    }
    if (tipoUser === null) {
      window.alert("Selecciona un tipo de usuario");
      return false;
    }

    return true;
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
    alignSelf: "center",
  };

  //limpia el apellido en caso de ser desarrolladora
  useEffect(() => {
    if (tipoUser === "2") {
      setApellidoM("");
      setApellidoP("");
    }
  }, [tipoUser]);

  if (tipo !== "0") {
    return <Login />;
  }

  return (
    <View flex={1} mx={16} my={16} alignSelf="center" bg="white">
      <HStack justifyContent="center" alignItems="center" space={4}>
        <HiOutlineUserAdd size={54} />
        <h1 style={{ textAlign: "center" }}>Agrega nuevo usuario</h1>
      </HStack>

      <Stack
        space={4}
        mt={4}
        direction={{ base: "column", md: "row" }}
        alignSelf={"center"}
      >
        {/* tipo de usuario */}
        <VStack w={56} mx={4} alignSelf="center">
          <FormControl.Label>Tipo de usuario</FormControl.Label>
          <Select
            placeholder="Tipo de usuario"
            selectedValue={tipoUser}
            onValueChange={(itemValue) => setTipoUser(itemValue)}
          >
            <Select.Item label="Desarrolladora" value="1" />
            <Select.Item label="Broker" value="2" />
          </Select>
        </VStack>

        <FormControl w={56} mx={4} alignSelf="center">
          <FormControl.Label>Correo electrónico: </FormControl.Label>
          <Input
            placeholder="Email"
            onChangeText={(val) => setCorreo(val.toUpperCase())}
            value={correo}
            InputLeftElement={
              <Center ml={2}>
                <Icon as={<MdAlternateEmail />} />
              </Center>
            }
          />
        </FormControl>
      </Stack>

      <Center
        w="100%"
        display={tipoUser !== null ? "flex" : "none"}
        alignSelf={"center"}
      >
        <div style={stackContainerStyle}>
          <FormControl w={48} mx={4} mt={8}>
            <FormControl.Label>Nombre: </FormControl.Label>
            <Input
              placeholder="Nombre"
              onChangeText={(val) => setName(val)}
              value={name}
              InputLeftElement={
                <Center ml={2}>
                  <Icon as={<FaUserPen />} />
                </Center>
              }
            />
          </FormControl>
          {tipoUser === "2" ? (
            <FormControl w={48} mx={4} mt={8}>
              <FormControl.Label>Apellido Paterno: </FormControl.Label>
              <Input
                placeholder="Apellido(s)"
                onChangeText={(val) => setApellidoP(val)}
                value={apellidoP}
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<FaUserPen />} />
                  </Center>
                }
              />
            </FormControl>
          ) : null}

          {tipoUser === "2" ? (
            <FormControl w={48} mx={4} mt={8}>
              <FormControl.Label>Apellido Materno: </FormControl.Label>
              <Input
                placeholder="Apellido(s)"
                onChangeText={(val) => setApellidoM(val)}
                value={apellidoM}
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<FaUserPen />} />
                  </Center>
                }
              />
            </FormControl>
          ) : null}

          <FormControl w={48} mx={4} mt={8}>
            <FormControl.Label>Teléfono:</FormControl.Label>
            <Input
              placeholder="Teléfono"
              onChangeText={(val) => setPhone(val.toUpperCase())}
              value={phone}
              InputLeftElement={
                <Center ml={2}>
                  <Icon as={<MdPhone />} />
                </Center>
              }
            />
          </FormControl>
        </div>

        <Stack
          space={4}
          my={4}
          direction={{ base: "column", md: "row" }}
          alignSelf={"center"}
        >
          <FormControl w={64} mx={4} mt={8}>
            <FormControl.Label>Contraseña</FormControl.Label>
            <Input
              type={show ? "text" : "password"}
              placeholder="Contraseña"
              onChangeText={(val) => setPassword(val)}
              value={password}
              InputRightElement={
                <Button
                  ml={1}
                  variant="link"
                  roundedLeft={0}
                  roundedRight="md"
                  onPress={handleClick}
                >
                  {show ? <FaRegEyeSlash /> : <FaRegEye />}
                </Button>
              }
              InputLeftElement={
                <Center ml={2}>
                  <Icon as={<MdOutlinePassword />} />
                </Center>
              }
            />
          </FormControl>

          <FormControl w={64} mx={4} mt={8}>
            <FormControl.Label>Confirmar contraseña</FormControl.Label>
            <Input
              type={show2 ? "text" : "password"}
              placeholder="Confirmar Contraseña"
              onChangeText={(val) => setPassword2(val)}
              value={password2}
              pl={2}
              InputRightElement={
                <Button
                  ml={1}
                  variant="link"
                  roundedLeft={0}
                  roundedRight="md"
                  onPress={handleClick2}
                >
                  {show2 ? <FaRegEyeSlash /> : <FaRegEye />}
                </Button>
              }
              InputLeftElement={
                <Center ml={2}>
                  <Icon as={<MdOutlinePassword />} />
                </Center>
              }
            />
          </FormControl>
        </Stack>

        {isLoading ? (
          <Loader size="lg" color="primary.500" />
        ) : (
          <Button
            shadow={6}
            my={8}
            minW={40}
            maxW={80}
            colorScheme="indigo"
            alignSelf="center"
            onPress={() => NuevoUsuario()}
          >
            Registrar nuevo usuario
          </Button>
        )}
      </Center>
    </View>
  );
};
export default AgregarUsuarioAdmin;
