import { Box, Heading, Image, Stack, Text, VStack, View } from "native-base";
import FotoPortada from "../components/global/FotoPortada";

const Nosotros = () => {
  const Foto = ({ url }) => {
    return (
      <Image
        source={{
          uri: url,
        }}
        alt="Alternate Text"
        w="100%"
        h={96}
        display={{ base: "none", md: "block" }}
      />
    );
  };

  const textStyle = {
    fontFamily: "Graphik",
    color: "#bd6823",
    fontSize: "56px", // Ajusta el tamaño de la fuente según necesites

    fontWeight: "bold", // Esto pone el texto en negrita
  };

  return (
    <View bg="#000" pb={12}>
      <FotoPortada
        url="https://marketeasyrealty.com/fondos/nosotros.jpg"
        texto="¿QUIÉNES SOMOS?"
        subtext="Somos una plataforma digital revolucionaria que va más allá de las transacciones inmobiliarias convencionales. Somos una comunidad global unida por la pasión por la innovación, transparencia y la excelencia en el servicio al cliente."
        posicion="center"
      />
      <Image
        source={{
          uri: "https://marketeasyrealty.com/fondos/icono3.png",
        }}
        alt="Alternate Text"
        size="xl"
        resizeMode="contain"
        alignSelf={"center"}
      />

      {/* mision */}
      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
      >
        <Box w={{ base: "80%", md: "50%" }} alignSelf={"center"}>
          <Foto url="https://marketeasyrealty.com/img/mision.jpg" />
        </Box>
        <VStack w={{ base: "90%", md: "50%" }} justifyContent={"center"}>
          <Heading style={textStyle} ml="5%">
            MISIÓN
          </Heading>
          <Box w="90%" alignSelf="center">
            <Text
              fontSize={{ base: "lg", lg: "xl" }}
              style={{
                fontFamily: "Graphik",
                fontWeight: "normal",

                textAlign: "justify",
                color: "white",
              }}
            >
              Facilitar la conexión entre promotores inmobiliarios y una amplia
              red de inversores y agentes inmobiliarios internacionales es
              nuestra misión principal. En cada línea de código, en cada
              relación construida, buscamos simplificar y potenciar la
              experiencia de la inversión inmobiliaria.
            </Text>
          </Box>
        </VStack>
      </Stack>

      {/* mision */}
      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
        my={8}
      >
        <VStack w={{ base: "90%", md: "50%" }} justifyContent={"center"}>
          <Heading style={textStyle} ml="5%">
            VISIÓN
          </Heading>
          <Box w="90%" alignSelf="center">
            <Text
              fontSize={{ base: "lg", lg: "xl" }}
              style={{
                fontFamily: "Graphik",
                fontWeight: "normal",
                textAlign: "justify",
                color: "white",
              }}
            >
              Imaginamos un mundo donde las oportunidades inmobiliarias
              trascienden fronteras, donde cada proyecto tiene el potencial de
              llegar a una audiencia global. Aspiramos a ser el
              mega-comercializador líder en el mundo, ofreciendo un valor
              inigualable a nuestros usuarios y liderando la transformación en
              el mercado inmobiliario global.
            </Text>
          </Box>
        </VStack>
        <Box w={{ base: "80%", md: "50%" }} alignSelf={"center"}>
          <Foto url="https://marketeasyrealty.com/img/vision.jpg" />
        </Box>
      </Stack>

      {/* VALORES */}
      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
        space={3}
      >
        <Box w={{ base: "80%", md: "50%" }} alignSelf={"center"}>
          <Foto url="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c" />
        </Box>

        <VStack w={{ base: "90%", md: "50%" }} justifyContent={"center"}>
          <Heading style={textStyle} ml="5%">
            VALORES
          </Heading>
          <Box w="90%" alignSelf="center">
            <Text
              fontSize={{ base: "lg", lg: "xl" }}
              style={{
                fontFamily: "Graphik",
                fontWeight: "normal",
                textAlign: "justify",
                color: "white",
              }}
            >
              Nos regimos por la transparencia, la seguridad, la innovación
              continua y la excelencia en el servicio al cliente. Estos valores
              son más que principios; son los cimientos sobre los cuales
              construimos relaciones sólidas y duraderas.
            </Text>
          </Box>
        </VStack>
      </Stack>
    </View>
  );
};
export default Nosotros;
