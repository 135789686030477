import { Button, FlatList, Flex, Spinner } from "native-base";
import { useEffect, useState } from "react";
import InmuebleComponent from "./InmuebleComponent";
import fetchPost from "../helper/fetchPost";
import URL from "../helper/baseURL";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import TituloComponent from "./global/TituloComponent";
import { styles } from "../styles";

const FlatListInmueblesPortada = () => {
  const navigate = useNavigate();
  const handleClick = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };
  // Código para determinar el número de columnas basado en el tamaño de la pantalla
  const breakpoints = {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
  };

  const numColumns =
    window.innerWidth < breakpoints.sm
      ? 1
      : window.innerWidth < breakpoints.md
      ? 2
      : window.innerWidth < breakpoints.lg
      ? 2
      : window.innerWidth < breakpoints.xl
      ? 3
      : 3; // Puedes ajustar los valores según tus necesidades

  const [inmuebles, setInmuebles] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getInmuebles = async () => {
    const url = `${URL.BASE_URL}verInmueblesAleatorios`;
    const options = {
      method: "POST",
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.status) {
      setInmuebles(res.Inmuebles);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInmuebles();
  }, []);

  return (
    <>
      {isLoading ? (
        <Flex my={20}>
          <Spinner size="lg" my={20} py={20} />
        </Flex>
      ) : (
        <Flex w={"100%"} alignSelf="center">
          <TituloComponent uno="Conoce nuestras propiedades" dos="" />
          <FlatList
            // style={{ width: '100%', marginTop: 5, paddingHorizontal: '2vw' }}
            contentContainerStyle={{
              justifyContent: "center",
              alignItems: "center",
            }}
            data={inmuebles}
            numColumns={numColumns}
            keyExtractor={(item) => item.ID}
            key={numColumns.toString()} // Usa el número de columnas como clave
            renderItem={({ item }) => (
              <InmuebleComponent
                key={item.ID}
                imageUri={item.fotoportada}
                titulo={item.nombre}
                estado={item.estado}
                municipio={item.municipio}
                precio={item.precio}
                id={item.ID}
                rooms={item.habitaciones}
                bath={item.wc}
                area={item.area}
              />
            )}
          />

          <Button
            onPress={() => handleClick("/properties")}
            flex={1}
            p={4}
            shadow={7}
            style={styles.buttonGradientStyle}
            my={4}
            alignSelf={"center"}
            _text={{ fontSize: "xl", fontFamily: "Garet" }}
          >
            Ver todas las propiedades
          </Button>
        </Flex>
      )}
    </>
  );
};

export default FlatListInmueblesPortada;
