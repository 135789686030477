import { View, Text, Stack, Center } from "native-base";
import ContactoSimple from "../components/ContactoSimple";
import FotoPortada from "../components/global/FotoPortada";

const Privacidad = () => {
  return (
    <View w="100%">
      <FotoPortada
        url="https://marketeasyrealty.com/fondos/proyecto.jpg"
        texto="Política de privacidad"
      />

      <Stack w="100%" direction={["column", "column", "row", "row", "row"]}>
        <Stack
          direction={"column"}
          w={["90%", "90%", "50%", "50%", "50%"]}
          mx="5%"
          my={10}
          alignSelf="center"
          justifyContent="center"
        >
          <Text bold fontSize="xl" color={"#fff"}>
            Política de Privacidad
          </Text>
          <Text fontSize="2xl" bold color={"#fff"}>
            Política de Privacidad de Market Easy Realty
          </Text>

          {/* Sección Introducción */}
          <Text fontSize="lg" bold color={"#fff"}>
            1. Introducción
          </Text>
          <Text color={"#fff"}>
            En Market Easy Realty, respetamos su privacidad y estamos
            comprometidos con la protección de sus datos personales. Esta
            política de privacidad explica cómo recopilamos, usamos, compartimos
            y protegemos su información personal en relación con nuestros
            servicios de renta vacacional.
          </Text>

          {/* Sección Información que Recopilamos */}
          <Text fontSize="lg" bold color={"#fff"}>
            2. Información que Recopilamos
          </Text>
          <Text color={"#fff"}>
            Podemos recopilar diferentes tipos de información personal,
            incluyendo: Información de Identificación (nombre, dirección de
            correo electrónico, número de teléfono, dirección postal y otros
            datos de contacto), Información de Pago (detalles de tarjeta de
            crédito/débito y otra información financiera necesaria para procesar
            pagos), Información de reserva (fechas de estancia, preferencias de
            alojamiento y detalles de compañeros de viaje), Información Técnica
            (dirección IP, datos de navegación, tipo de dispositivo y
            preferencias de idioma).
          </Text>

          {/* Sección Uso de su Información */}
          <Text fontSize="lg" bold color={"#fff"}>
            3. Uso de su Información
          </Text>
          <Text color={"#fff"}>
            Utilizamos su información para procesar y gestionar sus reservas,
            comunicarnos con usted sobre su reserva o servicios solicitados,
            mejorar nuestros servicios y personalizar su experiencia, realizar
            análisis y estudios de mercado, enviarle promociones y ofertas
            especiales, si ha optado por recibirlas.
          </Text>

          {/* Sección Compartir su Información */}
          <Text fontSize="lg" bold color={"#fff"}>
            4. Compartir su Información
          </Text>
          <Text color={"#fff"}>
            Podemos compartir su información con proveedores de servicios que
            nos ayudan con operaciones comerciales (como procesamiento de pagos,
            análisis de datos, etc.), autoridades legales o reguladoras, cuando
            sea requerido por ley, y terceros en caso de una fusión, venta,
            reestructuración, adquisición, empresa conjunta, cesión,
            transferencia o disposición de todo o cualquier parte de nuestro
            negocio.
          </Text>

          {/* Sección Seguridad de la Información */}
          <Text fontSize="lg" bold color={"#fff"}>
            5. Seguridad de la Información
          </Text>
          <Text color={"#fff"}>
            Implementamos medidas de seguridad para proteger su información
            personal contra el acceso no autorizado, alteración, divulgación o
            destrucción.
          </Text>

          {/* Sección Sus Derechos */}
          <Text fontSize="lg" bold color={"#fff"}>
            6. Sus Derechos
          </Text>
          <Text color={"#fff"}>
            Usted tiene derecho a acceder, corregir, eliminar o limitar el uso
            de su información personal. También puede oponerse al procesamiento
            de sus datos o solicitar su portabilidad.
          </Text>

          {/* Sección Cambios en la Política de Privacidad */}
          <Text fontSize="lg" bold color={"#fff"}>
            7. Cambios en la Política de Privacidad
          </Text>
          <Text color={"#fff"}>
            Nos reservamos el derecho de modificar esta política de privacidad
            en cualquier momento. Cualquier cambio será comunicado a través de
            nuestro sitio web o por correo electrónico.
          </Text>
        </Stack>

        <Center direction={"column"} w={["90%", "90%", "40%", "40%", "40%"]}>
          <ContactoSimple />
        </Center>
      </Stack>
    </View>
  );
};
export default Privacidad;
