import { View, Box, Stack, Heading, Text } from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import ContactoFooter from "../components/ContactoFooter";
import InvitacionInmueble from "../components/global/InvitacionInmueble";

const TuProyecto = () => {
  const backgroundImageStyle = {
    backgroundImage: `linear-gradient(to bottom, black, transparent, black), url('https://marketeasyrealty.com/fondos/proyectofondo.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "auto", // Ajusta la altura según lo que necesites
    color: "white", // Esto es opcional, si quieres que el texto sea blanco sobre el fondo
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const Caja = ({ titulo, texto, numero }) => {
    return (
      <Box
        w={{ base: "100%", lg: "33%" }}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        h={{ base: "300px", md: "200px", lg: "280px" }}
        borderWidth={1}
        borderColor={"white"}
        alignSelf={"center"}
      >
        <Heading
          color="#bd631d"
          fontFamily={"Graphik"}
          px={{ base: 5, md: 10 }}
          pt={5}
          fontSize={"5xl"}
        >
          {numero}
        </Heading>
        <Text
          px={{ base: 5, md: 10 }}
          fontSize="2xl"
          color="white"
          lineHeight={22}
          fontFamily={"Graphik"}
          textAlign={"justify"}
        >
          {titulo}
        </Text>
        <Text
          px={{ base: 5, md: 10 }}
          pt={2}
          fontSize="xl"
          color="white"
          lineHeight={22}
          fontFamily={"Graphik"}
          textAlign={"justify"}
        >
          {texto}
        </Text>
      </Box>
    );
  };

  return (
    <View bg="#000">
      <FotoPortada
        url="https://marketeasyrealty.com/fondos/proyecto.jpg"
        texto="TU PROYECTO CON NOSOTROS"
        subtext=" Sé parte de nuestra plataforma y obtén beneficios exclusivos."
        posicion="bottom"
      />
      <Box w="90%" alignSelf="center">
        <Heading
          fontSize={{ base: "xl", md: "4xl" }}
          mt={5}
          style={{ fontFamily: "Times", textAlign: "center", color: "white" }}
        >
          Potencia tu Desarrollo Inmobiliario con Market Easy Realty
        </Heading>
      </Box>
      <Box flex={1}>
        <InvitacionInmueble />
      </Box>

      <View style={backgroundImageStyle}>
        <Stack
          direction={{ base: "column", md: "column", lg: "row" }}
          space={8}
          justifyContent={"center"}
          alignSelf={"center"}
          w="80%"
          mt={5}
        >
          <Caja
            titulo="EXPOSICIÓN GLOBAL"
            texto="Tu desarrollo tendrá una exposición sin precedentes antes una audiencia global de inversores interesados en proyectos de lujo."
            numero="01"
          />
          <Caja
            titulo="PROMOCIÓN PROFESIONAL"
            texto="Nuestro equipo de marketing trabajará estrechamente contigo para crear campañas de promoción personalizadas"
            numero="02"
          />
          <Caja
            titulo="INVERSIONISTAS CALIFICADOS"
            texto="Conecta con una red selecta e inversionistas calificados y potenciales compradores."
            numero="03"
          />
        </Stack>
        <Stack
          direction={{ base: "column", md: "column", lg: "row" }}
          space={8}
          justifyContent={"center"}
          alignSelf={"center"}
          w="80%"
          mt={5}
        >
          <Caja
            titulo="ASESORAMIENTO ESTRATÉGICO"
            texto="Recibe orientación de nuestro equipo de expertos en bienes raíces quienes te brindan el apoyo para optimizar tus procesos."
            numero="04"
          />
          <Caja
            titulo="FACILIDAD DE GESTIÓN"
            texto="y comercialización de tu desarrollo al utilizar nuestra plataforma."
            numero="05"
          />
          <Box
            w="33%"
            h={"270px"}
            display={{ base: "none", md: "none", lg: "block" }}
          ></Box>
        </Stack>
      </View>
      <ContactoFooter />
    </View>
  );
};

export default TuProyecto;
