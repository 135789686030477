import React, { useState, useEffect } from "react";
import {
  HStack,
  Text,
  View,
  Select,
  Input,
  Button,
  VStack,
  Flex,
} from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import { styles } from "../styles";

const ConversorDivisas = () => {
  const [precioUSD, setPrecioUSD] = useState(null);
  const [monto, setMonto] = useState("");
  const [monedaOrigen, setMonedaOrigen] = useState("USD");
  const [monedaDestino, setMonedaDestino] = useState("MXN");
  const [resultado, setResultado] = useState(null);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch(
          `https://open.er-api.com/v6/latest/${monedaOrigen}`
        );
        const data = await response.json();

        const tasaDeCambio = Number(data.rates[monedaDestino].toFixed(2));
        setPrecioUSD(tasaDeCambio);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate();
  }, [monedaOrigen, monedaDestino]);

  const convertirDivisas = () => {
    const montoConvertido = (monto * precioUSD).toFixed(2);
    if (montoConvertido > 0) {
      setResultado(
        `$${monto} ${monedaOrigen} = $${montoConvertido} ${monedaDestino}`
      );
    } else {
      window.alert("Ingresa una cantidad");
    }
  };

  return (
    <View bg="#000">
      <FotoPortada
        url="https://marketeasyrealty.com/fondos/caculadora.png"
        texto="CONVERSOR DE DIVISAS"
        posicion="center"
        subtext="Conoce el valor real de tus propiedades con el tipo de cambio
de la moneda de tu país."
      />

      <Text
        alignSelf={"center"}
        textAlign={"center"}
        color="#bd6822"
        fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
        fontFamily={"Times"}
        w="80%"
      >
        Favor de poner la cantidad sólo con numeros
      </Text>

      <VStack
        space={4}
        my={8}
        flex={1}
        alignSelf="center"
        borderColor={"#ff7e37"}
        p={10}
        shadow={5}
        borderRadius={10}
        borderWidth={1}
      >
        <HStack justifyContent="space-between">
          <Text color="white" fontSize={"lg"}>
            Selecciona moneda de origen:
          </Text>
          <Flex>
            <Select
              selectedValue={monedaOrigen}
              bg="#000"
              color="#ff8b39"
              w={48}
              accessibilityLabel="Selecciona moneda de origen:"
              placeholder="Selecciona moneda de origen:"
              onValueChange={(itemValue) => setMonedaOrigen(itemValue)}
            >
              <Select.Item label="USD" value="USD" />
              <Select.Item label="MXN" value="MXN" />
              <Select.Item label="EUR" value="EUR" />
              <Select.Item label="CAD" value="CAD" />
            </Select>
          </Flex>
        </HStack>

        <HStack justifyContent="space-between">
          <Text color="white" fontSize={"lg"}>
            Ingresa el monto:
          </Text>

          <Input
            placeholder="Ingresa el monto:"
            keyboardType="numeric"
            bg="#000"
            color="#ff8b39"
            focusOutlineColor="#ff8b39"
            value={monto}
            w={48}
            onChangeText={(val) => {
              const regex = /^\d{0,10}(\.\d{0,2})?$/; // Permite hasta dos números antes y dos después del punto
              if (regex.test(val)) {
                setMonto(val); // Solo actualiza si cumple con el formato
              }
            }}
          />
        </HStack>

        <HStack justifyContent="space-between" space={3} alignItems={"center"}>
          <Text color="white" fontSize={"lg"}>
            Selecciona moneda de destino:
          </Text>
          <Select
            selectedValue={monedaDestino}
            w={48}
            bg="#000"
            color="#ff8b39"
            accessibilityLabel="Selecciona moneda de destino:"
            placeholder="Selecciona moneda de destino:"
            onValueChange={(itemValue) => setMonedaDestino(itemValue)}
          >
            <Select.Item label="USD" value="USD" />
            <Select.Item label="MXN" value="MXN" />
            <Select.Item label="EUR" value="EUR" />
            <Select.Item label="CAD" value="CAD" />
          </Select>
        </HStack>

        <Button
          shadow={6}
          textAlign="center"
          onPress={convertirDivisas}
          style={styles.buttonGradientStyle}
        >
          Convertir
        </Button>
      </VStack>

      {resultado && (
        <Text
          my={8}
          fontSize="xl"
          bold
          alignSelf="center"
          textAlign="center"
          borderWidth={1}
          shadow={7}
          borderRadius={10}
          borderColor={"muted.300"}
          p={4}
          color="white"
        >
          Resultado: {"\n"}
          {resultado}
        </Text>
      )}
    </View>
  );
};

export default ConversorDivisas;
