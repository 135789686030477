import React from "react";
import { Center, Heading, Text, View, Button } from "native-base";
import { Link } from "react-router-dom";
import { styles } from "../styles";

const NotFound = () => {
  return (
    <Center flex={1}>
      <View alignItems="center" py={40}>
        <Heading mb="4" textAlign="center" color="#fff">
          404 - Página no encontrada
        </Heading>
        <Text mb="4" textAlign="center" color="#fff">
          Lo sentimos, la página que estás buscando no existe.
        </Text>
        <Link to="/">
          <Button style={styles.buttonGradientStyle}>
            Ir a la página principal.
          </Button>
        </Link>
      </View>
    </Center>
  );
};
export default NotFound;
