import { Button, Center, Heading, VStack, Image } from "native-base";
import React from "react";
import { styles } from "../styles";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Gracias = () => {
  const navigate = useNavigate();
  const handleNavegation = () => {
    scroll.scrollToTop();
    navigate("/");
  };

  return (
    <VStack space={5} py={20} alignItems={"center"}>
      <Center bg={"#000"} p={5} borderRadius={100}>
        <Image
          source={{
            uri: "https://marketeasyrealty.com/fondos/icono3.png",
          }}
          alt="Alternate Text"
          size="xl"
          resizeMode="contain"
          alignSelf={"center"}
        />
      </Center>

      <Heading color="white" textAlign={"center"} w="80%">
        Gracias por contactarnos, tu mensaje fue enviado y responderemos a la
        brevedad
      </Heading>
      <Button
        style={styles.buttonGradientStyle}
        px={5}
        w={80}
        onPress={handleNavegation}
      >
        Volver a la página principal
      </Button>
    </VStack>
  );
};

export default Gracias;
