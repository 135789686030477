import React from "react";
import { Spinner, Center } from "native-base";

const Loader = ({ size, color }) => {
  return (
    <Center flex={1}>
      <Spinner size={size} color={"#f27b34"} />
    </Center>
  );
};

export default Loader;
