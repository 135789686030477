import React from "react";
import { Button, Popover, Icon } from "native-base";
import { CiLogout } from "react-icons/ci";
import { IconContext } from "react-icons";
import { styles } from "../styles";

const LogoutConfirmationPopover = ({ onConfirm, triggerProps }) => {
  return (
    <Popover
      trigger={(triggerProps) => {
        return (
          <IconContext.Provider value={{ color: "white", size: "2em" }}>
            <Button
              p={4}
              flex={1}
              shadow={5}
              my={10}
              mx={4}
              alignSelf="center"
              leftIcon={<Icon as={CiLogout} />}
              {...triggerProps}
              style={styles.buttonGradientStyle}
              _text={{ fontWeight: "bold", fontSize: "lg" }}
            >
              Cerrar sesión
            </Button>
          </IconContext.Provider>
        );
      }}
    >
      <Popover.Content
        accessibilityLabel="Confirmar cierre de sesión"
        w="56"
        borderColor={"#ff8b37"}
      >
        <Popover.Arrow />
        <Popover.CloseButton />
        <Popover.Header bg="#000" _text={{ color: "white" }}>
          Confirmación
        </Popover.Header>
        <Popover.Body bg="#000" _text={{ color: "white" }}>
          ¿Estás seguro de que quieres cerrar sesión?
          <Button mt={4} style={styles.buttonGradientStyle} onPress={onConfirm}>
            Confirmar
          </Button>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

export default LogoutConfirmationPopover;
