import {
  ZStack,
  Image,
  Center,
  Button,
  VStack,
  Box,
  PresenceTransition,
  Pressable,
  Text,
  HStack,
} from "native-base";
import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi"; // Importa el icono
import { styles } from "../../styles";

const RazonesZStack = () => {
  const [activeBtn, setActiveBtn] = useState(0);

  const Imagenes = [
    "https://marketeasyrealty.com/fondos/razones1.jpg",
    "https://marketeasyrealty.com/img/01galeria00.webp",
    "https://marketeasyrealty.com/img/foto2.jpg",
    "https://marketeasyrealty.com/img/img97.jpg",
  ];

  const BotonesTextos = [
    "DESARROLLOS EXCLUSIVOS",
    "OBTÉN MAYORES GANANCIAS",
    "SEGURIDAD",
    "ASESORÍA PERSONALIZADA",
  ];

  const Textos = [
    "Descubre un mundo de propiedades únicas y exclusivas que se adaptan a tu estilo de vida. Encuentra el hogar de tus sueños en nuestros desarrollos de calidad incomparable.",
    "Maximiza tu inversión con nuestras oportunidades inmobiliarias estratégicas. Aumenta tus ganancias y asegura un futuro próspero con nuestras propiedades de alto rendimiento.",
    "Tu tranquilidad es nuestra prioridad. Ofrecemos propiedades con sistemas de seguridad avanzados para garantizar un ambiente seguro y protegido para ti y tu familia.",
    "Cuenta con nuestro equipo de expertos para recibir asesoramiento personalizado. Estamos comprometidos a guiarte en cada paso del proceso inmobiliario, asegurando que tomes decisiones informadas y acertadas.",
  ];

  const toggleButton = (index) => {
    setActiveBtn(index);
  };

  // Renderiza manualmente cada elemento
  const renderButtonAndText = (index) => (
    <VStack key={index} alignItems="flex-start" bg="#000">
      <Pressable
        p={4}
        w={{
          base: 80,
          md: 96,
        }}
        onPress={() => toggleButton(index)}
      >
        <HStack justifyContent="space-between" w="100%" alignItems="center">
          <Text color={"muted.600"} fontSize="xl">
            <Text fontFamily={"Graphik"} color="#fff">
              {BotonesTextos[index]}
            </Text>
          </Text>
          {activeBtn !== index && <FiChevronDown size={32} color="#ff8b39" />}
        </HStack>
      </Pressable>
      <PresenceTransition alignSelf={"center"} visible={activeBtn === index}>
        {activeBtn === index && (
          <Center
            px={5}
            w={{
              base: 80,
              md: 96,
            }}
            flex={1}
            _text={{ color: "#000" }}
            shadow={6}
          >
            <Text
              fontFamily={"Garet"}
              textAlign={"justify"}
              color="#fff"
              lineHeight={20}
              fontSize={"lg"}
            >
              {Textos[index]}
            </Text>
          </Center>
        )}
      </PresenceTransition>
    </VStack>
  );

  return (
    <>
      <HStack alignSelf="center" space={1} my={5}>
        <Text
          fontSize={{ base: "3xl", md: "5xl" }}
          style={styles.colorTitulos2}
          fontFamily={"Times"}
          textAlign={"center"}
        >
          4 razones para invertir en{" "}
          <Text fontWeight={"normal"} fontFamily={"Times"}>
            Market Easy Realty
          </Text>
        </Text>
      </HStack>
      <VStack w="100%">
        <ZStack h={"35rem"} w="100%">
          <Box
            w={{
              base: "100%",
              md: "80%",
            }}
            h={"35rem"}
            alignSelf="flex-end"
          >
            <Image
              source={{
                uri: Imagenes[activeBtn],
              }}
              alt="Alternate Text"
              w="100%"
              h="100%"
            />
          </Box>

          <VStack borderColor={"#ff8b39"} borderWidth={1}>
            {renderButtonAndText(0)}
            {renderButtonAndText(1)}
            {renderButtonAndText(2)}
            {renderButtonAndText(3)}
          </VStack>
        </ZStack>
      </VStack>
    </>
  );
};

export default RazonesZStack;
