import { Button, Stack, Center, Modal, Text, Icon } from "native-base";
import React, { useEffect, useState } from "react";
import { useUser } from "../../helper/UserContext";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { IconContext } from "react-icons";
import { FaFileDownload } from "react-icons/fa";

const BotonesDescargar = ({ brochure, planos }) => {
  const navigate = useNavigate();
  const handleNavegation = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };
  const { userId } = useUser();

  useEffect(() => {
    console.log("id user: ", userId);
  }, []);

  const BotonDescarga = ({ texto, link }) => {
    const enlaceCompleto =
      "https://marketeasyrealty.com/back/public/uploads/" + link;
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
      if (!userId) {
        setShowModal(true);
      } else {
        window.open(enlaceCompleto, "_blank");
      }
    };

    return (
      <Center>
        <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
          <Button
            leftIcon={<Icon as={FaFileDownload} />}
            colorScheme="darkBlue"
            _text={{ fontWeight: "bold", fontSize: "lg" }}
            shadow={6}
            onPress={() => {
              handleClick();
            }}
          >
            {texto}
          </Button>
        </IconContext.Provider>

        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <Modal.Content maxWidth="400px">
            <Modal.CloseButton />
            <Modal.Header>
              Acceso exclusivo: Regístrate o inicia sesión.
            </Modal.Header>
            <Modal.Body>
              <Text fontSize={"lg"}>
                ¡No te pierdas lo mejor! Únete a nuestra comunidad para acceder
                a descargas exclusivas, contenido premium y mucho más.
                Regístrate o inicia sesión para continuar.
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant="ghost"
                  colorScheme="blueGray"
                  onPress={() => {
                    setShowModal(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="darkBlue"
                  onPress={() => {
                    handleNavegation("/signup");
                  }}
                >
                  Registrarse
                </Button>
                <Button
                  onPress={() => {
                    handleNavegation("/login");
                  }}
                >
                  Iniciar Sesión
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </Center>
    );
  };

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      justifyContent={"space-between"}
      space={6}
      alignSelf={"center"}
      mx={6}
    >
      <BotonDescarga texto="Descargar Brochure" link={brochure} />
      <BotonDescarga texto="Descargar Planos" link={planos} />
    </Stack>
  );
};
export default BotonesDescargar;
