import React, { useState, useEffect } from "react";
import { Text, View, Stack, Heading, Box, Button, Icon } from "native-base";
import { useUser } from "../../../helper/UserContext";
import { useNavigate } from "react-router";
import { PiUserList } from "react-icons/pi";
import LogoutConfirmationPopover from "../../../components/LogoutConfirmationPopover";
import OpcionUser from "../../../components/global/OpcionUser";
import { animateScroll as scroll } from "react-scroll";
import { TbHomeHeart } from "react-icons/tb";
import { FaHouseUser, FaRegCreditCard } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";
import { BiSolidCommentError } from "react-icons/bi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { styles } from "../../../styles";
import checkStatus from "../../../helper/checkStatus";

const BrokerPanel = () => {
  const { logout, nombre, apellido, correo, userId, statusSuscripcion } =
    useUser();

  const [idUser, setIdUser] = useState(userId);
  const [status, setStatus] = useState(statusSuscripcion);

  const getId = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        console.log("async id: ", value);
        setIdUser(value);
        return value;
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };
  const getStatus = async () => {
    try {
      const value = await AsyncStorage.getItem("status_suscripcion");
      if (value !== null) {
        console.log("async status: ", value);
        console.log("async status tipo: ", typeof value);
        setStatus(value);
        return value;
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };

  useEffect(() => {
    // getId();
    // getStatus();
    console.log("id useuser: ", userId);
    console.log("status useuser: ", statusSuscripcion);
  }, []);

  const navigate = useNavigate();

  const handleSalir = () => {
    // Lógica para el inicio de sesión
    logout();
    scroll.scrollToTop();
    navigate("/");
  };

  const UserNav = (nav) => {
    navigate(nav);
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
  };
  const handleCheckStatus = async () => {
    const id = idUser || (await getId()); // Asegúrate de obtener el ID antes de continuar
    if (id) {
      const result = await checkStatus(id); // Llama a la función con el ID correcto
      setStatus(result); // Actualiza el estado con el valor devuelto
      await AsyncStorage.setItem("status_suscripcion", result);
    } else {
      console.error("No se pudo obtener el ID del usuario");
    }
  };

  return (
    <View mt={[12, 12, 20, 24]}>
      <Heading bold fontSize={"2xl"} textAlign="center" mt={3} color="white">
        BIENVENIDO
      </Heading>
      <Stack my={3} ml="20%">
        <Text bold fontSize={"2xl"} textAlign="justify" px={10} color="white">
          Cuenta
        </Text>

        <Text bold fontSize={"lg"} px={10} color="white">
          {nombre} {apellido}.
        </Text>
        <Text
          fontWeight={"semibold"}
          fontSize={"md"}
          px={10}
          mb={3}
          color="white"
        >
          {correo}
        </Text>

        {status === "active" ? (
          <>
            <Button
              mx={10}
              style={styles.buttonGradientStyle}
              w={64}
              onPress={() => handleCheckStatus()}
              borderRadius={20}
              rightIcon={<Icon as={<FaUserCheck color="#fff" size={30} />} />}
              _text={{
                fontFamily: "Graphik",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Suscripción activa
            </Button>
          </>
        ) : (
          <>
            <Button
              mx={10}
              style={styles.buttonGradientStyle}
              w={64}
              onPress={() => handleCheckStatus()}
              borderRadius={20}
              rightIcon={
                <Icon as={<BiSolidCommentError color="#fff" size={30} />} />
              }
              _text={{
                fontFamily: "Graphik",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Suscripción pendiente
            </Button>
          </>
        )}
      </Stack>

      <Box w="80%" alignSelf="center">
        <div style={stackContainerStyle}>
          <OpcionUser
            titulo="Información personal"
            texto="Visualiza y edita tus datos personales"
            funcion={() => UserNav("/UserInfo")}
            icon={PiUserList}
            idUser={idUser}
          />
          <OpcionUser
            titulo="Favoritos"
            texto="Ver inmuebes favoritos"
            funcion={() => UserNav("/Favoritos")}
            icon={TbHomeHeart}
          />
          <OpcionUser
            titulo="Prospectos (Leads)"
            texto="Visualiza tus prospectos"
            funcion={() => UserNav("/SolicitudesBroker")}
            icon={FaHouseUser}
          />
          <OpcionUser
            titulo="Pagar Suscripción"
            texto="Paga y administra tu suscripción"
            funcion={() => UserNav("/Suscripcion")}
            icon={FaRegCreditCard}
          />
        </div>
      </Box>

      <LogoutConfirmationPopover onConfirm={handleSalir} />
    </View>
  );
};
export default BrokerPanel;
