import React, { useState } from "react";
import {
  Button,
  Icon,
  Checkbox,
  CheckIcon,
  View,
  Input,
  Text,
  Image,
  FormControl,
  Select,
  useToast,
  Box,
  Center,
  HStack,
  VStack,
  Stack,
  TextArea,
  Flex,
} from "native-base";
import Loader from "../Loader";
import fetchPost from "../../helper/fetchPost";
import URL from "../../helper/baseURL";
import MapComponent from "./MapComponent";
import { MdAddPhotoAlternate, MdSaveAs } from "react-icons/md";
import { useUser } from "../../helper/UserContext";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { styles } from "../../styles";

const CrudComponent = () => {
  const toast = useToast();

  const navigate = useNavigate();
  const handleNav = (vista) => {
    scroll.scrollToTop();
    navigate("/UserPanel");
  };

  const handleMapLinkChange = (newLink) => {
    setGoogleMapLink(newLink);
  };

  const handleLatitudChange = (lat) => {
    setLatitud(lat);
  };
  const handleLongitudChange = (long) => {
    setLongitud(long);
  };

  // Aquí iría la lógica para manejar los estados y la subida de imágenes
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useUser();

  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [area, setArea] = useState("");
  const [precio, setPrecio] = useState("");
  const [categoria, setCategoria] = useState("");
  const [habitaciones, setHabitaciones] = useState(0);
  const [wc, setWc] = useState(0);
  const [unidades, setUnidades] = useState("");
  const [rentabilidad, setRentabilidad] = useState("");
  const [comision, setComision] = useState("");
  const [tiempoEntrega, setTiempoEntrega] = useState("");
  const [antiguedad, setAntiguedad] = useState("");
  const [estado, setEstado] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [ubicacion, setUbicacion] = useState("");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");
  const [amenidades, setAmenidades] = useState([]);
  const [imagen, setImagen] = useState(null);
  const [planos, setPlanos] = useState(null);
  const [brochure, setBrochure] = useState(null);
  //legal
  const [selectedFile, setSelectedFile] = useState(null);
  const [foto1, setFoto1] = useState(null);
  const [foto2, setFoto2] = useState(null);
  const [foto3, setFoto3] = useState(null);
  const [foto4, setFoto4] = useState(null);

  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewUrl1, setPreviewUrl1] = useState(null);
  const [previewUrl2, setPreviewUrl2] = useState(null);
  const [previewUrl3, setPreviewUrl3] = useState(null);
  const [previewUrl4, setPreviewUrl4] = useState(null);

  const handleFoto1Change = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFoto1(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl1(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFoto2Change = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFoto2(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl2(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFoto3Change = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFoto3(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl3(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFoto4Change = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFoto4(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl4(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handlePortadaChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImagen(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  // FALTA DESCOMENTAR EL ELSE
  const AgregarInmueble = async () => {
    if (!validarCampos()) {
      return;
    }

    setIsLoading(true);

    const data = new FormData();
    data.append("id_user", userId);
    data.append("status", 1);
    data.append("nombre", titulo);
    data.append("descripcion", descripcion);
    data.append("precio", precio);
    data.append("categoria", categoria);
    data.append("habitaciones", habitaciones);
    data.append("wc", wc);
    data.append("area", area);
    data.append("unidades", unidades);
    data.append("rentabilidad", rentabilidad);
    data.append("comision", comision);
    data.append("tiempoEntrega", tiempoEntrega);
    data.append("antiguedad", antiguedad);
    data.append("pais", pais);
    data.append("estado", estado);
    data.append("municipio", municipio);
    data.append("ubicacion", ubicacion);
    data.append("mapa", googleMapLink);
    data.append("latitud", latitud);
    data.append("longitud", longitud);
    data.append("amenidades", amenidades);
    data.append("brochure", brochure);
    data.append("fotoportada", imagen);
    data.append("planos", planos);
    data.append("legal", selectedFile);
    data.append("foto1", foto1);
    data.append("foto2", foto2);
    data.append("foto3", foto3);
    data.append("foto4", foto4);

    // ... Agregar otras imágenes y campos si es necesario

    const url = `${URL.BASE_URL}/crearInmueble`; // Asegúrate de que la URL sea correcta
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta", res);
    if (res.resultado) {
      toast.show({
        duration: 10000,
        render: () => {
          return (
            <Box bg="emerald.500" px={12} py={12} rounded="sm" mb={5}>
              <Text fontSize={"2xl"} color={"#fff"} textAlign={"center"} bold>
                Se agregó el inmueble con éxito.
              </Text>
            </Box>
          );
        },
      });

      handleNav();
    } else {
      toast.show({
        duration: 10000,
        render: () => {
          return (
            <Box
              bg="danger.500"
              px="3"
              py="5"
              rounded="sm"
              mb={5}
              fontSize={"lg"}
            >
              <Text fontSize={"xl"} color={"#fff"} textAlign={"center"} bold>
                Hubo un error, intentalo más tarde.
              </Text>
            </Box>
          );
        },
      });
    }
    setIsLoading(false);
  };

  const validarCampos = () => {
    let camposFaltantes = [];

    if (!titulo) camposFaltantes.push("título");
    if (!descripcion) camposFaltantes.push("descripción");
    if (!area) camposFaltantes.push("área");
    if (!precio) camposFaltantes.push("precio");
    if (!categoria) camposFaltantes.push("categoría");
    if (habitaciones === 0) camposFaltantes.push("habitaciones");
    if (wc === 0) camposFaltantes.push("baños");
    if (!unidades) camposFaltantes.push("unidades");
    if (!rentabilidad) camposFaltantes.push("rentabilidad");
    if (!comision) camposFaltantes.push("comisión");
    if (!tiempoEntrega) camposFaltantes.push("tiempo de entrega");
    if (!antiguedad) camposFaltantes.push("antigüedad");
    if (!estado) camposFaltantes.push("estado");
    if (!municipio) camposFaltantes.push("municipio");
    if (!ubicacion) camposFaltantes.push("ubicación");
    if (!latitud) camposFaltantes.push("latitud");
    if (!longitud) camposFaltantes.push("longitud");
    if (!googleMapLink) camposFaltantes.push("enlace de Google Maps");
    if (amenidades.length === 0) camposFaltantes.push("amenidades");
    if (!imagen) camposFaltantes.push("imagen");
    if (!planos) camposFaltantes.push("planos");
    if (!brochure) camposFaltantes.push("brochure");
    if (!selectedFile) camposFaltantes.push("archivo legal");
    if (!foto1) camposFaltantes.push("foto 1");
    if (!foto2) camposFaltantes.push("foto 2");
    if (!foto3) camposFaltantes.push("foto 3");
    if (!foto4) camposFaltantes.push("foto 4");

    if (camposFaltantes.length > 0) {
      const mitad = Math.ceil(camposFaltantes.length / 2);
      const primeraColumna = camposFaltantes.slice(0, mitad);
      const segundaColumna = camposFaltantes.slice(mitad);

      toast.show({
        title: "Campos Faltantes",
        description: (
          <HStack space={4}>
            <VStack space={1}>
              {primeraColumna.map((campo, index) => (
                <Text key={index} color="white" fontSize={"md"}>
                  • {campo}
                </Text>
              ))}
            </VStack>
            <VStack space={1}>
              {segundaColumna.map((campo, index) => (
                <Text key={index} color="white" fontSize={"md"}>
                  • {campo}
                </Text>
              ))}
            </VStack>
          </HStack>
        ),
        status: "danger",
        paddingX: 24,
        marginX: 20,
        colorScheme: "orange",
        duration: 8000,
        isClosable: true,
      });
      return false;
    }

    toast.show({
      title: "Éxito",
      description: "Todos los campos están completos.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    return true;
  };

  // manejo de archivos
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const formatBytes = (bytes) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  };

  const handlePlanosChange = (e) => {
    const file = e.target.files[0];
    setPlanos(file);
  };
  const handleBrochureChange = (e) => {
    const file = e.target.files[0];
    setBrochure(file);
  };

  //fin manejo archivos

  //handle de habitaciones

  const handleIncrease = () => {
    setHabitaciones((prev) => parseInt(prev) + 1);
  };

  const handleDecrease = () => {
    setHabitaciones((prev) => (parseInt(prev) > 0 ? parseInt(prev) - 1 : 0));
  };

  //handle baños
  const handleIncreaseWC = () => {
    setWc((prev) => parseInt(prev) + 1);
  };

  const handleDecreaseWC = () => {
    setWc((prev) => (parseInt(prev) > 0 ? parseInt(prev) - 1 : 0));
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
  };

  const [pais, setPais] = useState("");
  const handlePais = async (idPais) => {
    setPais(idPais);

    const dataEstados = new FormData();
    dataEstados.append("country_id", idPais);

    const url = `${URL.BASE_URL}/getEstadosPorPais`;
    const options = {
      method: "POST",
      body: dataEstados,
    };
    const res = await fetchPost(url, options);
    console.log("res", res.data);

    //Falta manejar el resultado
    if (res.data) {
      setEstados(res.data);
    }
  };

  const handleEstado = async (idEstado) => {
    setEstado(idEstado);

    const dataEstados = new FormData();
    dataEstados.append("state_id", idEstado);

    const url = `${URL.BASE_URL}/getCiudadesPorEstado`;
    const options = {
      method: "POST",
      body: dataEstados,
    };
    const res = await fetchPost(url, options);

    //Falta manejar el resultado
    if (res.data) {
      console.log("res municipios", res.data);

      setMunicipios(res.data);
    }
  };

  const PaisSelect = () => {
    return (
      <Stack
        direction={{ base: "column", md: "row" }}
        alignSelf={"center"}
        w="100%"
        space={5}
      >
        <FormControl w={64} isRequired>
          <FormControl.Label>
            <Text color="white" fontSize={"lg"}>
              Selecciona el país
            </Text>
          </FormControl.Label>
          <Flex bg="white" borderRadius={5}>
            <Select
              bg="#000"
              color="#ff8b39"
              minWidth="200"
              accessibilityLabel="País"
              placeholder="País"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              selectedValue={pais}
              onValueChange={(itemValue) => handlePais(itemValue)}
            >
              <Select.Item label="México" value="141" />
              <Select.Item label="Estados Unidos" value="236" />
              <Select.Item label="Francia" value="76" />
              <Select.Item label="España" value="209" />
            </Select>
          </Flex>
        </FormControl>

        <FormControl
          flex={1}
          display={estados.length === 0 ? "none" : "flex"}
          isRequired={true}
          w={48}
        >
          <FormControl.Label>
            <Text color="white" fontSize={"lg"}>
              Selecciona el Estado
            </Text>
          </FormControl.Label>
          <Box w={48} borderRadius={5}>
            <Select
              w={48}
              bg="#000"
              color="#ff8b39"
              accessibilityLabel="Estado"
              placeholder="Estado"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              selectedValue={estado}
              onValueChange={(itemValue) => handleEstado(itemValue)}
            >
              {estados === null
                ? null
                : estados.map((estado) => (
                    <Select.Item
                      key={estado.state_id}
                      label={estado.state_name}
                      value={estado.state_id}
                    />
                  ))}
            </Select>
          </Box>
        </FormControl>

        <FormControl
          w={64}
          flex={1}
          isRequired
          display={municipios === null ? "none" : "flex"}
        >
          <FormControl.Label w={64}>
            <Text color="white" fontSize={"lg"}>
              Selecciona la localidad
            </Text>
          </FormControl.Label>
          <Flex borderRadius={5}>
            <Select
              bg="#000"
              color="#ff8b39"
              w={56}
              accessibilityLabel="Localidad"
              placeholder="Localidad"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              selectedValue={municipio}
              onValueChange={(itemValue) => setMunicipio(itemValue)}
            >
              {municipios === null
                ? null
                : municipios.map((municipio) => (
                    <Select.Item
                      key={municipio.city_id}
                      label={municipio.city_name}
                      value={municipio.city_id}
                    />
                  ))}
            </Select>
          </Flex>
        </FormControl>
      </Stack>
    );
  };

  return (
    <View w="100%" mx="auto" mb={5}>
      <Center alignSelf="center">
        {/* SELECCIONAR PAIS */}
        <Box
          alignSelf={"center"}
          alingItems="center"
          mt={10}
          justifyContent={"center"}
          justifyItems={"center"}
        >
          <PaisSelect />
        </Box>

        <Stack direction={{ base: "column", md: "row" }}>
          {/* TITULO */}
          <FormControl isRequired flex={1} mx={3} w={96}>
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Titulo
              </Text>
            </FormControl.Label>
            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Titulo del inmueble"
              onChangeText={(val) => setTitulo(val)}
              value={titulo}
            />
          </FormControl>
          {/* Área de inmueble */}
          <FormControl isRequired w={56} mx={3} alignSelf={"center"}>
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Área del inmueble (m2)
              </Text>
            </FormControl.Label>
            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              minW={48}
              placeholder="Área en metros cuadrados"
              keyboardType="numeric" // Establece el teclado solo para números
              onChangeText={(val) => {
                if (/^\d*$/.test(val)) {
                  // Verifica si el valor es un número
                  setArea(val);
                }
              }}
              value={area}
            />
          </FormControl>
        </Stack>

        <div style={stackContainerStyle}>
          {/* habitaciones */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Habitaciones:{" "}
              </Text>
            </FormControl.Label>
            <HStack space={2}>
              <Input
                bg="#000"
                color="#ff8b39"
                focusOutlineColor="#ff8b39"
                placeholder="Número de habitaciones"
                w={24}
                onChangeText={(val) => setHabitaciones(val)}
                value={habitaciones.toString()} // Asegúrate de que el valor sea un string para el input
                isReadOnly={true}
              />
              <VStack alignSelf={"flex-end"}>
                <button onClick={handleIncrease}>+</button>
                <button onClick={handleDecrease}>-</button>
              </VStack>
            </HStack>
          </FormControl>

          {/* UNIDADES DISPONIBLES */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Unidades disponibles:{" "}
              </Text>
            </FormControl.Label>

            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Unidades disponibles"
              flex={1}
              onChangeText={(val) => {
                if (/^\d*$/.test(val)) {
                  // Verifica si el valor es un número
                  setUnidades(val);
                }
              }}
              value={unidades}
            />
          </FormControl>

          {/* RENTBILIDAD */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                {" "}
                Rentabilidad:{" "}
              </Text>
            </FormControl.Label>

            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Rentabilidad"
              flex={1}
              keyboardType="numeric" // Muestra el teclado numérico
              onChangeText={(val) => {
                const regex = /^\d{0,2}(\.\d{0,2})?$/; // Permite hasta dos números antes y dos después del punto
                if (regex.test(val)) {
                  setRentabilidad(val); // Solo actualiza si cumple con el formato
                }
              }}
              value={rentabilidad}
            />
          </FormControl>

          {/* COMISION */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                {" "}
                % Comisión:
              </Text>
            </FormControl.Label>
            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Número de baños"
              w={24}
              onChangeText={(val) => {
                const regex = /^\d{0,2}(\.\d{0,2})?$/; // Permite hasta dos números antes y dos después del punto
                if (regex.test(val)) {
                  setComision(val); // Solo actualiza si cumple con el formato
                }
              }}
              value={comision} // Convertir el valor a string para el input
            />
          </FormControl>

          {/* BAÑOS */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Baños:
              </Text>
            </FormControl.Label>
            <HStack space={2}>
              <Input
                bg="#000"
                color="#ff8b39"
                focusOutlineColor="#ff8b39"
                placeholder="Número de baños"
                w={24}
                onChangeText={(val) => setWc(val)}
                value={wc}
                isReadOnly={true}
              />
              <VStack alignSelf={"flex-end"}>
                <button onClick={handleIncreaseWC}>+</button>
                <button onClick={handleDecreaseWC}>-</button>
              </VStack>
            </HStack>
          </FormControl>
        </div>
      </Center>

      {/* Mapa */}
      <Stack
        direction={{ base: "column", md: "row" }}
        w="100%"
        space={4}
        alignSelf="center"
        alignItems={"center"}
        justifyContent={"center"}
      >
        {/* mapa */}
        <VStack>
          {/* Mapa */}
          <FormControl
            isRequired
            w={"450px"}
            mx={5}
            borderWidth={1}
            borderColor="muted.300"
            shadow={4}
            borderRadius={10}
            alignSelf={"center"}
          >
            <Text
              alignSelf="center"
              fontSize="xl"
              color="white"
              my={4}
              fontWeight="bold"
            >
              Selecciona la ubicación en el mapa:
            </Text>
            <div style={{ alignSelf: "center" }}>
              <MapComponent
                onMapLinkChange={handleMapLinkChange}
                onLatitudChange={handleLatitudChange}
                onLongitudChange={handleLongitudChange}
              />
              <Center px={2} maxW={96}>
                <Text color="white" fontSize={"sm"} maxW={96}>
                  Enlace de Google Maps: {googleMapLink}
                </Text>
              </Center>
            </div>
          </FormControl>
          <VStack
            px={5}
            mx={5}
            justifyContent="center"
            alignItems="center"
            alignSelf={"center"}
            my={5}
            h={40}
            w={96}
            bg="#000"
            borderWidth={1}
            borderRadius={10}
            shadow={4}
            borderColor="muted.300"
          >
            <Text fontSize="md" bold color="white">
              Sube el brochure de la propiedad:
            </Text>
            <input type="file" accept=".pdf" onChange={handleBrochureChange} />
            {brochure && (
              <div>
                <Text color="white" fontSize={"lg"}>
                  Nombre del archivo: {brochure.name}
                </Text>
                <Text color="white" fontSize={"lg"}>
                  Tamaño del archivo: {formatBytes(brochure.size)} MB
                </Text>
              </div>
            )}
          </VStack>
        </VStack>
        {/* Descripcion a documentos */}
        <VStack w={96} m={4}>
          {/* Descripcion */}
          <FormControl isRequired>
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Descripción:{" "}
              </Text>
            </FormControl.Label>
            <TextArea
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              h={40}
              placeholder="Descripción del inmueble"
              onChangeText={(val) => setDescripcion(val)}
              value={descripcion}
            />
          </FormControl>
          {/* ubicacion */}
          <FormControl isRequired>
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Ubicación:{" "}
              </Text>
            </FormControl.Label>
            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Ubicación del inmueble"
              onChangeText={(val) => setUbicacion(val)}
              value={ubicacion}
            />
          </FormControl>
          {/* Tiempo de entrega */}
          <FormControl isRequired flex={1} alignSelf="center">
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Tiempo de entrega:
              </Text>
            </FormControl.Label>
            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Tiempo de entrega"
              onChangeText={(val) => setTiempoEntrega(val)}
              value={tiempoEntrega}
            />
          </FormControl>
          {/* Antiguedad */}
          <FormControl isRequired flex={1}>
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Antigüedad:{" "}
              </Text>
            </FormControl.Label>
            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Antigüedad"
              onChangeText={(val) => setAntiguedad(val)}
              value={antiguedad}
            />
          </FormControl>
          {/* Precio */}
          <FormControl isRequired flex={1}>
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                {" "}
                Precio:{" "}
              </Text>
            </FormControl.Label>
            <Input
              bg="#000"
              color="#ff8b39"
              focusOutlineColor="#ff8b39"
              placeholder="Precio del inmueble"
              keyboardType="numeric"
              onChangeText={(val) => {
                // Permitir solo números con hasta dos decimales
                if (/^\d*\.?\d{0,2}$/.test(val)) {
                  setPrecio(val);
                }
              }}
              value={precio}
            />
          </FormControl>
          {/* Categoria */}
          <FormControl isRequired flex={1}>
            <FormControl.Label>
              <Text color="white" fontSize={"lg"}>
                Categoría:{" "}
              </Text>
            </FormControl.Label>

            <Flex borderRadius={5}>
              <Select
                bg="#000"
                color="#ff8b39"
                placeholder="Selecciona una categoría"
                onValueChange={(itemValue) => setCategoria(itemValue)}
              >
                <Select.Item label="Frente a la playa" value="1" />
                <Select.Item label="Habitaciones" value="2" />
                <Select.Item label="Casas particulares" value="3" />
                <Select.Item label="Albercas increibles" value="4" />
                <Select.Item label="Adaptados" value="5" />
              </Select>
            </Flex>
          </FormControl>

          {/* Planos */}
          <VStack
            px={5}
            justifyContent="center"
            alignItems="center"
            my={5}
            bg="#000"
            h={40}
            borderWidth={1}
            borderRadius={10}
            shadow={4}
            borderColor="muted.300"
          >
            <Text fontSize="md" bold color={"white"}>
              Sube los planos de la propiedad:
            </Text>
            <input type="file" accept=".pdf" onChange={handlePlanosChange} />
            {planos && (
              <div>
                <Text color="white" fontSize={"lg"}>
                  Nombre del archivo: {planos.name}
                </Text>
                <Text color="white" fontSize={"lg"}>
                  Tamaño del archivo: {formatBytes(planos.size)} MB
                </Text>
              </div>
            )}
          </VStack>

          {/* DOCUMENTOS LEGALES */}
          <VStack
            h={40}
            px={5}
            justifyContent="center"
            alignItems="center"
            my={5}
            py={3}
            bg="#000"
            borderWidth={1}
            borderRadius={10}
            shadow={4}
            borderColor="muted.300"
          >
            <Text fontSize="lg" bold color="white">
              Sube documento legal de la propiedad:
            </Text>
            <input type="file" accept=".pdf" onChange={handleFileChange} />
            {selectedFile && (
              <div>
                <Text color="white" fontSize={"lg"}>
                  Nombre del archivo: {selectedFile.name}
                </Text>
                <Text color="white" fontSize={"lg"}>
                  Tamaño del archivo: {formatBytes(selectedFile.size)} MB
                </Text>
              </div>
            )}
          </VStack>
        </VStack>
      </Stack>

      {/* IMAGENES */}
      <Stack
        direction={{ base: "column", lg: "row" }}
        alignSelf={"center"}
        flex={1}
      >
        {/* AMENIDADES */}
        <FormControl
          isRequired
          p={2}
          flex={1}
          m={2}
          w={96}
          mt={4}
          borderWidth={1}
          borderRadius={10}
          borderColor="muted.300"
          shadow={3}
        >
          <FormControl.Label>
            <Text color="white" fontSize={"lg"}>
              {" "}
              Amenidades:{" "}
            </Text>
          </FormControl.Label>

          <Checkbox.Group
            onChange={setAmenidades}
            value={amenidades}
            accessibilityLabel="Elige amenidades"
          >
            <Checkbox value="Piscina" my={2}>
              <Text color="white" fontSize={"lg"}>
                Piscina
              </Text>
            </Checkbox>
            <Checkbox value="Estacionamiento">
              <Text color="white" fontSize={"lg"}>
                Estacionamiento
              </Text>
            </Checkbox>
            <Checkbox value="Vigilancia">
              <Text color="white" fontSize={"lg"}>
                Vigilancia
              </Text>
            </Checkbox>
            <Checkbox value="Concierge">
              <Text color="white" fontSize={"lg"}>
                Concierge
              </Text>
            </Checkbox>
            <Checkbox value="Seguridad 24/7">
              <Text color="white" fontSize={"lg"}>
                Seguridad 24/7
              </Text>
            </Checkbox>
            <Checkbox value="Gimnasio">
              <Text color="white" fontSize={"lg"}>
                Gimnasio
              </Text>
            </Checkbox>
            <Checkbox value="Spa">
              <Text color="white" fontSize={"lg"}>
                Spa
              </Text>
            </Checkbox>
            <Checkbox value="Sala de juegos">
              <Text color="white" fontSize={"lg"}>
                Sala de juegos
              </Text>
            </Checkbox>
            <Checkbox value="Rooftop">
              <Text color="white" fontSize={"lg"}>
                Rooftop
              </Text>
            </Checkbox>
            <Checkbox value="Zona de yoga">
              <Text color="white" fontSize={"lg"}>
                Zona de yoga
              </Text>
            </Checkbox>
            <Checkbox value="Coworking">
              <Text color="white" fontSize={"lg"}>
                Coworking
              </Text>
            </Checkbox>
            <Checkbox value="Cine">
              <Text color="white" fontSize={"lg"}>
                Cine
              </Text>
            </Checkbox>
          </Checkbox.Group>
        </FormControl>
        {/* FOTO DE PORTADA */}
        <FormControl
          w={80}
          mr={12}
          maxH={96}
          minH={28}
          my={5}
          borderWidth={1}
          borderColor={"muted.300"}
          borderRadius={10}
          shadow={4}
          alignSelf={"center"}
        >
          <Text bold fontSize="lg" color="white" my={3} alignSelf="center">
            Foto Portada:
          </Text>
          <input
            type="file"
            onChange={handlePortadaChange}
            style={{ display: "none" }}
            id="file-input-portada"
            accept="image/*" // Asegúrate de aceptar solo imágenes
          />
          {previewUrl && (
            <Image
              borderRadius={10}
              alignSelf="center"
              mb={1}
              source={{ uri: previewUrl }}
              alt="Previsualización de la imagen"
              size="2xl" // Puedes ajustar el tamaño según tus necesidades
            />
          )}
          <label
            htmlFor="file-input-portada"
            style={{ alignSelf: "center", marginTop: "11px" }}
          >
            <Button
              w={64}
              h={12}
              mb={4}
              style={styles.buttonGradientStyle}
              as="span"
              leftIcon={
                <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
              }
            >
              Seleccionar Foto de Portada
            </Button>
          </label>
        </FormControl>
      </Stack>

      <Stack
        flex={1}
        alignSelf={"center"}
        direction={{ base: "column", lg: "row" }}
        justifyContent="center"
      >
        {/* IMAGENES */}
        <FormControl
          flex={1}
          p={4}
          mx={4}
          my={5}
          borderWidth={1}
          borderColor={"muted.300"}
          borderRadius={10}
          shadow={4}
          alignSelf="center"
        >
          <Text bold fontSize="lg" color="white" my={2} alignSelf="center">
            Fotos de galería:
          </Text>

          <Stack
            w="90%"
            direction={{ base: "column", md: "row" }}
            justifyContent="center"
            alingItems="center"
            space={10}
            alignSelf={"center"}
          >
            <VStack>
              <Text bold fontSize="lg" color="white" my={3} alignSelf="center">
                Foto 1:
              </Text>
              <input
                type="file"
                onChange={handleFoto1Change}
                style={{ display: "none" }}
                id="file-input-foto1"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl1 && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={3}
                  source={{ uri: previewUrl1 }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-foto1"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  flex={1}
                  style={styles.buttonGradientStyle}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto 1
                </Button>
              </label>
            </VStack>

            <VStack>
              <Text bold fontSize="lg" color="white" my={3} alignSelf="center">
                Foto 2:
              </Text>
              <input
                type="file"
                onChange={handleFoto2Change}
                style={{ display: "none" }}
                id="file-input-foto2"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl2 && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={3}
                  source={{ uri: previewUrl2 }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-foto2"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  flex={1}
                  style={styles.buttonGradientStyle}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto 2
                </Button>
              </label>
            </VStack>
          </Stack>

          <Stack
            w="90%"
            direction={{ base: "column", md: "row" }}
            justifyContent="center"
            alingItems="center"
            space={10}
            alignSelf={"center"}
            pb={5}
          >
            <VStack>
              <Text bold fontSize="lg" color="white" my={3} alignSelf="center">
                Foto 3:
              </Text>
              <input
                type="file"
                onChange={handleFoto3Change}
                style={{ display: "none" }}
                id="file-input-foto3"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl3 && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={3}
                  source={{ uri: previewUrl3 }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-foto3"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  flex={1}
                  style={styles.buttonGradientStyle}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto 3
                </Button>
              </label>
            </VStack>

            <VStack>
              <Text bold fontSize="lg" color="white" my={3} alignSelf="center">
                Foto 4:
              </Text>
              <input
                type="file"
                onChange={handleFoto4Change}
                style={{ display: "none" }}
                id="file-input-foto4"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl4 && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={3}
                  source={{ uri: previewUrl4 }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-foto4"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  flex={1}
                  style={styles.buttonGradientStyle}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto 4
                </Button>
              </label>
            </VStack>
          </Stack>
        </FormControl>
      </Stack>

      {isLoading ? (
        <Box flex={1} my={12} alignSelf="center">
          <Loader size="xl" color="#04324c" />
        </Box>
      ) : (
        <Button
          mt={5}
          style={styles.buttonGradientStyle}
          alignSelf="center"
          flex={1}
          p={4}
          _text={{ fontWeight: "bold", fontSize: "xl" }}
          leftIcon={<Icon as={<MdSaveAs color="#fff" size={24} />} />}
          onPress={() => AgregarInmueble()}
        >
          Guardar Inmueble
        </Button>
      )}
    </View>
  );
};

export default CrudComponent;
