import { FlatList, Flex, Heading, Spinner } from "native-base";
import { useEffect, useState } from "react";
import InmuebleComponent from "./InmuebleComponent";
import fetchPost from "../helper/fetchPost";
import URL from "../helper/baseURL";

const FlatListBusqueda = ({ searchQuery }) => {
  // Datos estáticos para cargar en el FlatList

  const [viajes, setViajes] = useState(null);

  // Código para determinar el número de columnas basado en el tamaño de la pantalla
  const breakpoints = {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
  };

  const numColumns =
    window.innerWidth < breakpoints.sm
      ? 1
      : window.innerWidth < breakpoints.md
      ? 2
      : window.innerWidth < breakpoints.lg
      ? 2
      : window.innerWidth < breakpoints.xl
      ? 3
      : 3; // Puedes ajustar los valores según tus necesidades

  const [inmuebles, setInmuebles] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [Resultados, setResultados] = useState(0);

  const getInmuebles = async () => {
    const data = new FormData();

    data.append("searchQuery", searchQuery);
    const url = `${URL.BASE_URL}verInmueblesBusqueda`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.status) {
      setInmuebles(res.Inmuebles);
      setIsLoading(false);
      setResultados(res.numeroResultados);
    } else if (res.status === false) {
      setIsLoading(false);
      setResultados(res.numeroResultados);
    }
  };

  useEffect(() => {
    getInmuebles();
  }, []);

  return (
    <>
      {isLoading ? (
        <Flex my={20}>
          <Spinner size="lg" my={20} py={20} color={"orange.500"} />
        </Flex>
      ) : (
        <Flex w={"100%"} alignSelf="center" pb={8}>
          <Heading
            color={"white"}
            style={{ fontFamily: "Graphik" }}
            alignSelf={"center"}
          >
            Se han encontrado {Resultados} resultados
          </Heading>
          <FlatList
            // style={{ width: '100%', marginTop: 5, paddingHorizontal: '2vw' }}
            contentContainerStyle={{
              justifyContent: "center",
              alignItems: "center",
            }}
            data={inmuebles}
            numColumns={numColumns}
            keyExtractor={(item) => item.ID}
            key={numColumns.toString()} // Usa el número de columnas como clave
            renderItem={({ item }) => (
              <InmuebleComponent
                key={item.ID}
                imageUri={item.fotoportada}
                titulo={item.nombre}
                estado={item.estado}
                municipio={item.municipio}
                precio={item.precio}
                id={item.ID}
                rooms={item.habitaciones}
                bath={item.wc}
                area={item.area}
              />
            )}
          />
        </Flex>
      )}
    </>
  );
};

export default FlatListBusqueda;
