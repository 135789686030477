import { Box, Button, Flex, Heading, Image, VStack } from "native-base";
import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useUser } from "../../../helper/UserContext";
import fetchPost from "../../../helper/fetchPost";
import URL from "../../../helper/baseURL";

import { useNavigate } from "react-router";

const styles = {
  section: {
    background: "#000",
    display: "flex",
    flexDirection: "column",
    width: "400px",
    height: "142px",
    borderRadius: "6px",
    justifyContent: "space-between",
    alignSelf: "center",
  },
  product: {
    display: "flex",
    flex: 1,
  },
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    height: "54px",
    background: "linear-gradient(45deg, #b26835, #dba06b)",
    color: "white",
    width: "100%",
    fontSize: "18px",
    border: "0",
    fontWeight: "600",
    cursor: "pointer",
    borderRadius: "0 0 6px 6px",
    transition: "all 0.2s ease",
    boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
  },
  texto: {
    color: "#fff",
    fontSize: "18px",
    textAlign: "center",
  },
  buttonGradientStyle: {
    background: "linear-gradient(45deg, #b26835, #dba06b)", // Degradado de fondo
    color: "#ffffff", // Color del texto
    borderRadius: "50px",
    border: "none", // Elimina cualquier borde predeterminado
    fontWeight: "bold",
    fontFamily: "Graphik",
    fontSize: "xl",
    padding: "12px 24px", // Ajusta el padding si es necesario
    cursor: "pointer", // Cambia el cursor para indicar que es clickeable
    textAlign: "center",
  },
};

const ProductDisplay = ({ tipo, userId, status }) => {
  // Define las variables para el texto y el valor del lookup_key según el tipo
  const isDesarrollador = tipo === "1";
  const description = isDesarrollador ? (
    <div style={styles.description}>
      <h3 style={styles.texto}>
        Suscripcion Premium para desarrolladores inmoviliarios
      </h3>
      <h5 style={styles.texto}>$450.00 MXN / mes</h5>
    </div>
  ) : (
    <div style={styles.description}>
      <h3 style={styles.texto}>Suscripción Broker Plus</h3>
      <h5 style={styles.texto}>$300.00 MXN / mes</h5>
    </div>
  );
  const navigate = useNavigate();
  const lookupKey = isDesarrollador
    ? "Suscripcion_Premium_para_desarrolladores_inmobiliarios-f7f60b3" // Cambia esto según tu lógica
    : "Suscripción_Broker_Plus-6dc414e"; // Valor original para broker

  return (
    <Flex my={20} alignSelf={"center"}>
      {status === "active" ? (
        <VStack space={10}>
          <Heading color="#fff" textAlign={"center"}>
            Tu suscripción está activa
          </Heading>
          <Button
            mx={10}
            style={styles.buttonGradientStyle}
            w={64}
            alignSelf={"center"}
            onPress={() => navigate("/userPanel")}
            borderRadius={20}
            _text={{
              fontFamily: "Graphik",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Volver al panel de usuario
          </Button>
        </VStack>
      ) : (
        <section style={styles.section}>
          <div style={styles.product}>
            <Logo />
            {description}
          </div>
          <form
            action="https://marketeasyrealty.com/stripe/public/create-checkout-session.php"
            method="POST"
          >
            <input type="hidden" name="lookup_key" value={lookupKey} />
            <input type="hidden" name="user_id" value={userId} />{" "}
            {/* Campo oculto para el ID del usuario */}
            <button
              id="checkout-and-portal-button"
              type="submit"
              style={styles.button}
            >
              Confirmar pago
            </button>
          </form>
        </section>
      )}
    </Flex>
  );
};

const SuccessDisplay = ({ sessionId, userId }) => {
  const { actualizarStatus } = useUser();
  useEffect(() => {
    checkStatus();
  }, []);

  const checkStatus = async () => {
    const dataLogin = new FormData();
    console.log("id check:", userId);
    dataLogin.append("user_id", userId);
    const url = `${URL.BASE_URL}/users/status`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta check status: ", res);
    if (res.success) {
      actualizarStatus("active");
    }
  };
  const navigate = useNavigate();

  return (
    <Flex my={20} alignSelf={"center"}>
      <section style={styles.section}>
        <div className="product Box-root">
          <Logo />
          <div className="description Box-root">
            <h3 style={styles.texto}>¡Suscripción exitosa!</h3>
          </div>
        </div>
        <form
          action="https://marketeasyrealty.com/stripe/public/create-portal-session.php"
          method="POST"
        >
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button
            id="checkout-and-portal-button"
            type="submit"
            style={styles.button}
          >
            Gestiona tu información de facturación
          </button>
        </form>
      </section>

      <Button
        mx={10}
        mt={20}
        style={styles.buttonGradientStyle}
        w={64}
        alignSelf={"center"}
        onPress={() => navigate("/userPanel")}
        borderRadius={20}
        _text={{
          fontFamily: "Graphik",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Volver al panel de usuario
      </Button>
    </Flex>
  );
};
const Logo = () => (
  <Image
    source={{
      uri: "https://marketeasyrealty.com/fondos/icono3.png",
    }}
    alt="Alternate Text"
    size="sm"
    m={3}
    resizeMode="contain"
  />
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function SuscripcionBroker() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);

  const { tipo, userId, actualizarStatus, statusSuscripcion } = useUser();
  let [sessionId, setSessionId] = useState("");
  const [idUsuario, setIdUsuario] = useState(userId);
  const [status, setStatus] = useState(statusSuscripcion);

  const getId = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        console.log("async id: ", value);
        setIdUsuario(value);
        checkStatus(value);
        return value;
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };

  const checkStatus = async (id) => {
    const dataLogin = new FormData();
    console.log("id check:", id);
    dataLogin.append("user_id", id);
    const url = `${URL.BASE_URL}/users/status`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta check status: ", res);
    if (res.success) {
      setStatus(res.status);
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
    console.log("sesion id: ", query.get("session_id"));
  }, [sessionId]);

  useEffect(() => {
    getId();
  }, []);

  if (!success && message === "") {
    return <ProductDisplay userId={idUsuario} tipo={tipo} status={status} />;
  } else if (success && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} userId={idUsuario} />;
  } else {
    return <Message message={message} />;
  }
}
