import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  Button,
  Center,
  Heading,
  Modal,
  Text,
  VStack,
  View,
} from "native-base";
import fetchPost from "../../../helper/fetchPost";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import Loader from "../../../components/Loader";
import URL from "../../../helper/baseURL";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { styles } from "../../../styles";
import SuscripcionModal from "../../../components/global/SuscripcionModal";

const SolicitudesDesarrollo = () => {
  const navigate = useNavigate();
  const { tipo, userId } = useUser();
  const [idUsuario, setIdUsuario] = useState(userId);
  const [dataBrokers, setDataBrokers] = useState([]);
  const [apartados, setApartados] = useState(null);

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    textAlign: "center",
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };

  const getId = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        console.log("async id: ", value);
        setIdUsuario(value);
        return value;
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const id = idUsuario || (await getId());
    if (id) {
      setIdUsuario(id);
      await Promise.all([getData(id), getDataBrokers(id)]);
    } else {
      console.error("No se pudo obtener el ID de usuario");
    }
    setLoading(false);
  };

  const getData = async (id) => {
    const data = new FormData();
    data.append("desarrolladora", id);
    console.log("desarrolladora: ", id);

    const url = `${URL.BASE_URL}/leads/desarrolladora`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta prospectos: ", res);
    setApartados(res.data);
  };

  const getDataBrokers = async (id) => {
    const data = new FormData();
    data.append("id_usuario", id);

    const url = `${URL.BASE_URL}/desarrolladora/verBrokers`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta brokers: ", res);
    setDataBrokers(res.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);

  const handleVerBroker = (id, nombre) => {
    scroll.scrollToTop();
    navigate(`/userinformation/${id}/${nombre}/`);
  };
  const handleVerInmueble = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const [inmueble, setInmueble] = useState(null);

  const handleAsignarBrokerModal = (inmueble) => {
    setInmueble(inmueble);
    console.log("Asignar broker inmueble:", inmueble);
    setShowModal(true);
  };

  const handleAsignarBroker = async (broker, inmueble) => {
    console.log("Asignar inmueble: ", inmueble);
    console.log("Asignar broker: ", broker);

    const data = new FormData();
    data.append("broker", broker);
    data.append("id", inmueble);

    const url = `${URL.BASE_URL}/leads/asignarBroker`;
    const options = {
      method: "POST",
      body: data,
    };
    try {
      const res = await fetchPost(url, options);
      console.log("respuesta broker asignado: ", res);
      window.alert(res.message);
      await fetchData(); // Recargar todos los datos después de asignar el broker
      setShowModal(false);
    } catch (error) {
      console.error("Error al asignar broker:", error);
      window.alert(
        "Hubo un error al asignar el broker. Por favor, intente de nuevo."
      );
    }
  };

  const [showModal, setShowModal] = useState(false);

  if (tipo !== "1") {
    return <Login />;
  }

  if (apartados === null) {
    return (
      <View>
        <SuscripcionModal />
        <Heading alignSelf={"center"} my={20} h={96} color="white">
          No tienes prospectos por el momento
        </Heading>
      </View>
    );
  }

  return (
    <View mx={{ base: 0, md: 1, lg: 12 }} flex={1} alignSelf={"center"} my={20}>
      <h1 style={{ textAlign: "center", color: "white" }}>
        Solicitudes recibidas
      </h1>

      <SuscripcionModal />

      {loading === true ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : apartados === null ? (
        <Center p={20}>
          <Text color={"white"} bold italic fontSize={"3xl"}>
            No tienes prospectos
          </Text>
        </Center>
      ) : (
        <TableContainer component={Paper} style={{ zIndex: 0 }}>
          <Table sx={{ background: "#000" }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "#ff7e37" }}>
                <TableCell style={headerText} sx={{ width: "30px" }}>
                  ID
                </TableCell>
                <TableCell style={headerText}>Inmueble</TableCell>
                <TableCell style={headerText}>Prospecto</TableCell>
                <TableCell style={headerText}>Email</TableCell>
                <TableCell style={headerText}>Teléfono</TableCell>
                <TableCell style={headerText}>Broker</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apartados.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#000" : "#333", // Alterna colores negro y gris
                    borderBlockEnd: 1,
                  }}
                >
                  <TableCell style={headerText}>{row.id}</TableCell>
                  <TableCell style={headerText}>
                    <VStack>
                      {row.inmueble_nombre}
                      <Button
                        style={styles.buttonGradientStyle}
                        onPress={() =>
                          handleVerInmueble(
                            `/property/${row.inmueble}/${row.inmueble_nombre}`
                          )
                        }
                      >
                        Ver más
                      </Button>
                    </VStack>
                  </TableCell>
                  <TableCell style={headerText}>
                    {row.prospecto_nombre + " " + row.prospecto_apellido}
                  </TableCell>
                  <TableCell style={headerText}>
                    {row.prospecto_correo}
                  </TableCell>
                  <TableCell style={headerText}>{row.telefono}</TableCell>
                  <TableCell style={headerText}>
                    {row.broker_nombre === null ? (
                      <Button
                        style={styles.buttonGradientStyle}
                        onPress={() => handleAsignarBrokerModal(row.id)}
                      >
                        Asignar Broker
                      </Button>
                    ) : (
                      <VStack>
                        {row.broker_nombre + " " + row.broker_apellido}
                        <Button
                          style={styles.buttonGradientStyle}
                          onPress={() =>
                            handleVerBroker(row.broker, row.broker_nombre)
                          }
                        >
                          Ver perfil
                        </Button>
                      </VStack>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* MODAL SELECCIONAR BROKERS */}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Selecciona Broker para asignar prospecto</Modal.Header>
          <Modal.Body>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#11003a" }}>
                    <TableCell style={headerText}>ID</TableCell>
                    <TableCell style={headerText}>Nombre</TableCell>
                    <TableCell style={headerText}>Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataBrokers.map((broker) => (
                    <TableRow key={broker.ID}>
                      <TableCell>{broker.ID}</TableCell>
                      <TableCell>{`${broker.nombre} ${broker.apellidoP} ${
                        broker.apellidoM || ""
                      }`}</TableCell>
                      <TableCell>
                        <Button
                          style={styles.buttonGradientStyle}
                          onPress={() =>
                            handleAsignarBroker(broker.ID, inmueble)
                          }
                          size="sm"
                        >
                          Asignar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </View>
  );
};
export default SolicitudesDesarrollo;
