import React, { useEffect, useState } from "react";
import {
  View,
  Stack,
  VStack,
  Box,
  Spinner,
  Button,
  Icon,
  Modal,
  Text,
  HStack,
  Heading,
} from "native-base";
import { useParams } from "react-router-dom";
import URL from "../helper/baseURL";
import DetalleEscritorio from "../components/detalleInmueble/DetalleEscritorio";
import MapaGoogle from "../components/detalleInmueble/MapaGoogle";
import FotosPortada from "../components/detalleInmueble/FotosPortada";
import fetchPost from "../helper/fetchPost";
import BotonesDescargar from "../components/detalleInmueble/BotonesDescargar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";

const DetalleInmueble = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inmueble, setInmueble] = useState();
  const [tipoUser, setTipoUser] = useState(null);
  const [idUser, setIdUser] = useState(null);

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("tipo");
      if (value !== null) {
        console.log("tipo user: ", value);
        setTipoUser(value);
      }
    } catch (e) {
      console.log("error: ", e);
      setTipoUser(null);
    }
  };

  const getDataIdUser = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        console.log("ID user: ", value);
        setIdUser(value);
      }
    } catch (e) {
      //console.log("error: ", e);
      setIdUser(null);
    }
  };

  const getInmuebles = async () => {
    const data = new FormData();
    data.append("id_inmueble", id);
    const url = `${URL.BASE_URL}/detalleInmueble`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.status) {
      setInmueble(res.Inmueble);
    }
  };

  useEffect(() => {
    console.log("id inmueble: ", id);
    getInmuebles();
    getData();
    getDataIdUser();
  }, []);

  //  const { t, i18n } = useTranslation();
  //const idiomaActual = i18n.language;

  const BASE_URL = URL.BASE_URL;

  // Estado para almacenar si el formulario debe tener posición fija
  const [isFixed, setIsFixed] = useState(false);

  // Función para actualizar el estado basado en el ancho de la ventana
  const updatePosition = () => {
    const breakpoints = {
      base: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1280,
    };

    const windowWidth = window.innerWidth;
    const shouldBeFixed = windowWidth >= breakpoints.lg; // Cambiar según tus necesidades

    setIsFixed(shouldBeFixed);
  };

  useEffect(() => {
    // Actualizar la posición al montar
    updatePosition();

    // Añadir listener para cambios en el tamaño de la ventana
    window.addEventListener("resize", updatePosition);

    // Limpiar el listener al desmontar el componente
    return () => window.removeEventListener("resize", updatePosition);
  }, []);

  const agendarCita = async () => {
    const data = new FormData();
    data.append("desarrolladora", inmueble.id_user);
    data.append("inmueble", inmueble.ID);
    data.append("prospecto", idUser);
    const url = `${URL.BASE_URL}/leads/nuevo`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    window.alert(res.message);
  };
  const scrollTo = () => {
    scroll.scrollTo("modal"); // Scrolling to 100px from the top of the page.
  };
  const handleClick = () => {
    if (!idUser) {
      setShowModal(true);
      scrollTo();
    } else {
      agendarCita();
    }
  };

  //logica de modal:
  const [showModal, setShowModal] = useState(false);

  const handleNavegation = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        closeOnOverlayClick={false}
      >
        <Modal.Content maxWidth="450px">
          <Modal.CloseButton />
          <Modal.Header>Acceso exclusivo</Modal.Header>
          <Modal.Body>
            <Text fontSize={"lg"}>
              ¡No te pierdas lo mejor! Únete a nuestra comunidad para acceder a
              descargas exclusivas, contenido premium y mucho más. Regístrate o
              inicia sesión para continuar.
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setShowModal(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="darkBlue"
                onPress={() => {
                  handleNavegation("/signup");
                }}
              >
                Registrarse
              </Button>
              <Button
                onPress={() => {
                  handleNavegation("/login");
                }}
              >
                Iniciar Sesión
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      {inmueble ? (
        <View w="100%" alignSelf="center">
          {/* Fotos de portada */}
          <Box w="90%" alignSelf="center" mt={2}>
            <FotosPortada
              portada={inmueble.fotoportada}
              galeria={[
                inmueble.foto1,
                inmueble.foto2,
                inmueble.foto3,
                inmueble.foto4,
              ]}
            />
          </Box>

          <Stack
            direction={["column", "column", "column", "row", "row"]}
            alignSelf="center"
            space={10}
            w={"80%"}
            bg={"white"}
            p={5}
            m={5}
            borderRadius={10}
          >
            <VStack flex={1}>
              <HStack
                p={5}
                alignSelf={"center"}
                mb={2}
                justifyContent={{ base: "center", md: "space-between" }}
              >
                <DetalleEscritorio
                  idProspecto={idUser}
                  idInmueble={inmueble.ID}
                  idDesarrolladora={inmueble.id_user}
                  titulo={inmueble.nombre}
                  bath={inmueble.wc}
                  rooms={inmueble.habitaciones}
                  precio={inmueble.precio}
                  area={inmueble.area}
                  address={inmueble.ubicacion}
                />
              </HStack>

              <VStack p={5} w={"90%"} alignSelf={"center"}>
                <Heading>Descripción:</Heading>
                <Text fontSize={"md"}>{inmueble.descripcion}</Text>
                <Heading>Amenidades:</Heading>
                <HStack space={5}>
                  {/* Primera columna */}
                  <VStack flex={1}>
                    {inmueble.amenidades
                      .split(",")
                      .slice(
                        0,
                        Math.ceil(inmueble.amenidades.split(",").length / 2)
                      )
                      .map((amenidad, index) => (
                        <Text key={index} fontSize={"md"}>
                          • {amenidad.trim()}
                        </Text>
                      ))}
                  </VStack>

                  {/* Segunda columna */}
                  <VStack flex={1}>
                    {inmueble.amenidades
                      .split(",")
                      .slice(
                        Math.ceil(inmueble.amenidades.split(",").length / 2)
                      )
                      .map((amenidad, index) => (
                        <Text key={index} fontSize={"md"}>
                          • {amenidad.trim()}
                        </Text>
                      ))}
                  </VStack>
                </HStack>
              </VStack>
              <HStack alignSelf={"center"} justifyItems={"center"} my={6}>
                <BotonesDescargar
                  brochure={inmueble.brochure}
                  planos={inmueble.planos}
                />
              </HStack>

              <MapaGoogle linkMapa={inmueble.mapa} />
            </VStack>
          </Stack>
        </View>
      ) : (
        <Spinner m={20} size={"lg"} />
      )}
    </>
  );
};

export default DetalleInmueble;
