import { View, Text, Stack, Center } from "native-base";
import ContactoSimple from "../components/ContactoSimple";
import FotoPortada from "../components/global/FotoPortada";

const TerminosCondiciones = () => {
  return (
    <View w="100%">
      <FotoPortada
        url="https://marketeasyrealty.com/fondos/nosotros.jpg"
        texto="Términos y condiciones"
      />

      <Stack w="100%" direction={["column", "column", "row", "row", "row"]}>
        <Stack
          direction={"column"}
          w={["90%", "90%", "50%", "50%", "50%"]}
          mx="5%"
          my={10}
          alignSelf="center"
          justifyContent="center"
        >
          <Text bold fontSize="4xl" color={"#fff"}>
            Términos y condiciones
          </Text>
          <Text fontSize="lg" color={"#fff"}>
            Términos y Condiciones de Uso{"\n\n"}
            1. Aceptación de los Términos{"\n"}
            Al acceder y utilizar esta plataforma web inmobiliaria, usted acepta
            cumplir con estos Términos y Condiciones de Uso. Si no está de
            acuerdo con alguna parte de estos términos, le rogamos que no
            utilice nuestros servicios.{"\n\n"}
            2. Registro de Usuario{"\n"}
            2.1. Para acceder a ciertas funciones de la plataforma, deberá
            registrarse y crear una cuenta.{"\n"}
            2.2. Usted es responsable de mantener la confidencialidad de su
            cuenta y contraseña.{"\n"}
            2.3. Debe proporcionar información precisa y actualizada durante el
            proceso de registro.{"\n\n"}
            3. Tipos de Usuarios{"\n"}
            3.1. La plataforma distingue entre desarrolladores inmobiliarios,
            agentes de ventas y clientes potenciales.{"\n"}
            3.2. Cada tipo de usuario tendrá acceso a funciones específicas de
            la plataforma.{"\n\n"}
            4. Contenido de la Plataforma{"\n"}
            4.1. Los desarrolladores son responsables de la precisión y
            legalidad del contenido que publican.{"\n"}
            4.2. Nos reservamos el derecho de eliminar cualquier contenido que
            consideremos inapropiado o ilegal.{"\n\n"}
            5. Propiedad Intelectual{"\n"}
            5.1. Todo el contenido original de la plataforma está protegido por
            derechos de autor.{"\n"}
            5.2. Los usuarios conservan los derechos de propiedad intelectual
            sobre el contenido que publican.{"\n\n"}
            6. Privacidad y Protección de Datos{"\n"}
            6.1. Recopilamos y procesamos datos personales de acuerdo con
            nuestra Política de Privacidad.{"\n"}
            6.2. Al utilizar la plataforma, usted consiente la recopilación y
            uso de sus datos según lo establecido en dicha política.{"\n\n"}
            7. Limitación de Responsabilidad{"\n"}
            7.1. La plataforma se proporciona "tal cual" y no ofrecemos
            garantías sobre su funcionamiento ininterrumpido o libre de errores.
            {"\n"}
            7.2. No somos responsables de las transacciones o acuerdos
            realizados entre usuarios de la plataforma.{"\n\n"}
            8. Modificaciones de los Términos{"\n"}
            Nos reservamos el derecho de modificar estos Términos y Condiciones
            en cualquier momento. Los cambios entrarán en vigor inmediatamente
            después de su publicación en la plataforma.{"\n\n"}
            9. Ley Aplicable y Jurisdicción{"\n"}
            Estos Términos y Condiciones se regirán e interpretarán de acuerdo
            con las leyes de [insertar jurisdicción], y cualquier disputa estará
            sujeta a la jurisdicción exclusiva de los tribunales de [insertar
            ciudad/país].{"\n\n"}
            10. Contacto{"\n"}
            Si tiene alguna pregunta sobre estos Términos y Condiciones, puede
            contactarnos en [insertar información de contacto].
          </Text>
        </Stack>

        <Center direction={"column"} w={["90%", "90%", "40%", "40%", "40%"]}>
          <ContactoSimple />
        </Center>
      </Stack>
    </View>
  );
};
export default TerminosCondiciones;
