import {
  Pressable,
  Stack,
  VStack,
  Heading,
  Text,
  Image,
  Box,
  Center,
} from "native-base";
import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";

const BotonesInicio = () => {
  const navigate = useNavigate();
  const handleClick = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const BotonBanner = ({ id, url, titulo, texto, nav }) => {
    return (
      <Pressable
        onPress={() => handleClick(nav)}
        w={{ base: 96, md: 64, lg: 96 }}
        px={4}
        h={{ base: 80, md: "450px", lg: "420px" }}
        alignSelf={"center"}
      >
        <VStack
          alignSelf={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Center
            w={64}
            h={64}
            // style={{
            //   background: "linear-gradient(135deg, #000, #111)", // Degradado en línea
            //   boxShadow: "0px 5px 15px 5px rgba(100, 100, 100, 0.4)", // Sombra blanca
            // }}
            // borderRadius={200} // Bordes redondeados para dar forma de burbuja
            // // Sombra intensa para dar efecto de sobresalir
            // borderWidth={1}
            // borderColor={"#353535"}
          >
            <Image
              source={{
                uri: url,
              }}
              alt="Alternate Text"
              w={56}
              h={56}
              p={2}
              alignSelf={"center"}
            />
          </Center>

          <Text
            fontSize={"4xl"}
            style={{ fontFamily: "Graphik" }}
            textAlign={"center"}
            my={3}
            px={2}
            w="95%"
            lineHeight={40}
            color="white"
          >
            {titulo}
          </Text>
          <Text
            px={2}
            fontSize={{ base: "lg", md: "xl" }}
            style={{ fontFamily: "Graphik" }}
            textAlign={"center"}
            color="white"
            lineHeight={20}
          >
            {texto}
          </Text>
        </VStack>
      </Pressable>
    );
  };

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      w="100%"
      space={{ base: 4, lg: 16 }}
      alignSelf={"center"}
      justifyContent={"center"}
      mb={10}
      // alignItems={"center"}
    >
      <BotonBanner
        nav="/properties"
        id={1}
        url="https://marketeasyrealty.com/fondos/iconoportada3.png"
        titulo="Busca desarrollos"
        texto="Explora una variedad de opciones para encontrar tu próximo hogar."
        boton="Buscar casas"
      />
      <BotonBanner
        nav="/proyect"
        id={2}
        url="https://marketeasyrealty.com/fondos/iconoportada1.png"
        titulo="Vende una propiedad"
        texto="Pon en venta tu unidad de una manera fácil y segura."
        boton="Ver tus opciones"
      />
      <BotonBanner
        nav="/contact"
        id={3}
        url="https://marketeasyrealty.com/fondos/iconoportada2.png"
        titulo="Únete a nuestro equipo"
        texto="Forma parte de nuestro equipo y contribuye al éxito de nuestra misión compartida."
        boton="Contáctanos"
      />
    </Stack>
  );
};
export default BotonesInicio;
