import { Button, Modal } from "native-base";

import { useState, useEffect } from "react";
import { useUser } from "../../helper/UserContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { styles } from "../../styles";
import { useNavigate } from "react-router";
import { animateScroll as scroll } from "react-scroll";

const SuscripcionModal = () => {
  const { userId, statusSuscripcion } = useUser();

  const [idUser, setIdUser] = useState(userId);
  const [showModal, setShowModal] = useState(false);

  const [status, setStatus] = useState(statusSuscripcion);
  const getId = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        console.log("async id: ", value);
        setIdUser(value);
        return value;
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };
  const getStatus = async () => {
    try {
      const value = await AsyncStorage.getItem("status_suscripcion");
      if (value !== null) {
        console.log("async status: ", value);
        console.log("async status tipo: ", typeof value);
        setStatus(value);
        if (value !== "active") {
          setShowModal(true);
        }
        return value;
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };

  useEffect(() => {
    getId();
    getStatus();
  }, []);

  const navigate = useNavigate();

  const handlePanel = () => {
    // Lógica para el inicio de sesión
    scroll.scrollToTop();
    navigate("/userPanel");
  };

  const handleSuscripcion = () => {
    // Lógica para el inicio de sesión
    scroll.scrollToTop();
    navigate("/Suscripcion");
  };

  return (
    <Modal isOpen={showModal} onClose={() => {}} zIndex={99999}>
      <Modal.Content>
        <Modal.Header>Tu suscripción no está activa</Modal.Header>
        <Modal.Body>
          Para continuar usando nuestros servicios, debes tener una suscripción
          activa. Por favor, realiza el pago de tu suscripción para acceder al
          contenido.
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space="2">
            <Button
              variant="ghost"
              colorScheme="orange"
              onPress={() => handlePanel()}
            >
              Volver al panel de usuario
            </Button>
            <Button
              style={styles.buttonGradientStyle}
              onPress={() => handleSuscripcion()}
            >
              Pagar suscripción
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default SuscripcionModal;
