// ModeloInversion.jsx
import { View, Box, Stack, Image, Heading, Text, Button } from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import ContactoFooter from "../components/ContactoFooter";
import { styles } from "../styles";

const ModeloInversion = () => {
  const Caja = ({ titulo, texto, fondo }) => {
    return (
      <Box
        w={{ base: "90%", lg: "33%" }}
        bg={fondo}
        h={{ base: "300px", md: "150px", lg: "200px" }}
        borderWidth={1}
        borderColor={"white"}
        alignSelf={"center"}
        justifyItems={"center"}
      >
        <Heading
          color="#bd631d"
          fontFamily={"Graphik"}
          px={{ base: 4, lg: 10 }}
          lineHeight={24}
          py={5}
          fontSize={"3xl"}
        >
          {titulo}
        </Heading>
        <Text
          px={{ base: 4, lg: 10 }}
          fontSize="xl"
          color="white"
          lineHeight={20}
          fontFamily={"Graphik"}
          textAlign={"justify"}
        >
          {texto}
        </Text>
      </Box>
    );
  };

  return (
    <View bg="#000">
      <FotoPortada
        url="https://marketeasyrealty.com/fondos/inversion.jpg"
        texto="MODELO DE INVERSIÓN"
        subtext="Nos esforzamos por ofrecer una experiencia única y eficiente, conectando a desarrolladores con oportunidades de inversión y a brokers con propiedades de alta calidad para satisfacer las necesidades de sus clientes."
        posicion="top"
        boton={true}
      />
      <Button w={40} alignSelf={"center"} style={styles.buttonGradientStyle}>
        CONTÁCTANOS
      </Button>
      <Heading
        alignSelf="center"
        fontFamily={"Times"}
        color="#fff"
        mt={12}
        fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
      >
        MAXIMIZA TU POTENCIAL
      </Heading>
      <Image
        source={{
          uri: "https://marketeasyrealty.com/fondos/icono1.png",
        }}
        alt="Alternate Text"
        size="lg"
        alignSelf={"center"}
      />

      <Heading
        alignSelf="center"
        fontFamily={"Graphik"}
        color="#fff"
        mt={12}
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
      >
        COMO DESARROLLADOR
      </Heading>

      <Stack
        direction={{ base: "column", lg: "row" }}
        space={8}
        my={5}
        justifyContent={"center"}
        alignSelf={"center"}
        w="80%"
      >
        <Caja
          titulo="VISIBILIDAD"
          texto="Presenta tus desarrollos a una audiencia a nivel global de inversores y compradores interesados en propiedades exclusivas."
          fondo=""
        />
        <Caja
          titulo="COLABORACIÓN"
          texto="Conecta con brokers de confianza para agilizar en proceso de venta y maximizar tu retorno de inversión."
          fondo=""
        />
        <Caja
          titulo="ASESORAMIENTO"
          texto="Recibe asesoramiento experto y apoyo en cada etapa del proceso, desde la planificación hasta la venta final."
          fondo=""
        />
      </Stack>
      <Image
        source={{
          uri: "https://marketeasyrealty.com/fondos/icono1.png",
        }}
        alt="Alternate Text"
        size="lg"
        alignSelf={"center"}
      />
      <Heading
        alignSelf="center"
        fontFamily={"Graphik"}
        color="#fff"
        mt={12}
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
      >
        COMO BROKER
      </Heading>

      <Stack
        direction={{ base: "column", lg: "row" }}
        space={8}
        justifyContent={"center"}
        alignSelf={"center"}
        w="80%"
        my={5}
      >
        <Caja
          titulo="PROPIEDADES EXCLUSIVAS"
          texto="Explora una sección cuidadosamente curada de propiedades de lujo disponibles para su venta inmediata."
          fondo="#1e1e1e"
        />
        <Caja
          titulo="COMISIONES ATRACTIVAS"
          texto="Gana comisiones competitivas al conectar a tus clientes con las propiedades que más se adapten a sus necesidades."
          fondo="#1e1e1e"
        />
        <Caja
          titulo="NETWORKING"
          texto="Conecta con desarrolladores y otros profesionales del sector para explorar nuevas oportunidades de negocio."
          fondo="#1e1e1e"
        />
      </Stack>

      <Image
        source={{
          uri: "https://marketeasyrealty.com/fondos/icono3.png",
        }}
        alt="Alternate Text"
        size="xl"
        alignSelf={"center"}
        resizeMode="contain"
      />

      <ContactoFooter />
    </View>
  );
};

export default ModeloInversion;
