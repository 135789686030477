import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { HStack, Pressable, Center, Text, VStack } from "native-base";
import { animateScroll as scroll } from "react-scroll";
import { useUser } from "../helper/UserContext";
import { FaUserAlt } from "react-icons/fa";
import MenuHamburger from "./header/MenuHamburger";
import { secundario } from "../helper/colors";
import LenguajeSelector from "./header/LenguajeSelector";

const Header = () => {
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const { userId } = useUser();

  const handleClickMenu = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const [translateWidgetLoaded, setTranslateWidgetLoaded] = useState(false);

  useEffect(() => {
    if (!translateWidgetLoaded) {
      const googleTranslateScript = document.createElement("script");
      googleTranslateScript.type = "text/javascript";
      googleTranslateScript.async = true;
      googleTranslateScript.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      document.body.appendChild(googleTranslateScript);

      window.googleTranslateElementInit = function () {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "es",
            includedLanguages: "en,es,fr",
          },
          "google_translate_element"
        );
      };
    }
  }, [translateWidgetLoaded]);

  const headerStyle = {
    // position: 'fixed',
    top: 0, // Aplicando el estado de paddingTop aquí
    left: 0,
    right: 0,
    zIndex: 99,
  };

  const ButtonMenu = ({ link, texto, w }) => {
    return (
      <Pressable
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        onPress={() => handleClickMenu(link)}
        alignSelf={"center"}
        display={{ base: "none", lg: "flex" }}
        p={0.5}
        w={w}
        justifyContent="center"
        px={3}
      >
        <Text
          color={"#fff"}
          flex="1"
          fontSize={["lg"]}
          textAlign="center"
          py={2}
          style={{ fontFamily: "Graphik" }}
        >
          {texto}
        </Text>
      </Pressable>
    );
  };

  return (
    <>
      <header ref={headerRef} style={headerStyle}>
        <nav>
          <HStack
            // borderColor={["#ff0", "#0f0", "#00f", "#0f0", "#f50"]}
            // borderWidth={3}
            bg={secundario}
            zIndex={1}
            px={5}
            h={16}
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            space={1}
            // justifyItems={"space-between"}
          >
            {/* menu y logo movil */}
            <Center display={{ base: "block", lg: "none" }}>
              <MenuHamburger />
            </Center>
            <Center
              alignSelf={"center"}
              display={{ base: "block", lg: "none" }}
            >
              <Pressable onPress={() => handleClickMenu("/")}>
                <img
                  src="https://marketeasyrealty.com/fondos/icono3.png"
                  alt="RR"
                  style={{
                    width: "50px",
                    height:
                      "auto" /* Ajusta la altura máxima según sea necesario */,
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                />
              </Pressable>
            </Center>

            {/* Botones de Menú a la Izquierda */}
            <HStack
              justifyContent="flex-start"
              display={{ base: "none", lg: "flex" }}
              flex={1}
              space={4}
              maxW={"80%"}
              minW={"50%"}
            >
              <Pressable
                onPress={() => handleClickMenu("/")}
                mx={{ base: 1, md: 4 }}
                alignSelf={"center"}
                w={"100px"}
              >
                <img
                  src="https://marketeasyrealty.com/fondos/icono3.png"
                  alt="Marea Properties"
                  style={{
                    width: "50px",
                    height:
                      "auto" /* Ajusta la altura máxima según sea necesario */,
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                />
              </Pressable>
              {/* <DestinosMenu /> */}
              {/* boton destinos */}
              <ButtonMenu link="/properties" texto="Compra" />
              <ButtonMenu link="/investment" texto="Invertir" />
              <ButtonMenu link="/proyect" texto="Vender" />

              <ButtonMenu link="/contact" texto="Contacto" />
              <ButtonMenu link="/converter" texto="Convertidor de divisas" />
              <ButtonMenu link="/about" texto="Nosotros" />
            </HStack>

            {/* Imagen LOGO Centrada */}

            {/* Botones de lenguaje y cuenta a la Derecha */}

            <HStack
              pr={2}
              justifyContent="flex-end"
              alignItems={"center"}
              space={{ base: 2, md: 4 }}
            >
              <LenguajeSelector />

              <Pressable
                onPress={() =>
                  userId ? handleClickMenu("/login") : handleClickMenu("/login")
                }
                p={2}
                alignItems="center"
                justifyContent="center"
              >
                <VStack alignItems={"center"}>
                  <FaUserAlt size={16} color={"#fff"} />
                  <Text
                    color={"#fff"}
                    flex="1"
                    fontSize={["lg"]}
                    textAlign="center"
                    style={{ fontFamily: "Graphik" }}
                  >
                    Cuenta
                  </Text>
                </VStack>
              </Pressable>
            </HStack>
          </HStack>
        </nav>
      </header>
      <Outlet />
    </>
  );
};

export default Header;
