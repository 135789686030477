import React, { useEffect, useState } from "react";
import fetchPost from "../../helper/fetchPost";
import { Button, Pressable, Text, VStack } from "native-base";
import URL from "../../helper/baseURL";
import { styles } from "../../styles";

const CodigoReferencia = ({ id }) => {
  const [codigoReferido, setCodigoReferido] = useState(null);

  const getCodigoReferido = async (idUser) => {
    console.log("id user codigo: ", idUser);
    const dataLogin = new FormData();
    dataLogin.append("id_user", idUser);
    const url = `${URL.BASE_URL}/referidos/verCodigo`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta login", res);
    if (res.status) {
      setCodigoReferido(res.data);
    }
  };

  useEffect(() => {
    getCodigoReferido(id);
  }, [id]);

  const handleClick = () => {
    navigator.clipboard
      .writeText(codigoReferido)
      .then(() => {
        window.alert(`Código copiado al portapapeles: ${codigoReferido}`);
      })
      .catch((error) => {
        window.alert(`Error al copiar al portapapeles: ${error}`);
      });
  };

  return (
    <Button
      px={10}
      style={styles.buttonGradientStyle}
      display={codigoReferido ? "flex" : "none"}
      alignSelf={"center"}
      onPress={handleClick}
    >
      <Text bold color="#fff" fontSize={"lg"}>
        Código de referido:
      </Text>
      <Text fontSize={"2xl"} textAlign={"center"} color="#fff">
        {codigoReferido}
      </Text>
    </Button>
  );
};
export default CodigoReferencia;
