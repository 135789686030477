import { Text, View, Stack, Box } from "native-base";
import { useUser } from "../../../helper/UserContext";
import { useNavigate } from "react-router";
import { PiUserList } from "react-icons/pi";
import { MdOutlineWorkHistory } from "react-icons/md";
import OpcionUser from "../../../components/global/OpcionUser";
import LogoutConfirmationPopover from "../../../components/LogoutConfirmationPopover";
import { LuHotel } from "react-icons/lu";

const UserPanel = () => {
  const { logout, nombre, apellido, correo } = useUser();

  const navigate = useNavigate();

  const handleSalir = () => {
    // Lógica para el inicio de sesión
    logout();
    navigate("/");
  };

  const UserNav = (nav) => {
    navigate(nav);
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
  };

  return (
    <View mt={24}>
      <Text bold fontSize={"2xl"} textAlign="center" color="white">
        BIENVENIDO
      </Text>
      <Stack my={3} ml="20%">
        <Text bold fontSize={"2xl"} textAlign="justify" px={10} color="white">
          Cuenta
        </Text>

        <Text bold fontSize={"lg"} px={10} color="white">
          {nombre} {apellido}.
        </Text>
        <Text fontWeight={"semibold"} fontSize={"md"} px={10} color="white">
          {correo}
        </Text>
      </Stack>

      <Box w="80%" alignSelf="center">
        <div style={stackContainerStyle}>
          <OpcionUser
            titulo="Información personal"
            texto="Visualiza y edita tus datos personales"
            funcion={() => UserNav("/UserInfo")}
            icon={PiUserList}
          />

          {/* <OpcionUser
            titulo="Solicitudes de apartado"
            texto="Ver las solicitudes de brokers o clientes"
            funcion={() => UserNav("/SolicitudesUser")}
            icon={LuHotel}
          /> */}

          <OpcionUser
            titulo="Favoritos"
            texto="Revisa tus favoritos"
            funcion={() => UserNav("/Favoritos")}
            icon={MdOutlineWorkHistory}
          />
        </div>
      </Box>

      <LogoutConfirmationPopover onConfirm={handleSalir} />
    </View>
  );
};
export default UserPanel;
