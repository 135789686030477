import React, { useState, useEffect } from "react";
import {
  VStack,
  FormControl,
  Input,
  Button,
  View,
  Text,
  Center,
  Icon,
  Heading,
  Alert,
  Stack,
  Select,
  HStack,
  CheckIcon,
  TextArea,
  Divider,
  InputGroup,
  InputLeftAddon,
  Image,
} from "native-base";
import { useUser } from "../../helper/UserContext";
import {
  FaRegEye,
  FaRegEyeSlash,
  FaFacebook,
  FaTiktok,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import { FaUserPen } from "react-icons/fa6";
import fetchPost from "../../helper/fetchPost";
import URL from "../../helper/baseURL";
import {
  MdAlternateEmail,
  MdOutlinePassword,
  MdAddPhotoAlternate,
} from "react-icons/md";
import Loader from "../Loader";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router";
import { animateScroll as scroll } from "react-scroll";
import { FaSave, FaUserCircle } from "react-icons/fa";
import { styles } from "../../styles";

function InfoUserComponent() {
  const navigate = useNavigate();

  const { correo, apellido, nombre, userId, telefono, actualizaUser, tipo } =
    useUser();
  const [name, setName] = useState(nombre);
  const [lastName, setLastName] = useState(apellido);
  const [phone, setPhone] = useState(telefono);

  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [lada, setLada] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertVisible(true);
  };

  const validateUserInfo = () => {
    if (name.trim() === "") {
      showAlert("Por favor, ingresa tu nombre.");
      return false;
    }
    if (lastName.trim() === "") {
      showAlert("Por favor, ingresa tu apellido.");
      return false;
    }
    if (phone.trim() === "") {
      showAlert("Por favor, ingresa tu teléfono.");
      return false;
    } else if (phone.trim().length < 10 || phone.trim().length > 13) {
      showAlert("El teléfono debe tener entre 10 y 13 números.");
      return false;
    }

    // Agrega aquí más validaciones si lo necesitas
    return true;
  };

  const validatePasswordChange = () => {
    if (currentPassword.trim() === "") {
      showAlert("Por favor, ingresa tu contraseña actual.");
      return false;
    }
    if (newPassword.trim() === "") {
      showAlert("Por favor, ingresa tu nueva contraseña.");
      return false;
    }
    if (confirmPassword.trim() === "") {
      showAlert("Por favor, confirma tu nueva contraseña.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      showAlert("La nueva contraseña y la confirmación no coinciden.");
      return false;
    }
    // Agrega aquí más validaciones si lo necesitas
    return true;
  };

  const handleChangePassword = async () => {
    if (validatePasswordChange()) {
      const dataLogin = new FormData();
      dataLogin.append("ID", userId);
      dataLogin.append("Correo", correo);
      dataLogin.append("PassAntigua", currentPassword);
      dataLogin.append("PassNueva", newPassword);
      const url = `${URL.BASE_URL}/actualizarPassword`;
      const options = {
        method: "POST",
        body: dataLogin,
      };
      const res = await fetchPost(url, options);
      console.log(res);

      setIsLoading(false);
      if (res.exito) {
        handleUpdate(name, lastName, phone);
        window.alert(res.mensaje);
      } else {
        window.alert(res.mensaje);
      }
    }
  };

  //contraseña icono mostrar
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [show2, setShow2] = useState(false);
  const handleClick2 = () => setShow2(!show2);
  const [show3, setShow3] = useState(false);
  const handleClick3 = () => setShow3(!show3);

  const [user, setUser] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = (nombreUser, apellidoUser, telUser) => {
    // Lógica para el inicio de sesión
    actualizaUser(nombreUser, apellidoUser, telUser);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!validateUserInfo()) {
      setIsLoading(false); // Si la validación falla, detener la ejecución
      return;
    }
    const dataLogin = new FormData();
    dataLogin.append("ID", userId);
    dataLogin.append("nombre", nombre);
    dataLogin.append("apellidoP", lastName);
    dataLogin.append("facebook", facebook);
    dataLogin.append("tiktok", tiktok);
    dataLogin.append("instagram", instagram);
    dataLogin.append("descripcion", descripcion);
    dataLogin.append("foto", imagen);
    dataLogin.append("lada", lada);
    dataLogin.append("telefono", phone);

    const url = `${URL.BASE_URL}/actualizarInfoUser`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log(res);

    setIsLoading(false);
    if (res.exito) {
      handleUpdate(name, lastName, phone);
      window.alert(res.mensaje);
    } else {
      window.alert(res.mensaje);
    }
  };

  const handlePerfil = () => {
    console.log("boton");
    navigate(`../userinformation/${userId}/${nombre}`);
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const [imagen, setImagen] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");

  const handlePortadaChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImagen(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("reader.result:", reader.result);
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    // Primero validar las credenciales

    const data = new FormData();
    data.append("ID", userId);
    data.append("nombre", nombre);
    const url = `${URL.BASE_URL}/verPerfil`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta login", res);
    if (res.exito) {
      setUser(res.datos);
      setDescripcion(res.datos.descripcion);
      setLastName(res.datos.apellidoP);
      setLada(res.datos.lada);
      console.log("lada: ", res.datos.lada);
      console.log("telefono: ", res.datos.telefono);
      setPhone(res.datos.telefono);
      setTiktok(res.datos.tiktok);
      setFacebook(res.datos.facebook);
      setInstagram(res.datos.instagram);
      setPreviewUrl(
        "https://marketeasyrealty.com/back/public/uploads/profiles/" +
          res.datos.foto
      );
      setIsLoading(false);
    } else {
      window.alert("Error al buscar usuario, intente más tarde");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    console.log("preview", previewUrl);
  }, [previewUrl]);
  const linkStyle = {
    color: "#ffa538",
  };

  return (
    <View pt={24} w="100%">
      <Heading alignSelf="center" color="white">
        Mi información personal
      </Heading>
      <Text mb={3} alignSelf="center" color="white" fontSize="lg">
        Visualiza y edita tu información
      </Text>

      {isLoading ? (
        <Center my={20}>
          <Loader size={"md"} />
        </Center>
      ) : (
        <Stack
          alignSelf="center"
          justifyContent="space-between"
          my={5}
          direction={["column", "column", "row", "row"]}
          space={4}
          width="90%"
          mx="auto"
        >
          {/* DATOS NOMBRE, REDES */}
          <VStack w={["90%", "90%", "40%", "40%"]}>
            <Text my={1} bold color="white" fontSize={"xl"}>
              Información personal
            </Text>
            {/* NOMBRE */}
            <FormControl>
              <FormControl.Label>
                <Text color="white" fontSize={"lg"}>
                  Nombre actual: {name}
                </Text>
              </FormControl.Label>
              <Input
                shadow={6}
                value={name}
                onChangeText={setName}
                placeholder={name}
                bg="#000"
                color="#ff8b39"
                focusOutlineColor="#ff8b39"
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<FaUserPen color={"white"} />} />
                  </Center>
                }
              />
            </FormControl>
            {/* APELLIDO */}
            {tipo === "2" || tipo === "3" ? (
              <FormControl>
                <FormControl.Label>
                  <Text color="white" fontSize={"lg"}>
                    Apellido actual: {lastName}
                  </Text>
                </FormControl.Label>
                <Input
                  shadow={6}
                  value={lastName}
                  onChangeText={setLastName}
                  placeholder={apellido}
                  bg="#000"
                  color="#ff8b39"
                  focusOutlineColor="#ff8b39"
                  InputLeftElement={
                    <Center ml={2}>
                      <Icon as={<FaUserPen color={"white"} />} />
                    </Center>
                  }
                />
              </FormControl>
            ) : null}

            {/* Telefono */}
            <Divider m={4} />
            <HStack>
              <Center>
                <FaWhatsapp color="white" size={32} />
              </Center>
              <FormControl.Label mx={2}>
                <Text color="white" fontSize={"lg"}>
                  Teléfono actual: {lada + " " + phone}
                </Text>
              </FormControl.Label>
            </HStack>
            {/* TELEFONO */}
            <HStack space={2}>
              <FormControl w={32}>
                <FormControl.Label>
                  {" "}
                  <Text color="white" fontSize={"lg"}>
                    LADA
                  </Text>
                </FormControl.Label>
                <Select
                  selectedValue={lada}
                  w={32}
                  accessibilityLabel="LADA"
                  placeholder="LADA"
                  bg="#000"
                  color="#ff8b39"
                  focusOutlineColor="#ff8b39"
                  _selectedItem={{
                    bg: "white",
                    endIcon: <CheckIcon size="3" />,
                  }}
                  onValueChange={(itemValue) => setLada(itemValue)}
                >
                  <Select.Item label="MX (+52)" value="+52" />
                  <Select.Item label="US (+1)" value="+1" />
                  <Select.Item label="FR (+33)" value="+33" />
                  <Select.Item label="CA (+1)" value="+1" />
                </Select>
              </FormControl>
              {/* TELEFONO */}
              <FormControl flex={1}>
                <FormControl.Label>
                  <Text color="white" fontSize={"lg"}>
                    Teléfono
                  </Text>
                </FormControl.Label>
                <InputMask
                  mask="999 999 9999"
                  value={phone}
                  onChange={handleChangePhone}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      value={phone}
                      bg="#000"
                      color="#ff8b39"
                      focusOutlineColor="#ff8b39"
                      placeholder="Teléfono"
                      onChange={handleChangePhone}
                    />
                  )}
                </InputMask>
              </FormControl>
            </HStack>
            <Divider m={4} />

            {/* REDES SOCIALES */}
            <VStack space={2}>
              <FormControl>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  alignItems={{ base: "flex-start", lg: "center" }}
                  space={2}
                  mb={2}
                >
                  <HStack space={3} alignItems={"center"}>
                    <Center m={2}>
                      <Icon as={<FaFacebook color="white" size={32} />} />
                    </Center>
                    <FormControl.Label>
                      <Text color="white" fontSize={"lg"}>
                        Facebook:{" "}
                      </Text>
                    </FormControl.Label>
                  </HStack>
                  <a
                    href={facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={linkStyle}
                  >
                    {facebook}
                  </a>
                </Stack>

                <Input
                  shadow={6}
                  value={facebook}
                  onChangeText={setFacebook}
                  placeholder={"Facebook"}
                  bg="#000"
                  color="#ff8b39"
                  focusOutlineColor="#ff8b39"
                />
              </FormControl>
              {/* instagram */}
              <FormControl>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  alignItems={{ base: "flex-start", lg: "center" }}
                  space={2}
                  mb={2}
                >
                  <HStack space={3} alignItems={"center"}>
                    <Center m={2}>
                      <Icon as={<FaInstagram color="white" size={32} />} />
                    </Center>
                    <FormControl.Label>
                      <Text color="white" fontSize={"lg"}>
                        Instagram:{" "}
                      </Text>
                    </FormControl.Label>
                  </HStack>
                  <a
                    href={instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={linkStyle}
                  >
                    {instagram}
                  </a>
                </Stack>

                <Input
                  shadow={6}
                  value={instagram}
                  onChangeText={setInstagram}
                  placeholder={"Instagram"}
                  bg="#000"
                  color="#ff8b39"
                  focusOutlineColor="#ff8b39"
                />
              </FormControl>
              {/* tik tok  */}
              <FormControl>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  alignItems={{ base: "flex-start", lg: "center" }}
                  space={2}
                  mb={2}
                >
                  <HStack space={3} alignItems={"center"}>
                    <Center m={2}>
                      <Icon as={<FaTiktok color="white" size={32} />} />
                    </Center>
                    <FormControl.Label>
                      <Text color="white" fontSize={"lg"}>
                        TikTok:{" "}
                      </Text>
                    </FormControl.Label>
                  </HStack>
                  <a
                    href={tiktok}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={linkStyle}
                  >
                    {tiktok}
                  </a>
                </Stack>

                <Input
                  shadow={6}
                  value={tiktok}
                  onChangeText={setTiktok}
                  placeholder={"TikTok"}
                  bg="#000"
                  color="#ff8b39"
                  focusOutlineColor="#ff8b39"
                />
              </FormControl>
              {/* Descripcion */}
              <FormControl>
                <HStack space={4}>
                  <Center ml={2}>
                    <Icon as={<FaUserPen color="white" size={32} />} />
                  </Center>
                  <FormControl.Label>
                    <Text color="white" fontSize={"lg"}>
                      {" "}
                      Descripcion:
                    </Text>
                  </FormControl.Label>
                </HStack>
                <TextArea
                  shadow={6}
                  h={40}
                  value={descripcion}
                  onChangeText={setDescripcion}
                  placeholder="Descripcion"
                  bg="#000"
                  color="#ff8b39"
                  focusOutlineColor="#ff8b39"
                />
              </FormControl>
              {/* Correo electonico */}
              <FormControl>
                <FormControl.Label>
                  <Center ml={3}>
                    <Icon as={<MdAlternateEmail color="white" size={32} />} />
                  </Center>
                  <Text color="white" fontSize={"lg"}>
                    Correo Electrónico
                  </Text>
                </FormControl.Label>
                <Input
                  shadow={6}
                  bg="#000"
                  color="#ff8b39"
                  focusOutlineColor="#ff8b39"
                  value={correo}
                  isReadOnly={true}
                  InputLeftElement={
                    <Center ml={2}>
                      <Icon as={<MdAlternateEmail color="white" />} />
                    </Center>
                  }
                />
              </FormControl>
            </VStack>
            {isAlertVisible && (
              <Alert w="100%" status="error">
                <Alert.Icon />
                <Text>{alertMessage}</Text>
              </Alert>
            )}
          </VStack>

          {/* Cambiar contraseña  y foto de perfil*/}
          <VStack w={["90%", "90%", "40%", "40%"]} alignSelf={"center"}>
            <FormControl
              alignSelf={"center"}
              maxH={96}
              minH={28}
              my={5}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text bold fontSize="lg" color="white" my={3} alignSelf="center">
                Foto de Perfil:
              </Text>
              <input
                type="file"
                onChange={handlePortadaChange}
                style={{ display: "none", bg: "white" }}
                id="file-input-portada"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl && (
                <img
                  src={previewUrl}
                  alt="Previsualización de la imagen"
                  width="250"
                  height="250"
                />
              )}
              <label
                htmlFor="file-input-portada"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  w={64}
                  h={12}
                  mb={4}
                  style={styles.buttonGradientStyle}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto de Perfil
                </Button>
              </label>
            </FormControl>

            <Stack>
              {isLoading ? (
                <Center my={10}>
                  <Loader size="md" color="teal.500" />
                </Center>
              ) : (
                <Button
                  shadow={6}
                  style={styles.buttonGradientStyle}
                  w={64}
                  h={12}
                  alignSelf="center"
                  my={4}
                  onPress={handleSubmit}
                  leftIcon={<Icon as={<FaSave color="#fff" size={32} />} />}
                >
                  Actualizar Información
                </Button>
              )}
              {/* ver perfil */}
              <Button
                shadow={6}
                style={styles.buttonGradientStyle}
                w={64}
                h={12}
                alignSelf="center"
                my={4}
                onPress={handlePerfil}
                leftIcon={<Icon as={<FaUserCircle color="#fff" size={32} />} />}
              >
                Ver perfil público
              </Button>
            </Stack>
            {/* CAMBIAR CONTRASEÑA */}
            <Center style={styles.borderGradientStyle}>
              <VStack w={"100%"} px={5}>
                <Text
                  my={1}
                  bold
                  alignSelf="center"
                  color="white"
                  fontSize={"lg"}
                >
                  Cambiar contraseña
                </Text>
                <FormControl>
                  <FormControl.Label>
                    <Text color="white" fontSize={"lg"}>
                      Ingresa Contraseña Actual
                    </Text>
                  </FormControl.Label>
                  <Input
                    shadow={6}
                    bg="#000"
                    color="#ff8b39"
                    focusOutlineColor="#ff8b39"
                    value={currentPassword}
                    onChangeText={setCurrentPassword}
                    type={show ? "text" : "password"}
                    InputRightElement={
                      <Button
                        ml={1}
                        variant="link"
                        roundedLeft={0}
                        roundedRight="md"
                        onPress={handleClick}
                      >
                        {show ? (
                          <FaRegEyeSlash color="white" />
                        ) : (
                          <FaRegEye color="white" />
                        )}
                      </Button>
                    }
                    InputLeftElement={
                      <Center ml={2}>
                        <Icon as={<MdOutlinePassword color="white" />} />
                      </Center>
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormControl.Label>
                    <Text color="white" fontSize={"lg"}>
                      Nueva Contraseña
                    </Text>
                  </FormControl.Label>
                  <Input
                    shadow={6}
                    bg="#000"
                    color="#ff8b39"
                    focusOutlineColor="#ff8b39"
                    value={newPassword}
                    onChangeText={setNewPassword}
                    type={show2 ? "text" : "password"}
                    InputRightElement={
                      <Button
                        ml={1}
                        variant="link"
                        roundedLeft={0}
                        roundedRight="md"
                        onPress={handleClick2}
                      >
                        {show2 ? (
                          <FaRegEyeSlash color="white" />
                        ) : (
                          <FaRegEye color="white" />
                        )}
                      </Button>
                    }
                    InputLeftElement={
                      <Center ml={2}>
                        <Icon as={<MdOutlinePassword color="white" />} />
                      </Center>
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormControl.Label>
                    <Text color="white" fontSize={"lg"}>
                      Confirmar Contraseña
                    </Text>
                  </FormControl.Label>
                  <Input
                    shadow={6}
                    bg="#000"
                    color="#ff8b39"
                    focusOutlineColor="#ff8b39"
                    value={confirmPassword}
                    onChangeText={setConfirmPassword}
                    type={show3 ? "text" : "password"}
                    InputRightElement={
                      <Button
                        ml={1}
                        variant="link"
                        roundedLeft={0}
                        roundedRight="md"
                        onPress={handleClick3}
                      >
                        {show3 ? (
                          <FaRegEyeSlash color="white" />
                        ) : (
                          <FaRegEye color="white" />
                        )}
                      </Button>
                    }
                    InputLeftElement={
                      <Center ml={2}>
                        <Icon as={<MdOutlinePassword color="white" />} />
                      </Center>
                    }
                  />
                </FormControl>

                <Button
                  mb={10}
                  w={64}
                  h={12}
                  alignSelf="center"
                  my={4}
                  style={styles.buttonGradientStyle}
                  shadow={6}
                  onPress={handleChangePassword}
                  leftIcon={
                    <Icon as={<MdOutlinePassword color="#fff" size={32} />} />
                  }
                >
                  Cambiar Contraseña
                </Button>
              </VStack>
            </Center>
          </VStack>
        </Stack>
      )}
    </View>
  );
}

export default InfoUserComponent;
