import { FlatList, Flex, Spinner } from "native-base";
import { useEffect, useState } from "react";
import InmuebleComponent from "./InmuebleComponent";
import fetchPost from "../helper/fetchPost";
import URL from "../helper/baseURL";

const FlatListInmuebles = () => {
  // Datos estáticos para cargar en el FlatList
  const datosEstaticos = [
    {
      ID: "1",
      fotoportada: "BELEHU.webp",
      nombre: "BELEHU",
      ubicacion: "Tulum",
      precio: "2,800,000",
      rooms: "2",
      bath: "1",
      guest: "2",
      tipo: "Departamento",
    },
    {
      ID: "2",
      fotoportada: "oceantulum.jpg",
      nombre: "Ocean Tulum",
      ubicacion: "Cancún",
      precio: "2,300,000",
      rooms: "2",
      bath: "1",
      guest: "2",
      tipo: "Lotes residenciales",
    },
  ];

  const [viajes, setViajes] = useState(datosEstaticos);

  // Código para determinar el número de columnas basado en el tamaño de la pantalla
  const breakpoints = {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
  };

  const numColumns =
    window.innerWidth < breakpoints.sm
      ? 1
      : window.innerWidth < breakpoints.md
      ? 2
      : window.innerWidth < breakpoints.lg
      ? 2
      : window.innerWidth < breakpoints.xl
      ? 3
      : 3; // Puedes ajustar los valores según tus necesidades

  const [inmuebles, setInmuebles] = useState(datosEstaticos);
  const [isLoading, setIsLoading] = useState(true);

  const getInmuebles = async () => {
    const url = `${URL.BASE_URL}verInmuebles`;
    const options = {
      method: "POST",
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.status) {
      setInmuebles(res.Inmuebles);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInmuebles();
  }, []);

  return (
    <>
      {isLoading ? (
        <Flex my={20}>
          <Spinner size="lg" my={20} py={20} />
        </Flex>
      ) : (
        <Flex w={"100%"} alignSelf="center" pb={8}>
          <FlatList
            // style={{ width: '100%', marginTop: 5, paddingHorizontal: '2vw' }}
            contentContainerStyle={{
              justifyContent: "center",
              alignItems: "center",
            }}
            data={inmuebles}
            numColumns={numColumns}
            keyExtractor={(item) => item.ID}
            key={numColumns.toString()} // Usa el número de columnas como clave
            renderItem={({ item }) => (
              <InmuebleComponent
                key={item.ID}
                imageUri={item.fotoportada}
                titulo={item.nombre}
                estado={item.estado}
                municipio={item.municipio}
                precio={item.precio}
                id={item.ID}
                rooms={item.habitaciones}
                bath={item.wc}
                area={item.area}
              />
            )}
          />
        </Flex>
      )}
    </>
  );
};

export default FlatListInmuebles;
