import React, { useState } from "react";
import {
  Pressable,
  Modal,
  Button,
  Image,
  VStack,
  HStack,
  Text,
} from "native-base";
import { GrLanguage } from "react-icons/gr";

const LenguajeSelector = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Pressable
        onPress={() => setIsOpen(true)}
        alignItems="center"
        justifyContent="center"
        alignSelf={"center"}
        p={2}
        h={16}
      >
        <VStack>
          <HStack space={1}>
            <Image
              source={{
                uri: "https://marketeasyrealty.com/img/usa.png",
              }}
              alt="Alternate Text"
              size={5}
            />
            <Image
              source={{
                uri: "https://marketeasyrealty.com/img/france.png",
              }}
              alt="Alternate Text"
              size={5}
            />
          </HStack>
          <Text
            color={"#fff"}
            flex="1"
            fontSize={["lg"]}
            textAlign="center"
            style={{ fontFamily: "Graphik" }}
          >
            Idioma
          </Text>
        </VStack>

        {/* <GrLanguage size={24} color={"#dadada"} /> */}
      </Pressable>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        top={32}
        justifyContent="flex-start"
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Selecciona idioma</Modal.Header>
          <Modal.Body>
            <div id="google_translate_element" />
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={() => setIsOpen(false)}>Aceptar</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default LenguajeSelector;
