import { View, Text, ZStack, VStack } from "native-base";
import React from "react";
import { styles } from "../../styles";

const FotoPortada = ({ url, texto, posicion, subtext, boton }) => {
  const imagenDeFondo = {
    backgroundImage: `url("${url}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: posicion,
    width: "100%", // Asegúrate de ajustar estas propiedades según tus necesidades
    height: "96", // Ajusta la altura según necesites
  };

  return (
    <View w="100%" style={imagenDeFondo}>
      <ZStack
        w="100%"
        h={{ base: "450px", md: "500px", lg: "600px" }}
        style={styles.gradientNegroStyle}
      >
        <VStack alignSelf={"center"} mt={40} w="100%">
          <Text
            fontSize={["4xl", "4xl", "4xl", "6xl", "8xl"]}
            color="#fff"
            alignSelf="center"
            mt={{ base: 20, md: 40 }}
            textAlign="center"
            fontFamily="Baskerville"
            style={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
            }}
          >
            {texto}
          </Text>
          <Text
            fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
            color="#fff"
            alignSelf="center"
            px={{ base: "5%", md: "10%", lg: "20%" }}
            textAlign="center"
            lineHeight={20}
            fontFamily="Baskerville"
            style={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
            }}
          >
            {subtext}
          </Text>
        </VStack>
      </ZStack>
    </View>
  );
};

export default FotoPortada;
