// Inmuebles.jsx
import { View } from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import FlatListInmuebles from "../components/FlatListInmuebles";
import ContactoFooter from "../components/ContactoFooter";

const Inmuebles = () => {
  return (
    <View bg="#000">
      <FotoPortada
        url="https://marketeasyrealty.com/fondos/inmuebles.png"
        texto="INMUEBLES"
        subtext="Encuentra los mejores desarrollos para inversión a lo largo del mundo."
        posicion="center"
      />

      <View alignContent={"center"} pt={12} w="100%">
        <FlatListInmuebles />
      </View>

      <ContactoFooter />
    </View>
  );
};

export default Inmuebles;
