import { Text, Stack, VStack, Button, HStack, Image } from "native-base";
import React from "react";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { styles } from "../../styles";
const InvitacionRegistro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    scroll.scrollToTop();
    navigate("/login");
  };

  const Imagen = ({ url }) => {
    return (
      <Image
        source={{
          uri: url,
        }}
        ml={{ md: 0, lg: -12 }}
        alt="Alternate Text"
        w={{ md: "30vh", lg: "700px" }}
        h={96}
        display={{ base: "none", md: "block" }}
      />
    );
  };

  const Bandera = ({ bandera }) => {
    return (
      <Image
        source={{
          uri: `https://marketeasyrealty.com/img/${bandera}`,
        }}
        alt="Alternate Text"
        h={{ base: 8, md: 10 }}
        w={{ base: 8, md: 10 }}
        m={1}
      />
    );
  };

  return (
    <Stack
      direction={{ base: "column", md: "row", lg: "row" }}
      alignSelf={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      py={2}
      my={10}
      w="100%"
      space={6}
    >
      <VStack
        w={{ base: "90%", md: "50%", lg: "40%" }}
        px={4}
        space={2}
        alignSelf={"center"}
        style={styles.borderGradientStyle}
      >
        <Text
          fontSize={{ base: "4xl", md: "6xl" }}
          color="#bd6823"
          mx={4}
          style={{ fontFamily: "Graphik" }}
          lineHeight={40}
          textAlign={{ base: "center", md: "flex-start" }}
        >
          ACCESO{"\n"}EXCLUSIVO
        </Text>
        <Text
          mx={4}
          textAlign={{ base: "center", md: "flex-start" }}
          fontSize={{ base: "xl", md: "2xl" }}
          color="#fff"
          lineHeight={20}
          style={{ fontFamily: "Graphik" }}
        >
          A LOS MEJORES DESARROLLOS{"\n"}INMOBILIARIOS EN DISTINTOS PAÍSES
        </Text>
        <Stack
          direction={{ base: "column", md: "row" }}
          alignSelf={{ base: "center", md: "flex-start" }}
          alignItems={"center"}
          mt={4}
          w="95%"
          justifyContent="space-between"
        >
          <Button
            mx={2}
            px={3}
            mb={3}
            size="lg"
            style={styles.buttonGradientStyle}
            onPress={() => handleClick()}
          >
            <Text
              style={{ fontFamily: "Graphik" }}
              color="#fff"
              fontSize={"xl"}
            >
              Iniciar sesión
            </Text>
          </Button>
          <HStack space={2}>
            <Bandera bandera="mexico.png" />
            <Bandera bandera="usa.png" />
            <Bandera bandera="france.png" />
            <Bandera bandera="spain.png" />
          </HStack>
        </Stack>
      </VStack>

      <HStack mx={4}>
        <Imagen url="https://marketeasyrealty.com/fondos/inversion.jpg" />
      </HStack>
    </Stack>
  );
};

export default InvitacionRegistro;
