import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  VStack,
  View,
} from "native-base";
import { IconContext } from "react-icons";
import { BiArea } from "react-icons/bi";
import { IoBedOutline } from "react-icons/io5";
import { LuBath } from "react-icons/lu";
import { FaHouseLock } from "react-icons/fa6";
import fetchPost from "../../helper/fetchPost";
import { animateScroll as scroll } from "react-scroll";
import URL from "../../helper/baseURL";

const DetalleEscritorio = ({
  idProspecto,
  idInmueble,
  idDesarrolladora,
  titulo,
  descripcion,
  bath,
  rooms,
  precio,
  area,
  address,
}) => {
  const agendarCita = async () => {
    const data = new FormData();
    data.append("desarrolladora", idDesarrolladora);
    data.append("inmueble", idInmueble);
    data.append("prospecto", idProspecto);
    const url = `${URL.BASE_URL}/leads/nuevo`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    window.alert(res.message);
  };
  //logica de modal:
  const [showModal, setShowModal] = useState(false);

  const scrollTo = () => {
    scroll.scrollTo("modal"); // Scrolling to 100px from the top of the page.
  };

  const handleClick = () => {
    if (!idProspecto) {
      setShowModal(true);
      scrollTo();
    } else {
      agendarCita();
    }
  };

  const DatosComponent = ({ icono, cantidad, medida }) => {
    return (
      <Stack
        p={4}
        direction={{ base: "row", md: "column" }}
        space={1}
        alignSelf={{ base: "flex-start", md: "center" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Center>{icono}</Center>
        <Text fontSize={{ base: "md", md: "2xl" }} bold>
          {cantidad}
        </Text>
        <Text fontSize={"md"}>{medida}</Text>
      </Stack>
    );
  };

  return (
    <View>
      <Stack
        flex={1}
        direction={{ base: "column", lg: "row" }}
        justifyContent={{ base: "center", md: "space-between" }}
      >
        <VStack justifyItems={"center"} justifyContent={"center"}>
          <Text style={{ fontFamily: "Circular" }} fontSize="2xl">
            {titulo}
          </Text>
          <Text style={{ fontFamily: "Circular" }} fontSize="2xl">
            $ {precio} EUR
          </Text>
          <Text fontSize={"lg"}>{address}</Text>
        </VStack>

        <Stack
          space={{ base: 0, md: 3 }}
          p={4}
          direction={{ base: "column", md: "row" }}
          flex={1}
        >
          <DatosComponent
            icono={<IoBedOutline size="32px" />}
            cantidad={rooms}
            medida="habitaciones"
          />
          <DatosComponent
            icono={<LuBath size="32px" />}
            cantidad={bath}
            medida="baños"
          />
          <DatosComponent
            icono={<BiArea size="32px" />}
            cantidad={area}
            medida="metros cuadrados"
          />
        </Stack>

        {/* boton suspendido para apartar inmueble */}
        <Box justifyContent={"center"}>
          <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
            <Button
              display={"flex"}
              colorScheme={"orange"}
              leftIcon={<Icon as={FaHouseLock} />}
              _text={{ fontWeight: "bold", fontSize: "lg" }}
              alignSelf={"center"}
              shadow={7}
              onPress={handleClick}
            >
              Agendar visita
            </Button>
          </IconContext.Provider>
        </Box>
      </Stack>

      {/* <VStack p={5}>
        <Heading>Descripción:</Heading>
        <Text fontSize={"md"}>{descripcion}</Text>
      </VStack> */}
    </View>
  );
};
export default DetalleEscritorio;
